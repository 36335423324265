@use '../utils' as *;

/*----------------------------------------*/
/*  03. Banner CSS START
/*----------------------------------------*/
.banner{
    position: relative;
	z-index: 1;
    &__slider{
        position: relative;
    }
    &__border{
        position: absolute;
       height: 100%;
       width: 2px;
       background: var(--tp-common-white);
       opacity: .30;
       left: 24.3%;
    }
    &__border-2{
        position: absolute;
       height: 100%;
       width: 2px;
       background: var(--tp-common-white);
       opacity: .30;
       left: 44.4%;
       top: 0;
    }
    &__border-3{
        position: absolute;
       height: 100%;
       width: 2px;
       background: var(--tp-common-white);
       opacity: .30;
       left: 64.4%;
       top: 0;
    }
    &__area{
		&-3{
			background-image: -moz-linear-gradient( 145deg, rgb(255,235,235) 0%, rgb(240,240,240) 100%);
			background-image: -webkit-linear-gradient( 145deg, rgb(255,235,235) 0%, rgb(240,240,240) 100%);
			background-image: -ms-linear-gradient( 145deg, rgb(255,235,235) 0%, rgb(240,240,240) 100%);
			position: relative;
			z-index: 1;
		}
	}
	&__content{
		&-meta{
			font-size: 14px;
			background: var(--tp-common-white);
			padding: 5px 10px;
			border-radius: 6px;
			display: inline-block;
		}
		&-title{
			font-size: 60px;
            @media #{$lg} {
                font-size: 50px;
            }
            @media #{$sm,$xs} {
                font-size: 40px;
            }
		}
	}
	&__item{
		&-3{
			padding-top: 190px;
            @media #{$lg} {
                padding-top: 145px;
            }
            @media #{$md} {
                padding-top: 100px;
            }
            @media #{$sm,$xs} {
                padding-top: 80px;
            }
		}
	}
	&__button{
		display: flex;
		align-items: center;
        @media #{$xs} {
            display: block;
        }
	}
	&__cinfo{
		margin-left: 30px;
        @media #{$xs} {
            margin-left: 0;
           margin-top: 20px;
        }
		& a{
			display: inline-block;
			font-size: 16px;
			font-weight: var(--tp-fw-medium);
			color: var(--tp-heading-primary);
			& span{
				display: block;
				font-size: 14px;
				font-weight: var(--tp-regular);
				color: var(--tp-text-body);
			}
		}
	}
	&__d-image{
		position: absolute;
		top: 0;
		right: 0;
		z-index: -1;
        @media #{$md,$sm,$xs} {
            display: none;
        }
		&::before{
			position: absolute;
			content: '';
			background: var(--tp-theme-1);
			width: 100%;
			height: 100%;
			top: 0;
		}
	}
	&__shape{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		z-index: -1;
	}
	&__shape-2{
		position: absolute;
		top: 0px;
		left: 7%;
		z-index: -1;
        @media #{$md,$sm} {
            left: -40%;
        }
        @media #{$xs} {
            display: none;
        }
	}
    &__height-3{
        min-height: 970px;
        @media #{$lg} {
            min-height: 875px;
        }
    }
    &__image{
        @media #{$md,$sm,$xs} {
            margin-top: 50px;
        }
    }
}
//sd banner 
.banner-image{
    position: relative;
}
.sd-banner-area{
    position: relative;
}
.sd-banner__item{
    @include background();
}
.sd-content{
    background: var(--tp-theme-1);
    padding: 45px 50px;
    position: absolute;
    bottom: 0;
    width: 100.5%;
    z-index: 2;
    left: -2px;
    @media #{$lg} {
        bottom: 50px;
        width: 90%;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
    }
    @media #{$md} {
        bottom: 40px;
        width: 90%;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
    }
    @media #{$sm} {
        bottom: 30px;
        width: 90%;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
    }
    @media #{$sm,$xs} {
        bottom: 20px;
        width: 90%;
        z-index: 2;
        left: 0;
        right: 0;
        margin: auto;
        padding: 25px 20px;
    }

    & .sd-meta{
        font-size: 16px;
        line-height: 1.2;
        color: var(--tp-common-white);
    }
    & h5{ 
        font-size: 36px;
        line-height: 1;
        font-weight: 600;
        color: var(--tp-common-white);
        text-transform: uppercase;
        @media #{$xs} {
            font-size: 20px;
        }
        & a{
            line-height: 1;
        }
    }
    & p{
        color: var(--tp-common-white);
    }
    & .project-info{
        & i{
            font-size: 15px;
            color: var(--tp-heading-primary);
            height: 50px;
            width: 50px;
            line-height: 50px;
            background: var(--tp-common-white);
            border-radius: 6px;
            text-align: center;
            font-weight: var(--tp-fw-medium);
            margin-right: 20px;
            transition: .3s;
            &:hover{
                background: var(--tp-heading-primary);
                color: var(--tp-common-white);
            }
        }
        & span{
            font-size: 16px;
            color: var(--tp-common-white);
            font-weight: var(--tp-fw-sbold);
        }
    }
    & .sd-bg-icon{
        position: absolute;
        bottom: 10%;
        left: 35%;
        z-index: -1;
        & i{
            font-size: 230px;
            color: #e4e4e421;
        }
        @media #{$xs} {
            left: 4%;
        }
    }
}
.banner-slide-height {
	height: 800px;
	position: relative;
    @media #{$lg} {
        height: 700px;
    }
    @media #{$md} {
        height: 600px;
    }
    @media #{$sm} {
        height: 600px;
    }
    @media #{$xs} {
        height: 550px;
    }
}
.slide-border {
	border-right: 2px solid var(--tp-common-white);
	opacity: 0.3;
	height: 100%;
}