.careers {
    padding-top: 50px;
    padding-bottom: 50px
}

@media screen and (max-width: 991.98px) {
    .careers {
        padding-top:40px;
        padding-bottom: 40px
    }
}

@media screen and (max-width: 991.98px) {
    .careers .career-item {
        max-width:370px;
        margin: 0 auto
    }
}

.careers .career-item .career-item-wrap {
    -webkit-box-shadow: 0 5px 83px 0 rgba(40,40,40,.06);
    box-shadow: 0 5px 83px 0 rgba(40,40,40,.06);
    border-radius: 8px;
    background-color: var(--global--color-white);
    padding: 39px 40px 40px;
    position: relative
}

@media(min-width: 992px) and (max-width:1200px) {
    .careers .career-item .career-item-wrap {
        padding-right:25px;
        padding-left: 25px
    }
}

.careers .career-item .career-item-wrap .career-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 33px
}

.careers .career-item .career-item-wrap .career-meta .career-type,.careers .career-item .career-item-wrap .career-meta .career-place {
    font-size: 13px;
    line-height: 28px;
    font-family: var(--global--font-body);
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 0
}

.careers .career-item .career-item-wrap .career-meta .career-type {
    color: var(--global--color-primary);
    border: 2px solid var(--global--color-primary);
    padding: 0 9px;
    border-radius: 4px;
    margin-right: 15px
}

.careers .career-item .career-item-wrap .career-meta .career-place {
    color: var(--global--color-body)
}

.careers .career-item .career-item-wrap .career-content .career-title {
    font-family: var(--global--font-heading);
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--global--color-heading);
    margin-bottom: 17px;
    text-transform: capitalize
}

.careers .career-item .career-item-wrap .career-content .career-desc {
    font-family: var(--global--font-body);
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: var(--global--color-body);
    margin-bottom: 32px
}

.careers .career-item .career-item-wrap .career-content .career-more {
    width: 140px;
    height: 42px;
    padding: 0 20px
}

.careers .career-item .career-item-wrap .career-content .career-more i {
    font-size: 12px
}

.careers .carousel-dots .owl-dots {
    margin-top: 40px
}