@use '../utils' as *;

/*----------------------------------------*/
/*  04. PROJECTS CSS START
/*----------------------------------------*/

//project css
.project{
    &__area{
        position: relative;
    }
    &__inner{
        position: relative;
        width: 100%;
        display: flex;
        @media #{$sm,$xs} {
            display: block;
        }
    }
    &__info{
        position: relative;
        width: 50%;
        @media #{$sm,$xs} {
            width: 100%;
        }
        &-image{
            position: relative;
            &::before{
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(34, 35, 40, 0.5);
            }
            & img{
                width: 100%;
            }
        }
        &:hover .project__info-image {
            &::before{
                position: absolute;
                content: "";
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: .5;
                background: var(--tp-theme-1);
            }
        }
        &:hover .project__button{
            visibility: visible;
            opacity: 1;
        }
        &:hover .project__content{
            transform: translateY(-50%);
        }
    }
    &__content{
        position: absolute;
        top: 50%;
        transform: translateY(-23%);
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        transition: .3s;
        padding: 0 30px;
        & span{
            font-size: 14px;
            color: var(--tp-common-white);
            margin-bottom: 10px;
        }
        & .p-title{
            font-size: 40px;
            line-height: 1.3;
            color: var(--tp-common-white);
            @media #{$md,$sm,$xs} {
                font-size: 30px;
            }
        }
    }
    &__button{
        visibility: hidden;
        opacity: 0;
        transition: .3s;
    }
    &__slider{
        position: relative;
        margin-right: -750px;
        &-item{
            overflow: hidden;
            transition: .3s;
            &-image{
                @include background();
                min-height: 450px;
                border-radius: 6px;
                position: relative;
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    background: #2527287a;
                    visibility: hidden;
                    opacity: 0;
                    border-radius: 6px;
                }
            }
            & span{
                font-size: 14px;
                display: inline-block;
                margin-bottom: 5px;
            }
            &-title{
                font-size: 24px;
                line-height: 1.3;
                transition: .3s;
                @media #{$md} {
                    font-size: 18px;
                }
                &:hover {
                    color: var(--tp-theme-1);
                }
            }
            &-overlay{
                position: absolute;
                text-align: center;
                left: 0;
                right: 0;
                top: 45%;
                transform: translateY(-45%);
                margin: 0 auto;
                transition: .3s;
                visibility: hidden;
                opacity: 0;
                & a{
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    background: var(--tp-theme-1);
                    line-height: 50px;
                    font-size: 20px;
                    border-radius: 6px;
                    color: var(--tp-common-white);
                    transition: .3s;
                    &:hover{
                        background: var(--tp-common-white);
                        color: var(--tp-theme-1);
                    }
                }
            }
            &:hover{
                .project__slider-item-image::before{
                    visibility: visible;
                    opacity: 1;
                }
                .project__slider-item-overlay{
                    visibility: visible;
                    opacity: 1;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        @media #{$md,$sm,$xs} {
            margin-right: 0;
        }
    }
}

.casef-item {
    position: relative;
    padding-left: 120px;
    margin-top: 2px;
    .casef-item__left {
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
        height: 60px;
        font: 700 16px/60px "saira_condensed";
        text-align: center;
        background-color: var(--tp-theme-1);
        color: #fff;
        @media #{$md,$sm} {
            font: 700 14px/60px "saira_condensed";
        }
    }
    .casef-options {
        padding: 10px 30px;
        background-color: #F5F7F9;
        .casef-option {
            margin-right: 3em;
            position: relative;
            white-space: nowrap;
            font: 400 16px/40px "saira_condensed";
            color: #333;
            @media #{$md,$sm} {
                font: 400 14px/40px "saira_condensed";
                margin-right: 1em;
            }
        }
        .casef-option::after {
            content: "";
            position: absolute;
            top: 50%;
            right: -1.5em;
            width: 1px;
            height: 14px;
            margin-top: -7px;
            background-color: #333;
            opacity: .22;
            pointer-events: none;
            @media #{$md,$sm} {
                right: -0.5em;
            }
        }
        .casef-option:last-of-type {
            margin-right: 0;
        }
        .casef-option:last-of-type::after{
            display: none;
        }
    }
}

// List Products
.list-products {
    > li {
        &:nth-of-type(2n+1) {
            text-align: right;
            .product-item__img {
                right: 0px
            }
            .product-item__intro {
                left: 0;
            }
        }
        &:nth-of-type(2n) {
            .product-item__intro {
                right: 0;
            }
            .product-item__img {
                left: 0px
            }
        }
        &:nth-of-type(n+2) {
            margin-top: 60px;
        }
    }
}
.product-item {
    position: relative;
    display: block;
}
.product-item__img {
    position: relative;
    display: inline-block;vertical-align: top;width: 50%;
    background-color: #F8FAFB;overflow: hidden;
    &::before {
        padding-top: 83.5443%;
    }
}
.product-item__img-cate {
    position: absolute;top: 0;left: 50%;margin-top: .35em;
    font: 700 158px/1 var(--tp-ff-body);letter-spacing: -0.09em;white-space: nowrap;color: var(--tp-theme-1);opacity: 0.04;text-transform: uppercase;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.product-item__img-box {
    position: absolute;top: 50%;left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.product-item__intro {
    position: absolute;bottom: 0;top: 0;width: 50%;
}
.product-item__txt {
    position: absolute;top: 50%;right: 0;left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.product-item__con {
    width: 500px;max-width: 90%;margin-right: auto;margin-left: auto;text-align: left;
}
.product-item__title {
    font: 700 40px/1.16 var(--tp-ff-body);color:var(--tp-theme-1);
}
.product-item__desc {
    font: 500 14px/2 var(--tp-ff-body);
}
@media (max-width: 1920px) {
    .product-item__img-cate {
        font-size: vw(158, 1920);
    }
}
@media (max-width: 1680px) {
    .product-item__title,
    .products-intro__title {
        font-size: 48px;
    }
}
@media (max-width: 1024px) {
    .product-item__title,
    .products-intro__title {
        font-size: 28px;
    }
    .products-intro {
        padding-left: 0;
    }
    .products-intro__title {
        position: relative;width: auto;max-width: 100%;
    }
    .products-intro__desc {
        font-size: 16px;margin-top: 20px;
    }
    .pcate-item {
        font-size: 16px;
    }
}
@media (max-width: 750px) {
    .product-item__title,
    .products-intro__title {
        font-size: 20px;
    }
    .product-item__img {
        width: 100%;
    }
    .product-item__intro {
        position: relative;width: auto;top: auto;bottom: auto;
    }
    .product-item__txt {
        position: relative;top: auto;right: auto;left:auto;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    .product-item__con {
        width: auto;max-width: 100%;margin-top: 30px;
    }
    .list-products {
        > li {
            &:nth-of-type(2n+1) {
                .product-item__img {
                    right: auto
                }
                .product-item__intro {
                    left: auto;
                }
            }
            &:nth-of-type(2n) {
                .product-item__intro {
                    right: auto;
                }
                .product-item__img {
                    left: auto
                }
            }
            &:nth-of-type(n+2) {
                margin-top: 60px;
            }
        }
    }
    .pcate-item {
        margin-right: 3em;
        &::after {
            right: -1.5em;
        }
    }
}

.img__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top center;
    object-position: top center;
    z-index: -1;
}
.img__box {
    position: relative;
    overflow: hidden;
    margin-bottom: 50px
}

.img__box::before {
    content: "";
    display: block;
    padding-top: 142.85714%
}

.img__box__overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0,61,144,.15);
    z-index: 1;
    -webkit-transition: background .8s;
    -o-transition: background .8s;
    transition: background .8s
}

.img__box .img__bg {
    z-index: 0;
    -webkit-transition: -webkit-transform .8s;
    transition: -webkit-transform .8s;
    -o-transition: transform .8s;
    transition: transform .8s;
    transition: transform .8s,-webkit-transform .8s
}

.img__box__text-layout {
    position: absolute;
    top: 230px;
    left: 25px;
    color: #f3f6f5;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    z-index: 1
}

.img__box__details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 30px 13px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 1
}

.img__box__title {
    width: calc(100% - 60px)
}

.img__box__title a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    font-size: 14px;
    font-weight: 600;
}

.img__box__title a:hover {
    color: #fff;
    opacity: .7
}

.img__box__count {
    color: #00997d;
    font-size: 40px;
    font-weight: 700;
    line-height: 1
}

.img__box:hover .img__bg {
    -webkit-transform: scale(1.17);
    -ms-transform: scale(1.17);
    transform: scale(1.17)
}

.img__box:hover .img__box__overlay {
    background: rgba(0,61,144,.3)
}

.product__area{
    background: #fff;
}

/* Project Single */
.project-panel {
    margin-bottom: 20px;
    background-color: var(--global--color-white);
    -webkit-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    border-radius: 8px;
}
.project-single {
	padding-top   : 0;
	padding-bottom: 0;
}

.project-single .project-intro .project-img {
	position: relative;
}

.project-single .project-intro .project-img img {
	max-width    : 100%;
	border-radius: 12px;
}

@media screen and (max-width: 767.98px) {
	.project-single .project-intro .project-img img {
		height       : 200px;
		border-radius: 8px 8px 0 0;
	}
}

.project-single .project-intro .project-img .project-panel {
	position          : absolute;
	right             : 65px;
	bottom            : -65px;
	max-width         : 500px;
	-webkit-box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.16);
	box-shadow        : 0px 5px 83px 0px rgba(40, 40, 40, 0.16);
	margin-bottom     : 0;
}

@media screen and (max-width: 991.98px) {
	.project-single .project-intro .project-img .project-panel {
		right : 40px;
		bottom: -70px;
	}
}

@media screen and (max-width: 767.98px) {
	.project-single .project-intro .project-img .project-panel {
		position     : relative;
		right        : auto;
		bottom       : auto;
		border-radius: 0 0 8px 8px;
		margin-bottom: 0;
	}
}

.project-single .project-intro .project-img .project-panel:hover .project-content .project-more .btn {
	background-color: var(--global--color-primary);
}

.project-single .project-intro .project-img .project-panel:hover .project-content .project-more .btn::before {
	-webkit-transform       : scaleX(1);
	-ms-transform           : scaleX(1);
	transform               : scaleX(1);
	-webkit-transform-origin: left center;
	-ms-transform-origin    : left center;
	transform-origin        : left center;
}

.project-single .project-intro .project-img .project-panel .project-content {
	padding: 43px 50px 75px;
}

@media screen and (max-width: 991.98px) {
	.project-single .project-intro .project-img .project-panel .project-content {
		padding-left : 25px;
		padding-right: 25px;
	}
}

.project-single .project-intro .project-img .project-panel .project-content .project-cat {
	margin-bottom: 0;
}

.project-single .project-intro .project-img .project-panel .project-content .project-title {
	margin-bottom: 29px;
}

.project-single .project-intro .project-img .project-panel .project-content .project-more {
	position: absolute;
	bottom  : 0;
	left    : 0;
}

.project-single .project-intro .project-img .project-panel .project-content .project-more .btn {
	width             : 204px;
	height            : 65px;
	color             : var(--global--color-white);
	border            : 0;
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	border-radius     : 0 8px 0 8px;
}

.project-single .project-intro .project-img .project-panel .project-content .project-more .btn::before {
	content                 : '';
	position                : absolute;
	width                   : calc(100%);
	height                  : calc(100%);
	top                     : 0px;
	left                    : 0px;
	z-index                 : -1;
	-webkit-transform       : scaleX(0);
	-ms-transform           : scaleX(0);
	transform               : scaleX(0);
	-webkit-transform-origin: right center;
	-ms-transform-origin    : right center;
	transform-origin        : right center;
	-webkit-transition      : -webkit-transform .24s ease-in-out;
	transition              : -webkit-transform .24s ease-in-out;
	-o-transition           : transform .24s ease-in-out;
	transition              : transform .24s ease-in-out;
	transition              : transform .24s ease-in-out, -webkit-transform .24s ease-in-out;
}

.project-single .project-intro .project-img .project-panel .project-content .project-more .btn i {
	color           : var(--global--color-white);
	background-color: transparent;
}

.project-single .project-overview {
	padding-top   : 110px;
	padding-bottom: 80px;
}

@media screen and (max-width: 991.98px) {
	.project-single .project-overview {
		padding-top   : 70px;
		padding-bottom: 60px;
	}
}

.project-single .project-overview .project-details {
	margin-bottom: 30px;
}

@media screen and (min-width: 992px) {
	.project-single .project-overview .project-details {
		-webkit-transform: translateY(-6px);
		-ms-transform    : translateY(-6px);
		transform        : translateY(-6px);
		margin-bottom    : 23px;
	}
}

.project-single .project-overview .project-details .table {
	margin-bottom: 0;
}

@media screen and (min-width: 992px) {
	.project-single .project-overview .project-details .table {
		width: 80%;
	}
}

.project-single .project-overview .project-details .table td {
	border-top    : 0;
	border-bottom : 0;
	padding-bottom: 5px;
}

.project-single .project-overview .project-details .table .name {
	font-family   : var(--global--font-body);
	font-weight   : 700;
	font-size     : 15px;
	color         : var(--global--color-secondary);
	text-transform: capitalize;
}

@media screen and (min-width: 992px) {
	.project-single .project-overview .project-details .table .name {
		padding-left: 0;
	}
}

.project-single .project-overview .project-details .table .value {
	font-family   : var(--global--font-body);
	font-weight   : 400;
	font-size     : 15px;
	color         : var(--global--color-body);
	text-transform: capitalize;
}

.project-single .project-overview .advantages-list-holder p {
	font-size    : 17px;
	font-weight  : 700;
	line-height  : 27px;
	color        : var(--global--color-secondary);
	margin-bottom: 25px;
}

@media screen and (max-width: 991.98px) {
	.project-single .project-overview.project-overview-1 {
		padding-top: 100px;
	}
}

.project-single .project-overview.project-overview-1 .btn {
	width             : 270px;
	height            : 60px;
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
}

@media screen and (max-width: 991.98px) {
	.project-single .project-overview.project-overview-2 {
		padding-top   : 70px;
		padding-bottom: 70px;
	}
}

.project-single .project-overview.project-overview-3 {
	padding-top   : 120px;
	padding-bottom: 50px;
}

@media screen and (max-width: 991.98px) {
	.project-single .project-overview.project-overview-3 {
		padding-top   : 200px;
		padding-bottom: 30px;
	}
}
.-textPanel table{
    -webkit-box-shadow: 0px 5px 83px 0px rgb(40 40 40 / 21%);
    box-shadow: 0px 5px 83px 0px rgb(40 40 40 / 21%);
    background-color: var(--global--color-white);
    padding-top: 32px;
    padding-bottom: 26px;
    width: 100%;
}
.-textPanel table td {
    border-top: 0;
    padding: 15px 15px;
}
.-textPanel table tr td:first-child{
    font-family: var(--global--font-body);
    font-weight: 700;
    font-size: 15px;
    color: var(--global--color-secondary);
    text-transform: capitalize;
}
.-textPanel table tr td:last-child{
    font-family: var(--global--font-body);
    font-weight: 400;
    font-size: 15px;
    color: var(--global--color-body);
    text-transform: capitalize;
}

.project-single .project-overview.project-overview-3 .btn {
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
}

.project-single .project-overview.project-overview-3 .heading {
	margin-bottom: 62px;
}

.project-single .project-overview.project-overview-4 {
	padding-top   : 110px;
	padding-bottom: 90px;
}

@media screen and (max-width: 991.98px) {
	.project-single .project-overview.project-overview-4 {
		padding-top   : 70px;
		padding-bottom: 70px;
	}
}

.project-single .project-overview.project-overview-4 .counters-holder {
	margin-top: 30px;
}

@media screen and (min-width: 992px) {
	.project-single .project-overview.project-overview-4 .counters-holder {
		margin-top: 11px;
	}
}

.project-single .project-image-carousel {
	padding-top   : 0;
	padding-bottom: 0;
}

.project-single .project-image-carousel .owl-carousel .owl-stage-outer .owl-item {
	opacity: 1;
}

.project-single .project-image-carousel .owl-carousel .owl-nav {
	top: 167px;
}

@media screen and (max-width: 991.98px) {
	.project-single .project-image-carousel .owl-carousel .owl-nav {
		display: none;
	}
}

.project-single .project-image-carousel .owl-carousel .owl-nav .owl-prev,
.project-single .project-image-carousel .owl-carousel .owl-nav .owl-next {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 110px;
	height            : 110px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.project-single .project-image-carousel .owl-carousel .owl-nav .owl-prev::before,
.project-single .project-image-carousel .owl-carousel .owl-nav .owl-next::before {
	color      : var(--global--color-white);
	content    : '\e906';
	font-family: 'energia';
	font-size  : 20px;
}

.project-single .project-image-carousel .owl-carousel .owl-nav .owl-prev {
	left            : 0;
	border-radius   : 0 8px 8px 0;
	background-color: var(--global--color-primary);
}

.project-single .project-image-carousel .owl-carousel .owl-nav .owl-prev:hover {
	background-color: var(--global--color-heading);
}

.project-single .project-image-carousel .owl-carousel .owl-nav .owl-prev:before {
	-webkit-transform: rotate(270deg);
	-ms-transform    : rotate(270deg);
	transform        : rotate(270deg);
}

.project-single .project-image-carousel .owl-carousel .owl-nav .owl-next {
	right           : 0;
	border-radius   : 8px 0px 0px 8px;
	background-color: var(--global--color-heading);
}

.project-single .project-image-carousel .owl-carousel .owl-nav .owl-next:hover {
	background-color: var(--global--color-primary);
}

.project-single .project-image-carousel .owl-carousel .owl-nav .owl-next:before {
	-webkit-transform: rotate(90deg);
	-ms-transform    : rotate(90deg);
	transform        : rotate(90deg);
}

.project-single .project-image-carousel .owl-carousel .owl-dots {
	margin-top: 10px;
	position  : relative;
	bottom    : auto;
}

.project-single .project-share {
	padding-top   : 33px;
	padding-bottom: 40px;
	border-bottom : 1px solid #eaeaea;
}

.project-single .project-share .tags {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	margin-bottom    : 10px;
}

.project-single .project-share .tags a {
	font-family   : var(--global--font-body);
	font-weight   : 400;
	font-size     : 14px;
	line-height   : 28px;
	color         : var(--global--color-primary);
	text-transform: capitalize;
}

.project-single .project-share .tags a::after {
	content     : ',';
	margin-right: 4px;
}

.project-single .project-share .tags a:last-child::after {
	content: unset;
}

.project-single .project-share .social-share {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

.project-single .project-share .social-share a {
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 40px;
	height            : 40px;
	border-radius     : 50%;
	border            : 2px solid #929ba2;
	margin-right      : 10px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.project-single .project-share .social-share a:last-child {
	margin-right: 0;
}

.project-single .project-share .social-share a:hover i {
	width           : 40px;
	height          : 40px;
	background-color: var(--global--color-primary);
}

.project-single .project-share .social-share a i {
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 20px;
	height            : 20px;
	border-radius     : 50%;
	font-size         : 11px;
	background-color  : var(--global--color-heading);
	color             : var(--global--color-white);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}
.projects.projects-gallery .project-panel:hover .project-panel-holder .project-img .project-hover {
    opacity: 1;
    visibility: visible;
}

.projects.projects-standard {
	padding-top   : 86px;
	padding-bottom: 110px;
}

@media screen and (max-width: 991.98px) {
	.projects.projects-standard {
		padding-top   : 66px;
		padding-bottom: 70px;
	}
}

.projects.projects-standard .project-panel {
	-webkit-box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
	margin-bottom     : 30px;
}

.projects.projects-standard .project-panel:hover .project-panel-holder .project-content {
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	background-color  : var(--global--color-primary);
}

.projects.projects-standard .project-panel:hover .project-panel-holder .project-content .project-title h4 a {
	color: var(--global--color-white);
}

.projects.projects-standard .project-panel:hover .project-panel-holder .project-content .project-cat a {
	color: var(--global--color-white);
}

.projects.projects-standard .project-panel .project-panel-holder .project-img {
	border-radius: 8px 8px 0 0;
}

.projects.projects-standard .project-panel .project-panel-holder .project-content {
	background-color: var(--global--color-white);
	padding         : 30px 38px 27px;
	border-radius   : 0 0 8px 8px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.projects.projects-standard .project-panel .project-panel-holder .project-content {
		padding-left : 10px;
		padding-right: 10px;
	}
}

.projects.projects-standard .project-panel .project-panel-holder .project-content .project-title {
	margin-bottom: 6px;
}

.projects.projects-standard #load-more {
	margin-top: 20px;
}

.projects.projects-standard.projects-standard-2 {
	padding-top   : 36px;
	padding-bottom: 145px;
}

@media screen and (max-width: 767.98px) {
	.projects.projects-standard.projects-standard-2 {
		padding-bottom: 105px;
	}
}

.projects.projects-standard.projects-standard-2 .project-panel {
	margin-bottom: 50px;
}

.projects.projects-standard.projects-standard-2 .owl-carousel .owl-dots {
	position: absolute;
	width   : 100%;
	bottom  : -100px;
}

.projects .projects-load-more {
	text-align: center;
}

.projects .projects-load-more .btn {
	width             : 222px;
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
}

.project-panel .project-panel-holder .project-content .project-title h4 a {
	font-family   : var(--global--font-heading);
	font-size     : 20px;
	font-weight   : 600;
	line-height   : 27px;
	text-transform: capitalize;
	color         : var(--global--color-heading);
}

@media (min-width: 992px) and (max-width: 1200px) {
	.project-panel .project-panel-holder .project-content .project-title h4 a {
		font-size: 18px;
	}
}

.project-panel .project-panel-holder .project-content .project-title h4 a:hover {
	color: var(--global--color-primary);
}

.project-panel .project-panel-holder .project-img {
	position     : relative;
	overflow     : hidden;
	border-radius: 8px;
}
.project-panel .project-panel-holder .project-img::after {
	position          : absolute;
	top               : 0;
	left              : 0;
	width             : 100%;
	height            : 100%;
	background        : rgba(255, 255, 255, 0.5);
	content           : '';
	z-index           : 1;
	-webkit-transition: -webkit-transform 0.6s;
	transition        : -webkit-transform 0.6s;
	-o-transition     : transform 0.6s;
	transition        : transform 0.6s;
	transition        : transform 0.6s, -webkit-transform 0.6s;
	-webkit-transform : scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
	transform         : scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
}

.project-panel:hover {
	-webkit-box-shadow: 0 3px 20px 0 rgba(40, 40, 40, 0.13);
	box-shadow        : 0 3px 20px 0 rgba(40, 40, 40, 0.13);
}

.project-panel:hover .project-panel-holder .project-img img {
	-webkit-transform: scale(1.1);
	-ms-transform    : scale(1.1);
	transform        : scale(1.1);
}

.project-panel:hover .project-panel-holder .project-img::after {
	-webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
	transform        : scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 100%, 0);
}

.project-panel:hover .project-panel-holder .project-content .project-more .btn {
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	color             : var(--global--color-white);
	background-color  : var(--global--color-heading);
	border-color      : var(--global--color-heading);
}

.project-panel:hover .project-panel-holder .project-content .project-more .btn i {
	color: var(--global--color-heading);
}

@media screen and (max-width: 991.98px) {
	.project-panel {
		margin   : 0 auto 30px;
		max-width: 370px;
	}
}
.project-panel .project-panel-holder .project-img img {
	max-width         : 100%;
	height            : auto;
	-webkit-transition: 0.5s ease-in-out;
	-o-transition     : 0.5s ease-in-out;
	transition        : 0.5s ease-in-out;
}

/*------------------------------------*\
		#Processess
\*------------------------------------*/
.processes {
	overflow      : visible;
	padding-top   : 0;
	padding-bottom: 0;
}

.processes .process-panel {
	padding : 0px 50px 32px 0;
	position: relative;
}

@media screen and (max-width: 991.98px) {
	.processes .process-panel {
		padding-right: 0;
	}
}

.processes .process-panel .process-number {
	font-family  : var(--global--font-body);
	font-size    : 37px;
	font-weight  : 500;
	line-height  : 29px;
	color        : var(--global--color-primary);
	margin-bottom: 32px;
}

@media screen and (max-width: 767.98px) {
	.processes .process-panel .process-number {
		text-align: center;
	}
}

.processes .process-panel .process-body .process-content h5 {
	font-weight   : 700;
	font-style    : italic;
	font-size     : 24px;
	color         : var(--global--color-white);
	line-height   : 48px;
	margin-bottom : 18px;
	text-transform: capitalize;
}

.processes .process-panel .process-body .process-content p {
	font-size    : 16px;
	line-height  : 27px;
	color        : var(--global--color-white);
	margin-bottom: 0px;
}

.processes .process-panel.process-panel-dark .process-body .process-content h5 {
	color: var(--global--color-secondary);
}

.processes .process-panel.process-panel-dark .process-body .process-content p {
	color: var(--global--color-body);
}

@media screen and (max-width: 991.98px) {
	.processes .block-right-holder {
		padding      : 70px 5% 0;
		text-align   : center;
		margin-bottom: 50px;
	}
}

@media screen and (min-width: 992px) {
	.processes .block-right-holder {
		padding-left: var(--bs-gutter-x, 0.75rem);
		margin-left : auto;
		max-width   : calc(960px / 2);
		padding-top : 130px;
	}
}

@media screen and (min-width: 1200px) {
	.processes .block-right-holder {
		max-width: calc(1140px / 2);
	}
}

.processes .block-right-holder .heading {
	margin-bottom: 223px;
}

@media screen and (max-width: 991.98px) {
	.processes .block-right-holder .heading {
		margin-bottom: 50px;
	}
}

.processes .processes-holder {
	padding-top: 40px;
}

@media screen and (min-width: 992px) {
	.processes .processes-holder {
		border-left: 1px solid #4c5b66;
		padding-top: 130px;
	}
}

@media screen and (max-width: 991.98px) {
	.processes .processes-holder .process-content-carousel {
		text-align   : center;
		padding      : 0 5%;
		margin-bottom: 60px;
	}
}

@media screen and (min-width: 992px) {
	.processes .processes-holder .process-content-carousel {
		padding-left : 75px;
		margin-bottom: 110px;
		padding-right: var(--bs-gutter-x, 0.75rem);
		margin-right : auto;
		max-width    : calc(960px / 2);
	}
}

@media screen and (min-width: 1200px) {
	.processes .processes-holder .process-content-carousel {
		padding-left: 115px;
		max-width   : calc(1140px / 2);
	}
}

.processes .processes-holder .process-content-carousel .owl-stage-outer {
	overflow: hidden;
}

.processes .processes-holder .process-content-carousel .owl-stage-outer .owl-item {
	opacity: 1;
}

.processes .processes-holder .process-content-carousel .owl-dots {
	-webkit-box-pack: start;
	-ms-flex-pack   : start;
	justify-content : flex-start;
}

@media screen and (max-width: 991.98px) {
	.processes .processes-holder .process-content-carousel .owl-dots {
		-webkit-box-pack: center;
		-ms-flex-pack   : center;
		justify-content : center;
	}
}

.processes .processes-holder .images-holder {
	border-radius    : 0 0 0 12px;
	overflow         : hidden;
	-webkit-transform: translateY(130px);
	-ms-transform    : translateY(130px);
	transform        : translateY(130px);
	margin-top       : -130px;
	position         : relative;
}

.processes .processes-holder .images-holder .process-image-carousel {
	position: relative;
}

.processes .processes-holder .images-holder .process-image-carousel .owl-stage-outer {
	overflow: hidden;
}

.processes .processes-holder .images-holder .process-image-carousel .owl-stage-outer .owl-item {
	opacity: 1;
}

.processes .processes-holder .images-holder .process-image-carousel .owl-stage-outer .owl-item img {
	-o-object-fit: scale-down;
	object-fit   : scale-down;
	width        : auto;
}

.processes .processes-holder .images-holder .processes-panel {
	padding         : 42px 49px 40px;
	position        : absolute;
	right           : 0;
	bottom          : 0;
	border-radius   : 12px 0 0 0;
	background-color: var(--global--color-primary);
	max-width       : 400px;
	z-index         : 10;
}

@media screen and (max-width: 767.98px) {
	.processes .processes-holder .images-holder .processes-panel {
		padding-left : 30px;
		padding-right: 30px;
		position     : relative;
		right        : auto;
		bottom       : auto;
		border-radius: 0 0 12px 12px;
		text-align   : center;
		max-width    : 100%;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.processes .processes-holder .images-holder .processes-panel {
		max-width: 330px;
		padding  : 25px;
	}
}

.processes .processes-holder .images-holder .processes-panel .panel-title {
	font-size    : 20px;
	line-height  : 30px;
	color        : var(--global--color-white);
	margin-bottom: 17px;
}

.processes .processes-holder .images-holder .processes-panel .panel-desc {
	font-size    : 15px;
	line-height  : 24px;
	margin-bottom: 23px;
	color        : var(--global--color-gray);
}

.processes .processes-holder .images-holder .processes-panel a {
	font-family      : var(--global--font-body);
	font-weight      : 700;
	font-size        : 15px;
	color            : var(--global--color-white);
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	text-transform   : capitalize;
}

@media screen and (max-width: 767.98px) {
	.processes .processes-holder .images-holder .processes-panel a {
		-webkit-box-pack: center;
		-ms-flex-pack   : center;
		justify-content : center;
	}
}

.processes .processes-holder .images-holder .processes-panel a:hover i {
	-webkit-transform: translateX(15px);
	-ms-transform    : translateX(15px);
	transform        : translateX(15px);
}

.processes .processes-holder .images-holder .processes-panel a i {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 32px;
	height            : 32px;
	background-color  : var(--global--color-white);
	border-radius     : 50%;
	color             : var(--global--color-primary);
	margin-left       : 10px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.processes.processes-1 {
	background-position: top center;
	padding-bottom     : 0;
}

.processes.processes-2 {
	overflow: hidden;
}

.processes.processes-2 .processes-holder {
	padding-top : 0;
	border-left : 0;
	padding-left: 0;
}

.processes.processes-2 .processes-holder .process-content-carousel {
	padding-left : 0;
	max-width    : unset;
	padding-right: 0;
	margin-right : 0;
	margin-bottom: 40px;
}

@media screen and (max-width: 991.98px) {
	.processes.processes-2 .processes-holder .process-content-carousel {
		padding: 0;
	}
}

@media screen and (max-width: 767.98px) {
	.processes.processes-2 .processes-holder .process-content-carousel {
		max-width: 370px;
	}
}