#back-to-top {
	position          : fixed;
	right             : 0px;
	bottom            : 0px;
	opacity           : 0;
	width             : 90px;
	height            : 200px;
	border-radius     : 4px 0 0 0;
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	cursor            : pointer;
	-webkit-transition: 300ms ease-in-out;
	-o-transition     : 300ms ease-in-out;
	transition        : 300ms ease-in-out;
	background-color  : var(--global--color-primary);
}

#back-to-top::before {
	content                 : '';
	position                : absolute;
	width                   : calc(100%);
	height                  : calc(100%);
	top                     : 0px;
	left                    : 0px;
	z-index                 : -1;
	-webkit-transform       : scaleY(0);
	-ms-transform           : scaleY(0);
	transform               : scaleY(0);
	-webkit-transform-origin: top;
	-ms-transform-origin    : top;
	transform-origin        : top;
	background-color        : var(--global--color-heading);
	-webkit-transition      : -webkit-transform .24s ease-in-out;
	transition              : -webkit-transform .24s ease-in-out;
	-o-transition           : transform .24s ease-in-out;
	transition              : transform .24s ease-in-out;
	transition              : transform .24s ease-in-out, -webkit-transform .24s ease-in-out;
}

#back-to-top:hover::before {
	-webkit-transform       : scaleY(1);
	-ms-transform           : scaleY(1);
	transform               : scaleY(1);
	-webkit-transform-origin: bottom;
	-ms-transform-origin    : bottom;
	transform-origin        : bottom;
}

#back-to-top.show {
	z-index: 999;
	opacity: 1;
}

#back-to-top i {
	color      : var(--global--color-white);
	font-weight: 700;
	font-size  : 15px;
}

@media screen and (max-width: 991.98px) {
	#back-to-top {
		width        : 50px;
		height       : 50px;
		border-radius: 50%;
		bottom       : 30px;
		right        : 20px;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	#back-to-top {
		width : 70px;
		height: 150px;
	}
}