@font-face {
    font-family: 'inter';
    src: url('/fonts/inter-regular-webfont.woff2') format('woff2'),
         url('/fonts/inter-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'inter';
    src: url('/fonts/inter-light-webfont.woff2') format('woff2'),
         url('/fonts/inter-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'inter';
    src: url('/fonts/inter-extrabold-webfont.woff2') format('woff2'),
         url('/fonts/inter-extrabold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'energia';
    src: url(/fonts/energia.eot?lqjum8);
    src: url(/fonts/energia.eot?lqjum8#iefix) format("embedded-opentype"),url(/fonts/energia.ttf?lqjum8) format("truetype"),url(/fonts/energia.woff?lqjum8) format("woff"),url(/fonts/energia.svg?lqjum8#energia) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: block
}

[class*=" energia-"],[class^=energia-] {
    font-family: 'energia'!important;
    speak: never;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.energia-facebook:before {
    content: "\e900"
}

.energia-linkedin:before {
    content: "\e901"
}

.energia-twitter:before {
    content: "\e902"
}

.energia-youtube:before {
    content: "\e903"
}

.energia-alert-Icon:before {
    content: "\e904"
}

.energia-arrow-right:before {
    content: "\e905"
}

.energia-arrow-up:before {
    content: "\e906"
}

.energia-clock-Icon:before {
    content: "\e907"
}

.energia-email--icon:before {
    content: "\e908"
}

.energia-location-Icon:before {
    content: "\e909"
}

.energia-phone-Icon:before {
    content: "\e90a"
}

.energia-search-Icon:before {
    content: "\e90b"
}