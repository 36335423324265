@use '../utils' as *;

/*------------------------------------*\
		#Menu
\*------------------------------------*/
.header .navbar {
	margin-bottom     : 0;
	border-radius     : 0;
	border            : none;
	z-index           : 7777;
	padding           : 0;
	-webkit-transition: all 300ms ease-in-out;
	-o-transition     : all 300ms ease-in-out;
	transition        : all 300ms ease-in-out;
}

.header .navbar .navbar-nav>li {
	margin-right: 30px;
	position    : relative;
}

.header .navbar .navbar-nav>li:last-child {
	margin-right: 0;
}

.header .navbar .navbar-nav>li>a {
	font-family      : var(--global--font-body);
	font-size        : 15px;
	text-transform   : capitalize;
	font-weight      : 700;
	line-height      : 100px;
	color            : var(--global--color-white);
	padding          : 0;
	position         : relative;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

.header .navbar .navbar-nav>li>a::before {
	position                : absolute;
	bottom                  : 0;
	left                    : 0;
	margin                  : auto;
	width                   : 100%;
	height                  : 3px;
	background              : var(--global--color-white);
	content                 : "";
	-webkit-transition      : -webkit-transform 1s cubic-bezier(0.2, 1, 0.3, 1);
	transition              : -webkit-transform 1s cubic-bezier(0.2, 1, 0.3, 1);
	-o-transition           : transform 1s cubic-bezier(0.2, 1, 0.3, 1);
	transition              : transform 1s cubic-bezier(0.2, 1, 0.3, 1);
	transition              : transform 1s cubic-bezier(0.2, 1, 0.3, 1), -webkit-transform 1s cubic-bezier(0.2, 1, 0.3, 1);
	-webkit-transform-origin: 100% 50%;
	-ms-transform-origin    : 100% 50%;
	transform-origin        : 100% 50%;
	-webkit-transform       : scale3d(0, 1, 1);
	transform               : scale3d(0, 1, 1);
}

.header .navbar .navbar-nav>li.has-dropdown>a {
	position: relative;
}

.header .navbar .navbar-nav>li.has-dropdown>a::after {
	border            : 0;
	content           : '\f078';
	position          : relative;
	margin-left       : 7px;
	font-size         : 11px;
	font-family       : "Font Awesome 6 Pro";
	color             : var(--global--color-heading);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.header .navbar .navbar-nav>li.active a,
.header .navbar .navbar-nav>li:hover a {
	color: var(--global--color-primary);
}

@media screen and (min-width: 992px) {

	.header .navbar .navbar-nav>li.active a::before,
	.header .navbar .navbar-nav>li:hover a::before {
		-webkit-transform       : scale3d(1, 1, 1);
		transform               : scale3d(1, 1, 1);
		-webkit-transform-origin: 0 50%;
		-ms-transform-origin    : 0 50%;
		transform-origin        : 0 50%;
	}
}

.header .navbar .navbar-nav>li.active>.dropdown-toggle,
.header .navbar .navbar-nav>li.has-dropdown.active>.dropdown-toggle,
.header .navbar .navbar-nav>li:hover .dropdown-toggle {
	color: var(--global--color-primary);
}

.header .navbar .navbar-nav>li.active>.dropdown-toggle::before,
.header .navbar .navbar-nav>li.has-dropdown.active>.dropdown-toggle::before,
.header .navbar .navbar-nav>li:hover .dropdown-toggle::before {
	-webkit-transform       : scale3d(1, 1, 1);
	transform               : scale3d(1, 1, 1);
	-webkit-transform-origin: 0 50%;
	-ms-transform-origin    : 0 50%;
	transform-origin        : 0 50%;
	background-color        : var(--global--color-primary);
}

.header .navbar .navbar-nav>li.active>.dropdown-toggle::after,
.header .navbar .navbar-nav>li.has-dropdown.active>.dropdown-toggle::after,
.header .navbar .navbar-nav>li:hover .dropdown-toggle::after {
	color: var(--global--color-primary);
}

.header .navbar.navbar-fixed {
	position          : fixed;
	top               : 0;
	right             : 0;
	left              : 0;
	background        : var(--global--color-white);
	-webkit-animation : fadeInDown 1s ease-in-out;
	animation         : fadeInDown 1s ease-in-out;
	-webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.1);
	box-shadow        : 0 2px 4px rgba(3, 27, 78, 0.1);
}

@media screen and (min-width: 992px) {
	.header .navbar.navbar-fixed .navbar-brand .logo-dark {
		display: inline-block;
	}
}

.header .navbar.navbar-fixed .navbar-brand .logo-light {
	display: none;
}

.header .navbar.navbar-fixed .navbar-nav>li>a {
	color: var(--global--color-heading);
}

@-webkit-keyframes fadeInDown {
	0% {
		opacity          : 0;
		-webkit-transform: translateY(-200px);
		transform        : translateY(-200px);
	}

	100% {
		opacity          : 1;
		-webkit-transform: translateY(0);
		transform        : translateY(0);
	}
}

@keyframes fadeInDown {
	0% {
		opacity          : 0;
		-webkit-transform: translateY(-200px);
		transform        : translateY(-200px);
	}

	100% {
		opacity          : 1;
		-webkit-transform: translateY(0);
		transform        : translateY(0);
	}
}

.navbar-nav>li.active>ul {
	margin-top: 0;
}

li.has-dropdown>ul.dropdown-menu>li.dropdown-submenu>a:before,
ul.dropdown-menu>li.has-dropdown>a:before {
	font-family: "Font Awesome 6 Pro";
	font-weight: 700;
	content    : "\f105";
	font-size  : 13px;
	position   : absolute;
	right      : 25px;
}

/* Menu Levels */
.dropdown-menu {
	background-color  : transparent;
	position          : relative;
	width             : 100%;
	border            : none;
	margin            : 0;
	border-radius     : 0;
	-webkit-box-shadow: none;
	box-shadow        : none;
}

@media screen and (min-width: 992px) {
	.navbar.navbar-dark {
		background-color: var(--global--color-heading);
	}
}

.navbar.navbar-dark.navbar-fixed .module-social a {
	color: var(--global--color-white);
}

.navbar.navbar-dark.navbar-fixed .module-social a:hover {
	color: var(--global--color-primary);
}

.navbar.navbar-dark .module .module-icon i {
	color: var(--global--color-white);
}

@media screen and (min-width: 992px) {
	.navbar.navbar-dark .navbar-nav>li>a {
		color: var(--global--color-white);
	}

	.navbar.navbar-dark .navbar-nav>li>a:hover {
		color: var(--global--color-primary);
	}

	.navbar.navbar-dark .navbar-nav>li.active>a,
	.navbar.navbar-dark .navbar-nav>li:hover>a {
		color: var(--global--color-white);
	}
}

/* Small Devices, Tablets */
@media only screen and (max-width: 991.98px) {
	.header .navbar {
		padding: 0 40px;
	}
}

@media only screen and (max-width: 991.98px) and (max-width: 767.98px) {
	.header .navbar {
		padding: 0 15px;
	}
}

@media only screen and (max-width: 991.98px) {
	.header .navbar .navbar-brand {
		margin-right: 0;
		height      : 80px;
		line-height : 80px;
	}

	.header .navbar .navbar-brand .logo-light {
		display: none;
	}

	.header .navbar .navbar-brand .logo-phone {
		width: auto;
	}

	.header .navbar .navbar-collapse {
		border-top        : 1px solid #eaeaea;
		position          : absolute;
		left              : 0;
		top               : 100%;
		width             : 100%;
		background-color  : var(--global--color-white);
		padding           : 15px 30px;
		opacity           : 0;
		visibility        : hidden;
		-webkit-transform : translateY(20px);
		-ms-transform     : translateY(20px);
		transform         : translateY(20px);
		-webkit-transition: 0.3s linear;
		-o-transition     : 0.3s linear;
		transition        : 0.3s linear;
	}

	.header .navbar .navbar-collapse.show {
		visibility       : visible;
		opacity          : 1;
		-webkit-transform: translateY(0px);
		-ms-transform    : translateY(0px);
		transform        : translateY(0px);
	}

	.header .navbar.navbar-bordered .navbar-collapse {
		top: calc(100% + 1px);
	}

	/* Navabar */
	.header .navbar .navbar-nav>li {
		margin-right: 0px;
	}

	.header .navbar .navbar-nav>li>a,
	.header .navbar .navbar-nav>li.active>a {
		line-height: 36px;
		color      : var(--global--color-heading);
		display    : block;
		z-index    : 5;
	}

	.header .navbar .navbar-nav>li>a::before,
	.header .navbar .navbar-nav>li>a::after,
	.header .navbar .navbar-nav>li.active>a::before,
	.header .navbar .navbar-nav>li.active>a::after {
		display: none;
	}

	.header .navbar .menu-item::before {
		display: none;
	}

	.header .navbar .has-dropdown::after {
		font-family       : "Font Awesome 6 Pro";
		font-weight       : 700;
		content           : "\f107";
		display           : -webkit-box;
		display           : -ms-flexbox;
		display           : flex;
		-webkit-box-pack  : center;
		-ms-flex-pack     : center;
		justify-content   : center;
		-webkit-box-align : center;
		-ms-flex-align    : center;
		align-items       : center;
		font-size         : 14px;
		position          : absolute;
		border-radius     : 5px;
		right             : 0;
		top               : 5px;
		color             : var(--global--color-heading);
		background-color  : #e0f6e9;
		width             : 25px;
		height            : 25px;
		-webkit-transition: 0.3s ease-in-out;
		-o-transition     : 0.3s ease-in-out;
		transition        : 0.3s ease-in-out;
	}

	.header .navbar .has-dropdown:hover::after,
	.header .navbar .has-dropdown.active::after {
		color: var(--global--color-primary);
	}

	.header .navbar .has-dropdown.show::after {
		content: '\f106';
	}

	.header .navbar .dropdown-menu {
		padding           : 0;
		-webkit-transition: max-height 0.3s ease-in-out;
		-o-transition     : max-height 0.3s ease-in-out;
		transition        : max-height 0.3s ease-in-out;
		max-height        : 0;
		display           : block;
		overflow          : hidden;
	}

	.header .navbar .dropdown-menu li {
		line-height: 26px;
	}

	.header .navbar .dropdown-menu li.current a {
		background-color: #e0f6e9;
		color           : var(--global--color-primary);
	}

	.header .navbar .dropdown-menu li a {
		margin-left   : 15px;
		color         : var(--global--color-heading);
		font-size     : 15px;
		font-weight   : 500;
		display       : block;
		padding       : 4px 15px;
		margin-bottom : 5px;
		text-transform: capitalize;
		border-radius : 8px 8px 0 8px;
	}

	.header .navbar .dropdown-menu li a:hover {
		background-color: #e0f6e9;
		color           : var(--global--color-primary);
	}

	.header .navbar .show>.dropdown-menu {
		max-height: 2000px;
	}

	.header .navbar .dropdown-submenu.show .dropdown-menu {
		max-height: 2000px;
	}

	.header .navbar .dropdown-submenu .dropdown-menu {
		padding-left: 20px;
	}

	.header .navbar li.has-dropdown.mega-dropdown>.dropdown-menu .row .menu-head {
		font-size     : 14px;
		font-family   : var(--global--font-heading);
		font-weight   : 700;
		color         : var(--global--font-heading);
		text-transform: capitalize;
		margin-bottom : 15px;
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.navbar-nav>li {
		margin-right: 16px;
	}

	.module .module-icon,
	.module-consultation .btn {
		margin-right: 0;
	}
}

@media only screen and (min-width: 992px) {
	.navbar-collapse {
		padding-right: 0;
		padding-left : 0;
	}

	/* Menu with Dropdown */
	.navbar-nav>li.has-dropdown>ul.dropdown-menu {
		-webkit-box-shadow      : 0px 5px 41.5px rgba(40, 40, 40, 0.11);
		box-shadow              : 0px 5px 41.5px rgba(40, 40, 40, 0.11);
		-webkit-transition      : all 300ms linear;
		-o-transition           : all 300ms linear;
		transition              : all 300ms linear;
		opacity                 : 0;
		visibility              : hidden;
		position                : absolute;
		left                    : -40px;
		right                   : auto;
		-webkit-transform-origin: left top;
		-ms-transform-origin    : left top;
		transform-origin        : left top;
		-webkit-transform       : translateY(20px);
		-ms-transform           : translateY(20px);
		transform               : translateY(20px);
		display                 : block;
		width                   : 235px;
		padding                 : 24px 40px 25px;
		background-color        : var(--global--color-white);
		border-radius           : 0px 0px 8px 8px;
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu>li {
		padding-right : 0;
		position      : relative;
		vertical-align: top;
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a {
		-webkit-transition: 0.3s ease-in-out;
		-o-transition     : 0.3s ease-in-out;
		transition        : 0.3s ease-in-out;
		position          : relative;
		font-family       : var(--global--font-body);
		color             : var(--global--color-body);
		padding           : 10px 0px 9px 0;
		display           : block;
		font-size         : 14px;
		font-weight       : 400;
		-webkit-transition: all 300ms ease-in-out;
		-o-transition     : all 300ms ease-in-out;
		transition        : all 300ms ease-in-out;
		text-transform    : capitalize;
		border-radius     : 0;
		border-bottom     : 1px solid #eaeaea;
		position          : relative;
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a::before {
		content           : '\e905';
		color             : var(--global--color-primary);
		font-family       : 'energia';
		position          : absolute;
		top               : 50%;
		left              : -22px;
		font-size         : 12px;
		opacity           : 0;
		visibility        : hidden;
		-webkit-transform : translate(-5px, -50%);
		-ms-transform     : translate(-5px, -50%);
		transform         : translate(-5px, -50%);
		-webkit-transition: 0.3s ease-in-out;
		-o-transition     : 0.3s ease-in-out;
		transition        : 0.3s ease-in-out;
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a::after {
		content           : '';
		position          : absolute;
		bottom            : -1px;
		left              : 0;
		height            : 1px;
		width             : 0px;
		-webkit-transition: 0.3s ease;
		-o-transition     : 0.3s ease;
		transition        : 0.3s ease;
		background-color  : var(--global--color-primary);
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:active,
	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:focus,
	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:hover {
		color: var(--global--color-primary);
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:active::before,
	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:focus::before,
	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:hover::before {
		visibility       : visible;
		opacity          : 1;
		-webkit-transform: translate(0px, -50%);
		-ms-transform    : translate(0px, -50%);
		transform        : translate(0px, -50%);
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:active::after,
	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:focus::after,
	.navbar-nav>li.has-dropdown>ul.dropdown-menu li a:hover::after {
		width: 100%;
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li:last-child a {
		border-bottom: 0;
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li:last-child a::after {
		content: unset;
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li.current>a {
		border-color: var(--global--color-primary);
		color       : var(--global--color-primary);
	}

	.navbar-nav>li.has-dropdown>ul.dropdown-menu li.current>a::before {
		visibility       : visible;
		opacity          : 1;
		-webkit-transform: translate(0px, -50%);
		-ms-transform    : translate(0px, -50%);
		transform        : translate(0px, -50%);
	}

	.navbar-nav>li.has-dropdown:hover>ul.dropdown-menu {
		opacity          : 1;
		visibility       : visible;
		-webkit-transform: translateY(0);
		-ms-transform    : translateY(0);
		transform        : translateY(0);
		z-index          : 100;
		display          : block;
	}

	.navbar-nav li ul.dropdown-menu.left {
		left       : -650px !important;
		margin-left: 0 !important;
	}

	.navbar-nav li.has-dropdown.mega-dropdown>.dropdown-menu {
		background-color: var(--global--color-white);
		padding         : 0;
		width           : 510px;
	}

	.navbar-nav li.has-dropdown.mega-dropdown>.dropdown-menu li {
		padding-left : 0;
		padding-right: 0;
	}

	.navbar-nav li.has-dropdown.mega-dropdown>.dropdown-menu .row {
		background-color: var(--global--color-white);
		margin-right    : 0;
		margin-left     : 0;
		padding         : 35px 23px;
	}

	.navbar-nav li.has-dropdown.mega-dropdown>.dropdown-menu .row .menu-head {
		font-size     : 14px;
		font-family   : var(--global--font-heading);
		font-weight   : 700;
		color         : var(--global--font-heading);
		text-transform: capitalize;
		margin-bottom : 15px;
	}

	.navbar-nav li.has-dropdown.mega-dropdown>.dropdown-menu .row ul {
		margin : 0;
		padding: 0;
	}

	.navbar-nav li.has-dropdown.mega-dropdown>.dropdown-menu .row ul li {
		line-height: 2.5;
	}

	.navbar-nav li.has-dropdown.mega-dropdown>.dropdown-menu .row ul li.title {
		color         : var(--global--color-heading);
		font-size     : 13px;
		font-weight   : 600;
		text-transform: capitalize;
	}

	.navbar-nav li.has-dropdown.mega-dropdown>.dropdown-menu .row ul li a {
		margin: 0;
	}

	.navbar-nav.ml-auto.mr-auto li.has-dropdown.mega-dropdown {
		position: absolute;
	}

	.navbar-nav.ml-auto:not(.mr-auto) li.has-dropdown.mega-dropdown {
		position: relative;
	}
}