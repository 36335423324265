@use '../utils' as *;
*,
*::before,
*::after {
    box-sizing: border-box;
}
:root {
	--global--color-primary   : #32c36c;
	--global--color-primary-90: rgba(50, 195, 108, 0.9);
	--global--color-primary-70: rgba(50, 195, 108, 0.7);
	--global--color-heading   : #253745;
	--global--color-secondary : #2b3e4b;
	--global--color-body      : #414a53;
	--global--color-white     : #ffffff;
	--global--color-white-90  : rgba(255, 255, 255, 0.9);
	--global--color-gray      : #f9f9f9;
	--global--font-body       : 'inter', sans-serif;
	--global--font-heading    : 'inter', sans-serif;
	--global--font-secondary  : 'inter', sans-serif;
    --swiper-theme-color: #12b0ea !important;
    --colorMain: #32c36c;
    --colorMain2: #09445a;
    --colorMain3: #dd9044;
    --colorMain4: #ff0000;
    --colorDarkGrey: #09445a;
    --colorDark: #042b3d;
    --colorGrey: #f2f5f7;
    --colorFont: #999999;
    --colorMainRgb: 221, 68, 84;
    --colorMain2Rgb: 9, 68, 90;
    --colorMain3Rgb: 221, 144, 68;
    --colorMain4Rgb: 255, 0, 0;
    --colorDarkGreyRgb: 9, 68, 90;
    --colorDarkRgb: 4, 43, 61;
    --colorGreyRgb: 242, 245, 247;
    --fontColorRgb: 153, 153, 153;
    --colorMainDarken10: #c92536;
    --colorMainLighten10: #e56f7b;
    --colorDarkGreyDarken5: #073243;
    --colorFontDarken7: #878787;
    --colorFontDarken10: #808080;
    --colorDarkLighten2: #053247;
    --colorGreyDarken6: #dfe6eb;
}
/*------------------------------------*\
		#RESET
\*------------------------------------*/
*,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
a,
dl,
dt,
dd,
ol,
ul,
li {
	list-style: none;
	margin    : 0;
	padding   : 0;
}

*,
:active,
:focus {
	outline: none !important;
}

ul,
ol {
	list-style: none;
	margin    : 0;
	padding   : 0;
}

textarea {
	resize: none;
}

::-moz-selection {
	text-shadow: none;
}

::selection {
	text-shadow: none;
}

::-moz-selection {
	text-shadow: none;
}

::-webkit-selection {
	text-shadow: none;
}

.form-control {
	-webkit-box-shadow: none;
	box-shadow        : none;
}

button {
	background-color  : transparent;
	-webkit-box-shadow: none;
	box-shadow        : none;
	border            : none;
	outline           : none;
	padding           : 0;
}

textarea {
	resize: none;
}

/*------------------------------------*\
		#GLOBAL STYLES
\*------------------------------------*/
body,
html {
	overflow-x: hidden;
}

body {
	background-color: var(--global--color-white);
	font-family     : var(--global--font-body);
	font-size       : 14px;
	font-weight     : 400;
	color           : var(--global--color-body);
	margin          : 0;
}

::-moz-selection {
	background-color: var(--global--color-primary);
	color           : var(--global--color-white);
	text-shadow     : none;
}

::selection {
	background-color: var(--global--color-primary);
	color           : var(--global--color-white);
	text-shadow     : none;
}

a {
	font-family       : var(--global--font-body);
	font-weight       : 400;
	color             : var(--global--color-primary);
	-webkit-transition: all 300ms ease-in-out;
	-o-transition     : all 300ms ease-in-out;
	transition        : all 300ms ease-in-out;
	text-decoration   : none;
}

a:hover {
	text-decoration: none;
}

p {
	font-family: var(--global--font-body);
	font-weight: 400;
}
.green{
	color: var(--global--color-primary);
}
textarea {
	resize: none;
}

.fullscreen {
	height: 100vh !important;
}

section {
	background-color: var(--global--color-white);
	padding         : 120px 0;
	overflow        : hidden;
	position: relative;
}

@media only screen and (max-width: 767px) {
	section {
		padding: 70px 0;
	}
}

hr {
	background-color: #eaeaea;
	border-color    : #eaeaea;
	margin          : 0;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
	.fullscreen {
		height: auto !important;
	}
}

/*------------------------------------*\
		#TYPOGRAPHY
\*------------------------------------*/
/* Heading Text */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--global--font-heading);
	font-weight: 700;
	margin     : 0 0 28px;
	line-height: 1;
}

.h1,
h1 {
	font-size: 52px;
}

.h2,
h2 {
	font-size: 42px;
}

.h3,
h3 {
	font-size: 38px;
}

.h4,
h4 {
	font-size: 28px;
}

.h5,
h5 {
	font-size: 24px;
}

.h6,
h6 {
	font-size: 18px;
}

p {
	font-family: var(--global--font-body);
	font-size  : 16px;
	font-weight: 400;
	line-height: 28px;
}

.lead {
	font-size  : 16px;
	line-height: 1.8;
}
.container {
    position: relative;
}
.wow { visibility: hidden; }

.lazyload {
	opacity: 0;
}

.lazyloading {
	opacity: 1;
	transition: opacity 300ms;
	background: #f7f7f7 url(../../media/loading.svg) no-repeat center;
	background-size: 50px;
}
.p-relative {
    position: relative;
}
/* Aligning Text */
.text--left {
	text-align: left !important;
}

.text--right {
	text-align: right !important;
}

.text--center {
	text-align: center !important;
}

.text--just {
	text-align: justify !important;
}

.align--top {
	vertical-align: top;
}

.align--bottom {
	vertical-align: bottom;
}

.align--middle {
	vertical-align: middle;
}

.align--baseline {
	vertical-align: baseline;
}
@media (min-width: 768px){
.text-md-right {
    text-align: right!important;
}
}

/* Weight Text */
.bold {
	font-weight: bold !important;
}

.regular {
	font-weight: normal !important;
}

.medium {
	font-weight: 500 !important;
}

.italic {
	font-style: normal;
}

.break-word {
	word-wrap: break-word;
}

.no-wrap {
	white-space: nowrap;
}

/* Text Color */
.text-white {
	color: var(--global--color-white) !important;
}

.text-gray {
	color: var(--global--color-gray) !important;
}

.text-black {
	color: var(--global--color-heading);
}

.text-theme {
	color: var(--global--color-primary) !important;
}

.text--capitalize {
	text-transform: capitalize !important;
}

.text--uppercase {
	text-transform: uppercase !important;
}

.font-heading {
	font-family: var(--global--font-heading);
}

.font-heading {
	font-family: var(--global--font-heading);
}

.font-18 {
	font-size: 24px;
}

.font-16 {
	font-size: 16px;
}

.font-20 {
	font-size: 20px;
}

.font-40 {
	font-size: 40px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
	text-center-xs {
		text-align: center !important;
	}
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	text-center-sm {
		text-align: center !important;
	}
}

.higlighted-style1 {
	background-color: var(--global--color-primary);
	color           : var(--global--color-white);
	padding         : 5px 0;
}

.higlighted-style2 {
	background-color: var(--global--color-heading);
	color           : var(--global--color-white);
	padding         : 5px 0;
}

.higlighted-style3 {
	background-color: var(--global--color-gray);
	color           : var(--global--color-primary);
	padding         : 5px 0;
}

/*------------------------------------*\
		#COLORS
\*------------------------------------*/
.color-heading {
	color: var(--global--color-heading) !important;
}

.color-theme {
	color: var(--global--color-primary) !important;
}

.color-white {
	color: var(--global--color-white) !important;
}

.color-gray {
	color: var(--global--color-gray) !important;
}

/*------------------------------------*\
		#Align
\*------------------------------------*/
address,
blockquote,
dd,
dl,
fieldset,
form,
ol,
p,
pre,
table,
ul {
	margin-bottom: 0;
}

/*------------------------------------*\
		#Grid
\*------------------------------------*/
.row-no-padding [class*="col-"] {
	padding-left : 0 !important;
	padding-right: 0 !important;
}

.col-lg-5ths,
.col-md-5ths,
.col-sm-5ths,
.col-xs-5ths {
	position     : relative;
	min-height   : 1px;
	padding-right: 15px;
	padding-left : 15px;
}

.col-xs-5ths {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 1200px) {
	.col-lg-5ths {
		width: 20%;
		float: left;
	}
}

.col-content {
	padding: 120px 100px 110px 120px;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) and (max-width: 767px) {
	.col-content {
		padding: 125px 18px 0 !important;
	}
}

/* Postion Helpers */
.pos-fixed {
	position: fixed;
}

.pos-relative {
	position: relative;
}

.pos-absolute {
	position: absolute;
}

.pos-static {
	position: static;
}

.pos-top {
	top: 0;
}

.pos-bottom {
	bottom: 0;
}

.pos-right {
	right: 0;
}

.pos-left {
	left: 0;
}

.pos-vertical-center {
	position         : relative;
	top              : 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform    : translateY(-50%);
	transform        : translateY(-50%);
}

.height-700 {
	height: 700px !important;
}

.height-500 {
	height: 500px !important;
}

.height-800 {
	height: 800px !important;
}

@media only screen and (min-width: 992px) {

	.hidden-lg,
	.hidden-md {
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	.col-lg-5ths {
		-ms-flex        : 0 0 20%;
		-webkit-box-flex: 0;
		flex            : 0 0 20%;
		max-width       : 20%;
	}
}

/*------------------------------------*\
		#Blockquotes
\*------------------------------------*/
.blockquote,
blockquote {
	font-size  : 20px;
	font-family: var(--global--font-heading);
	color      : var(--global--color-body);
	font-style : normal;
	line-height: 28px;
	font-weight: 500;
	padding    : 30px 0 30px 30px;
	position   : relative;
	border-left: 6px solid var(--global--color-heading);
}

.blockquote .quote-author,
blockquote .quote-author {
	font-family: var(--global--font-heading);
	display    : block;
	font-size  : 13px;
	font-style : normal;
	font-weight: 600;
	line-height: 1;
	color      : var(--global--color-heading);
	margin-top : 18px;
}

.blockquote .author,
blockquote .author {
	font-family: var(--global--font-body);
	display    : block;
	font-size  : 18px;
	font-style : italic;
	font-weight: 600;
	line-height: 1;
	color      : var(--global--color-heading);
	margin-top : 18px;
}

.blockquote-1 {
	border-left: 6px solid var(--global--color-heading);
}

.blockquote-2 {
	position   : relative;
	border-left: none;
}

.blockquote-2::before,
.blockquote-2::after {
	font-family: "Font Awesome 5 Free";
	font-size  : 16px;
	position   : absolute;
	color      : var(--global--color-primary);
	line-height: 1.8;
	font-weight: 600;
}

.blockquote-2::before {
	content: "\f10d";
	top    : 0;
	left   : 0;
}

.blockquote-2::after {
	content: "\f10e";
	right  : 0;
	bottom : 0;
}

.blockquote-3 {
	background-color: var(--global--color-heading);
}

.blockquote-3 .author {
	color: var(--global--color-body);
}

.sprig-component{
	--bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--bs-gutter-x)*-.5);
    margin-right: calc(var(--bs-gutter-x)*-.5);
    margin-top: calc(var(--bs-gutter-y)*-1);
  }
  .sprig-component>* {
    flex-shrink: 0;
    margin-top: var(--bs-gutter-y);
    max-width: 100%;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
	@media #{$sm} {
        width: 100%;
    }
}

// List
.list {
    font-size: 0;
    > li {
        display: inline-block;vertical-align: top;
    }
}
.list-1 {
    > li {
        width: 100%;
    }
}
.img-box {
    position: relative;
    &::before {
        content: "";
        display: block;
    }
    > img {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
.text-right {
    text-align: right!important
}
.text-md-right {
    text-align: right!important;
}
.white{
	color:  var(--tp-common-white) !important;
}

.js-load-more {
    margin-top: 10px;
    border: 1px solid var(--global--color-heading)!important;
}

.mfp-fade.mfp-bg {
	opacity: 0;
  
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
  }
  /* overlay animate in */
  .mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
  }
  /* overlay animate out */
  .mfp-fade.mfp-bg.mfp-removing {
	opacity: 0;
  }
  
  /* content at start */
  .mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
  
	-webkit-transition: all 0.15s ease-out;
	-moz-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
  }
  /* content animate it */
  .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1;
  }
  /* content animate out */
  .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0;
  }