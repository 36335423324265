/*------------------------------------*\
		#Heading
\*------------------------------------*/
.heading {
	margin-bottom: 48px;
	position: relative;
}

@media screen and (max-width: 991.98px) {
	.heading {
		text-align: center;
	}
}

.heading .heading-subtitle {
	font-family   : var(--global--font-body);
	text-transform: capitalize;
	color         : var(--global--color-primary);
	font-size     : 15px;
	font-weight   : 700;
	line-height   : 1;
	margin-bottom : 13px;
}

@media screen and (max-width: 991.98px) {
	.heading .heading-subtitle {
		line-height: 1.5;
	}
}

.heading .heading-subtitle.heading-subtitle-bg {
	display         : inline-block;
	width           : -webkit-max-content;
	width           : -moz-max-content;
	width           : max-content;
	padding         : 12px 20px;
	border-radius   : 4px;
	background-color: #e0f6e9;
}

@media screen and (max-width: 991.98px) {
	.heading .heading-subtitle.heading-subtitle-bg {
		width: auto;
	}
}

.heading .heading-title {
	font-family   : var(--global--font-heading);
	font-size     : 40px;
	line-height   : 54px;
	margin-bottom : 25px;
	font-weight   : 700;
	color         : var(--global--color-heading);
	text-transform: uppercase;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading .heading-title {
		font-size  : 35px;
		line-height: 1.4;
	}
}

@media screen and (max-width: 767.98px) {
	.heading .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

.heading .heading-desc {
	font-family  : var(--global--font-body);
	color        : var(--global--color-body);
	font-size    : 16px;
	font-weight  : 400;
	line-height  : 27px;
	margin-bottom: 25px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading .heading-desc {
		font-size  : 15px;
		line-height: 26px;
	}
}

.heading .paragraph {
	font-weight  : 700;
	font-size    : 16px;
	line-height  : 27px;
	color        : #283b6a;
	margin-bottom: 0px;
}

.heading.heading-light .heading-subtitle {
	color: var(--global--color-gray);
}

.heading.heading-light .heading-title {
	color: var(--global--color-white);
}

.heading.heading-light .heading-desc {
	color: var(--global--color-gray);
}

.heading.heading-light .paragraph {
	color: var(--global--color-gray);
}

.heading.heading-light2 .heading-subtitle {
	color: var(--global--color-primary);
}

.heading.heading-light2 .heading-title {
	color: var(--global--color-white);
}

.heading.heading-light2 .heading-desc {
	color: var(--global--color-gray);
}

.heading.heading-light2 .paragraph {
	color: var(--global--color-gray);
}

.heading.heading-1 {
	margin-bottom: 40px;
}

.heading.heading-1 .heading-title {
	margin-bottom: 0;
}

.heading.heading-2 {
	margin-bottom: 70px;
}

.heading.heading-2 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-2 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-2 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-2 .heading-desc:first-child {
	margin-top: 8px;
}

.heading.heading-2 .heading-desc:nth-child(2) {
	margin-bottom: 32px;
}

.heading.heading-3 {
	margin-bottom: 63px;
}

.heading.heading-3 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-3 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media screen and (max-width: 767.98px) {
	.heading.heading-3 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

.heading.heading-4 {
	margin-bottom: 70px;
}

.heading.heading-4 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-4 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-4 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-4 .heading-desc {
	margin-bottom: 32px;
}

.heading.heading-5 {
	margin-bottom: 58px;
}

.heading.heading-5 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-5 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-5 .heading-title {
		font-size  : 35px;
		line-height: 1.4;
	}
}

.heading.heading-6 .heading-title {
	font-size    : 37px;
	line-height  : 54px;
	margin-bottom: 31px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-6 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-6 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

.heading.heading-7 {
	margin-bottom: 41px;
}

.heading.heading-7 .heading-title {
	font-size  : 24px;
	line-height: 1.5;
}

.heading.heading-8 {
	margin-bottom: 56px;
}

.heading.heading-8 .heading-title {
	margin-bottom: 0;
	font-size    : 37px;
	line-height  : 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-8 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-8 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-9 {
	margin-bottom: 96px;
}

.heading.heading-9 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-9 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-9 .heading-title {
		font-size  : 35px;
		line-height: 1.4;
	}
}

.heading.heading-10 .heading-title {
	font-size    : 37px;
	line-height  : 54px;
	margin-bottom: 21px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-10 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-10 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-10 .heading-desc {
	font-weight  : 700;
	margin-bottom: 42px;
}

.heading.heading-11 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-11 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-11 .heading-title {
		font-size  : 35px;
		line-height: 1.4;
	}
}

.heading.heading-12 {
	margin-bottom: 40px;
}

.heading.heading-12 .heading-title {
	margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-12 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-13 {
	margin-bottom: 48px;
}

.heading.heading-13 .heading-title {
	margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-13 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-14 {
	margin-bottom: 23px;
}

.heading.heading-15 {
	margin-bottom: 70px;
}

.heading.heading-15 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-15 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-15 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-15 .heading-desc {
	margin-top   : 8px;
	margin-bottom: 35px;
}

.heading.heading-16 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-16 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-16 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-17 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-17 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-17 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-18 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-18 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-18 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-19 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-19 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-19 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

.heading.heading-20 {
	margin-bottom: 0;
}

.heading.heading-20 .heading-title {
	font-size  : 37px;
	line-height: 54px;
}

@media screen and (max-width: 767.98px) {
	.heading.heading-20 .heading-title {
		font-size  : 32px;
		line-height: 1.4;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.heading.heading-20 .heading-title {
		font-size  : 30px;
		line-height: 1.4;
	}
}

@media screen and (min-width: 992px) {
	.heading.heading-20 .heading-desc:last-child {
		margin-bottom: 0;
	}
}

.heading.heading-21 {
	margin-bottom: 34px;
}

.heading.heading-21 .heading-title {
	font-size    : 24px;
	line-height  : 48px;
	margin-bottom: 0;
}

.heading--white {
    color: #fff;
}

.heading__layout {
    display: none;
    color: transparent;
    font-size: 85px;
    line-height: .85;
    font-weight: 700;
    font-family: "Open Sans",sans-serif;
    letter-spacing: -.020em;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ebebeb;
    position: absolute;
    left: 20px;
    top: -30px;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

@media only screen and (min-width: 992px) {
    .heading__layout {
        display:initial
    }
}

@media only screen and (min-width: 1200px) {
    .heading__layout {
        font-size:100px
    }
}

@media only screen and (min-width: 1600px) {
    .heading__layout {
        font-size:120px
    }
}

.heading--center {
    text-align: center;
    width: 100%
}

.heading--center .heading__layout {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 5px;
    top: auto
}
.layout--dgray {
    -webkit-text-stroke-color: #4e5156;
}