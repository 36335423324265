
@use '../utils' as *;

/*----------------------------------------*/
/*  00. Footer CSS START
/*----------------------------------------*/

.footer {
	background-color: #1a2a36;
	padding-top     : 0;
	padding-bottom  : 0;
	position        : relative;
	/* Footer Widgets */
}

.footer .footer-top {
	padding-top   : 100px;
	padding-bottom: 68px;
}

@media screen and (max-width: 991.98px) {
	.footer .footer-top {
		padding-top   : 70px;
		padding-bottom: 20px;
	}
}

.footer .footer-widget {
	text-align: center;
}

@media screen and (min-width: 768px) {
	.footer .footer-widget {
		text-align: left;
	}
}

@media screen and (max-width: 991.98px) {
	.footer .footer-widget {
		margin-bottom: 30px;
	}
}

.footer .footer-widget .footer-widget-title h5 {
	font-family   : var(--global--font-heading);
	color         : var(--global--color-gray);
	font-size     : 16px;
	font-weight   : 700;
	line-height   : 30px;
	text-transform: capitalize;
	margin-bottom : 30px;
}

.footer .footer-widget .widget-content p {
	font-family  : var(--global--font-body);
	color        : #929ba2;
	font-size    : 14px;
	font-weight  : 400;
	line-height  : 24px;
	margin-bottom: 25px;
}

.footer .widget-links ul {
	padding-left     : 0;
	list-style       : none;
	margin-bottom    : 0;
	overflow         : hidden;
	-webkit-transform: translateY(-5px);
	-ms-transform    : translateY(-5px);
	transform        : translateY(-5px);
}

.footer .widget-links ul li {
	display: block;
}

.footer .widget-links ul li a {
	color         : #929ba2;
	font-size     : 14px;
	font-weight   : 400;
	line-height   : 33px;
	text-transform: capitalize;
	font-family   : var(--global--font-body);
}

.footer .widget-links ul li a:hover {
	color: var(--global--color-primary);
}

.footer .widget-links.widget-icon {
	background           : url("../../media/footer-mouse.png");
	background-position-x: 100%;
	background-position-y: -21px;
	background-repeat    : no-repeat;
}

.footer .widget-contact {
	-webkit-transform: translateY(12px);
	-ms-transform    : translateY(12px);
	transform        : translateY(12px);
}

.footer .widget-contact .widget-content p {
	color        : var(--global--color-white);
	margin-bottom: 15px;
}

.footer .widget-contact .widget-content .phone {
	margin-bottom: 18px;
	font-family  : var(--global--font-body);
	font-weight  : 300;
	font-size    : 34px;
	line-height  : 22px;
	color        : #929ba2;
	display      : -webkit-box;
	display      : -ms-flexbox;
	display      : flex;
}

@media screen and (max-width: 767.98px) {
	.footer .widget-contact .widget-content .phone {
		-webkit-box-pack: center;
		-ms-flex-pack   : center;
		justify-content : center;
	}
}

.footer .widget-contact .widget-content .phone a {
	font-family       : var(--global--font-body);
	font-weight       : 300;
	font-size         : 30px;
	line-height       : 22px;
	margin-left       : 8px;
	color             : var(--global--color-white);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.footer .widget-contact .widget-content .phone a:hover {
	color: var(--global--color-primary);
}

.footer .widget-contact .widget-content .email {
	font-family  : var(--global--font-body);
	font-weight  : 700;
	font-size    : 15px;
	line-height  : 21px;
	color        : var(--global--color-primary);
	margin-bottom: 40px;
}

.footer .widget-contact .widget-content .email a {
	font-family: var(--global--font-body);
	font-weight: 700;
	font-size  : 15px;
	line-height: 21px;
	color      : var(--global--color-primary);
}

.footer .widget-contact .widget-content .email a:hover {
	color: var(--global--color-primary-70);
}

.footer .widget-contact .widget-content .address {
	margin-bottom: 12px;
}

.footer .widget-contact .widget-content .address p {
	font-family  : var(--global--font-body);
	color        : #929ba2;
	font-size    : 14px;
	font-weight  : 400;
	line-height  : 24px;
	margin-bottom: 25px;
	margin-bottom: 0;
}

.footer .widget-contact .widget-content .directions a {
	font-family       : var(--global--font-body);
	font-weight       : 700;
	font-size         : 15px;
	line-height       : 1;
	color             : var(--global--color-white);
	text-transform    : capitalize;
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

@media screen and (max-width: 767.98px) {
	.footer .widget-contact .widget-content .directions a {
		-webkit-box-pack: center;
		-ms-flex-pack   : center;
		justify-content : center;
	}
}

.footer .widget-contact .widget-content .directions a i {
	color             : var(--global--color-white);
	margin-right      : 10px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.footer .widget-contact .widget-content .directions a:hover {
	color: var(--global--color-primary);
}

.footer .widget-contact .widget-content .directions a:hover i {
	color: var(--global--color-primary);
}

.footer-copyright {
	padding: 50px 0 40px;
}

.footer-copyright .copyright {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-pack : justify;
	-ms-flex-pack    : justify;
	justify-content  : space-between;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	width            : 100%;
}

@media screen and (max-width: 991.98px) {
	.footer-copyright .copyright {
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
	}
}

.footer-copyright .copyright span {
	font-family   : var(--global--font-body);
	font-weight   : 400;
	font-size     : 14px;
	color         : var(--global--color-white);
	text-transform: capitalize;
}

@media screen and (max-width: 991.98px) {
	.footer-copyright .copyright span {
		margin-bottom: 40px;
		text-align   : center;
	}
}

.footer-copyright .copyright span a {
	color: var(--global--color-primary);
}

.footer-copyright .copyright span a:hover {
	color: var(--global--color-primary-70);
}

.footer-copyright .copyright .social-icons {
	margin-bottom: 0;
}

.footer-copyright .copyright .social-icons li {
	display     : inline-block;
	margin-right: 30px;
}

@media screen and (max-width: 767.98px) {
	.footer-copyright .copyright .social-icons li {
		margin-right: 15px;
	}
}

.footer-copyright .copyright .social-icons li:last-child {
	margin-right: 0;
}

.footer-copyright .copyright .social-icons li a {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	font-size         : 14px;
	text-transform    : capitalize;
	font-family       : var(--global--font-body);
	font-weight       : 700;
	color             : #929ba2;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.footer-copyright .copyright .social-icons li a:hover {
	color: var(--global--color-primary);
}

.footer-copyright .copyright .social-icons li a:hover i {
	color: var(--global--color-primary);
}

.footer-copyright .copyright .social-icons li a i {
	font-size         : 20px;
	margin-right      : 10px;
	color             : var(--global--color-white);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

