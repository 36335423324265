@use '../utils' as *;

/*----------------------------------------*/
/*  00. SERVICES CSS START
/*----------------------------------------*/

.service_tab_section{
	position: relative;
	z-index: 1;
}
.sm-services{
	&__icon{
		margin-right: 15px;
		& i{
			font-size: 50px;
			color: var(--tp-theme-1);
			transition: .3s;
		}
	}
	&__text{
		span{
			font-size: 14px;
			color: var(--tp-grey-3);
			display: inline-block;
		}
		& h4{
			color: var(--tp-common-white);
			font-size: 20px;
			line-height: 1.3;
			margin-bottom: 0;
			@media #{$xl} {
				font-size: 18px;
			}
			@media #{$sm,$xs} {
				font-size: 18px;
			}
		}
	}
}
//single service
.single-services{
	padding: 35px 40px;
	background: var(--tp-common-white);
	border-radius: 6px;
	& h5{
		font-size: 24px;
		line-height: 1.3;
		margin-bottom: 20px;
	}
	& .services-list{
		& ul{
			li{
				margin-bottom: 12px;
				list-style: none;
				& span{
					font-size: 16px;
					display: inline-block;
				}
			}
		}
	}
	& .sr-button{
		padding-top: 15px;
		border-top: 1px solid var(--tp-border-2);
		& a{
			font-size: 14px;
			font-weight: var(--tp-fw-medium);
			&:hover{
				color: var(--tp-theme-1);
			}
		}
	}
}
.services__slider{
	position: relative;
	margin-right: -370px;
	@media #{$xs,$sm,$md} {
		margin-right: 0;
	}
}
.ser__text{
	& a{
		color: var(--tp-heading-primary);
		text-decoration: underline;
		font-weight: var(--tp-fw-medium);
	}
}
// sm-services css
.sm-services{
	&__lists{
		transform: translateY(-40px);
	}
	&__item{
		.flip-card-front {
			background-color: transparent;
			width: 100%;
			height: 300px;
			border-radius: 6px;
			& .flip-card-icon{
				& i{
					font-size: 80px;
					color: var(--tp-heading-primary);
				}
				&-2{
					& i{
						color: var(--tp-theme-1);
					}
				}
			}
			& .flip-card-title{
				font-size: 20px;
				&-2{
					margin-bottom: 15px;
				}
			}
		}
		  .flip-card-back {
			background-color: transparent;
			width: 100%;
			height: 300px;
			border-radius: 6px;
			& .flip-card-icon{
				& i{
					font-size: 80px;
					color: var(--tp-common-white);
				}
			}
			& .flip-card-title{
				font-size: 20px;
				color: var(--tp-common-white);
			}
			& .flip-card-omore{
				& i{
					color: var(--tp-common-white);
				}
				& p{
					font-size: 14px;
					line-height: 24px;
				}
			}
		  }    
		  .flip-card-inner {
			position: relative;
			width: 100%;
			height: 100%;
			text-align: center;
			transition: transform 0.6s;
			transform-style: preserve-3d;
			box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
			&-2{
				box-shadow: none;
				& p{
					font-size: 14px;
					line-height: 24px;
				}
			}
		  }
		  .flip-card:hover .flip-card-inner {
			transform: rotateY(180deg);
		  }
		  .flip-card-front, .flip-card-back {
			position: absolute;
			width: 100%;
			height: 100%;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
		  }
		  .flip-card-front {
			background-color: #fff;
			color: black;
			border-radius: 6px;
			padding: 85px 0;
			&-2{
				padding: 35px 40px;
				height: 260px;
			}
		  }
		  .flip-card-back {
			background-color: var(--tp-theme-1);
			color: var(--tp-common-white);
			transform: rotateY(180deg);
			border-radius: 6px;
			padding: 65px 0;
			position: relative;
			&-2{
				padding: 35px 40px;
				height: 260px;
			}
			& span{
				display: inline-block;
				font-weight: var(--tp-fw-medium);
			}
			& p{
				color: var(--tp-common-white);
			}
			.flip-card-bg{
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
				& img{
					width: 100%;
					height: 100%;
				}
			}
		}
		@media #{$md,$xs} {
			margin-bottom: 30px;
		}
	}
	&__info{
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media #{$md,$sm,$xs} {
			display: block;
		}
		& h5{
			font-size: 24px;
			color: var(--tp-common-white);
			@media #{$lg} {
				font-size: 22px;
			}
			@media #{$md,$sm,$xs} {
				font-size: 22px;
				margin-bottom: 20px;
			}
		}
	}
	&__more{
		& a{
			font-size: 16px;
			color: var(--tp-common-white);
			transition: .3s;
			& i{
				margin-left: 5px;
			}
			&:hover{
				color: var(--tp-theme-1);
			}
		}
	}
}
//services 
.services{
	&__area{
		@include background ();
		position: relative;
		z-index: 1;
	}
	&__item{
		padding: 50px 45px;
		background: var(--tp-common-white);
		border-radius: 6px;
		@media #{$xl} {
			padding: 50px 25px;
		}
		@media #{$lg} {
			padding: 50px 15px;
		}
		@media #{$md} {
			padding: 50px 30px;
		}
		& .ser{
			&__icon{
				& i{
					font-size: 60px;
					color: var(--tp-theme-1);
				}
			}
			&__title{
				font-size: 20px;
				line-height: 1.4;
				@media #{$md} {
					font-size: 18px;
				}
				& br{
					@media #{$md} {
						display: none;
					}
				}
				&:hover{
					color: var(--tp-theme-1);
				}
			}
			&__more-option{
				& a{
					font-size: 14px;
					color: var(--tp-theme-1);
					font-weight: var(--tp-fw-sbold);
					display: inline-block;
					& i{
						margin-left: 10px;
					}
				}
			}
		}
		& p{
			margin-bottom: 0;
			font-size: 14px;
			line-height: 22px;
		}
		&-tp{
			position: relative;
			box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.03);
			&::after{
				position: absolute;
				content: '';
				left: 15px;
				right: 15px;
				background-color: var(--tp-theme-1);
				border-radius: 6px;
				height: 100%;
				bottom: 0;
				z-index: -1;
				visibility: hidden;
				opacity: 0;
			}
			&::before{
				position: absolute;
				content: '';
				left: 0;
				background-color: var(--tp-common-white);
				border-radius: 6px;
				height: 100%;
				width: 100%;
				top: 0;
				z-index: -1;
				visibility: hidden;
				opacity: 0;
			}
			&:hover{
				&::after,
				&::before{
					visibility: visible;
					opacity: 1;
				}

				&::after{
					bottom: -15px;
				}

				&::before{
					top: -25px;
				}
			}
		}
		&-grid{
			position: relative;
			border: 2px solid var(--tp-border-2);
			&::after{
				position: absolute;
				content: '';
				left: 15px;
				right: 15px;
				background-color: var(--tp-theme-1);
				border-radius: 6px;
				height: 100%;
				bottom: 0;
				z-index: -1;
				visibility: hidden;
				opacity: 0;
			}
			&::before{
				position: absolute;
				content: '';
				left: -2px;
				background-color: var(--tp-common-white);
				border-radius: 6px;
				height: 100%;
				width: 101.5%;
				top: 0;
				z-index: -1;
				visibility: hidden;
				opacity: 0;
				border: 2px solid var(--tp-border-2);
				transition: .3s;
			}
			&:hover{
				border-top-color: transparent;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				&::after,
				&::before{
					visibility: visible;
					opacity: 1;
				}
				&::after{
					bottom: -15px;
				}
				&::before{
					top: -25px;
				}
			}
		}
	}
}
//related services
.related{
	&__services{
		position: relative;
		&-title{
			font-size: 24px;
			font-weight: 600;
		}
	}
}

// cta css
.cta{
	&__area{
		@include background();
		position: relative;
		background-attachment: fixed;
		z-index: 1;
		&::before{
			position: absolute;
			content: "";
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(52, 58, 64, 0.6);
			z-index: -1;
		}
	}
	&__title{
		font-size: 60px;
		color: var(--tp-common-white);
		& br{
			@media #{$lg,$sm,$xs} {
				display: none;
			}
		}
		@media #{$md} {
			font-size: 40px;
		}
		@media #{$sm,$xs} {
			font-size: 30px;
		}
	}
	&__button{
		& a{
			@media #{$xs} {
				margin-right: 0;
				margin-top: 20px;
			}
		}
	}
}
.hide{
	display: none;
}
/* history css */
.history{
    &__area{
        position: relative;
        z-index: 1;
    }
    &__right{
        position: relative;
        &-bg{
            position: absolute;
            width: 35%;
            top: 0;
            height: 100%;
            right: 0;
            z-index: -1;
            @media #{$md,$sm,$xs}{
                display: none;
            }
            & img{
                width: 100%;
                height: 100%;
            }
        }
    }
    &__item{
        & .sm-item{
            &-thumb{
                position: relative;
				overflow: hidden;
                 & img{
					border-top-left-radius: 6px;
					border-top-right-radius: 6px;
					transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
                 }
            }
            &-tag{
                position: absolute;
                bottom: 0;
                left: 30px;
                & span{
                    width: 70px;
                    height: 30px;
                    line-height: 30px;
                    color: var(--tp-common-white);
                    background: var(--tp-theme-1);
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    display: inline-block;
                    text-align: center;
                }
            }
            &-content{
                padding: 30px;
                background: var(--tp-common-white);
                border-bottom-left-radius: 6px;
                 border-bottom-right-radius: 6px;
                 @media #{$xl} {
                     padding: 30px 20px;
                 }
                & h6{
                 font-size: 20px;
                 transition: .3s;
                 @media #{$xl} {
                     font-size: 18px;
                 }
                 &:hover{
                     color: var(--tp-theme-1);
                 }
                }
                & p{
                	margin-bottom: 0;
					font-size: 14px;
					line-height: 22px;
                }
            }
        }
		&:hover .sm-item-thumb img{
			transform: scale3d(1.1, 1.1, 1.1);
		}
    }
}
//nevigation css
.nevigation{
	&__info{
		display: flex;
		align-items: center;
		& img{
			margin-right: 20px;
		}
		&-2{
			text-align: right;
			& img{
				margin-left: 20px;
				margin-right: 0;
			}
			justify-content: end;
		}
	}
	&__content{
		& p{
			margin-bottom: 0;
		}

	}
	&__title{
		font-size: 18px;
		transition: .3s;
		&:hover{
			color: var(--tp-theme-1);
		}
	}
	&__icon{
		& i{
			font-size: 40px;
			color: var(--tp-theme-1);
		}
	}
}

.contact-trigger {
    position: relative;
    color: #fff;
    background: #003d90;
    padding: 28px 30px 40px 30px;
    width: 100%;
    max-width: 370px;
    -webkit-clip-path: polygon(90% 0,100% 18%,100% 100%,0 100%,0 0);
    clip-path: polygon(90% 0,100% 18%,100% 100%,0 100%,0 0)
}

.contact-trigger--style-2 {
    -webkit-clip-path: polygon(85% 0,100% 10%,100% 100%,0 100%,0 0);
    clip-path: polygon(85% 0,100% 10%,100% 100%,0 100%,0 0)
}

.contact-trigger__img {
    height: 100px;
    width: auto;
    position: absolute;
    bottom: 0;
    right: 2px
}

.contact-trigger__title {
    line-height: 45px;
	color: #fff;
}

.contact-trigger__text {
    margin-top: 13px;
	color: #fff;
}

.contact-trigger .button {
    position: relative;
    z-index: 2;
    margin-top: 20px
}


/*--------------------------------
		#Services
---------------------------------*/
.services {
	padding-top     : 120px;
	padding-bottom  : 100px;
	background-color: #f6f7f8;
}

@media screen and (max-width: 991.98px) {
	.services {
		padding-top   : 80px;
		padding-bottom: 70px;
	}
}

.services .service-panel {
	position          : relative;
	padding           : 16px 40px 40px;
	cursor            : pointer;
	margin-bottom     : 30px;
	-webkit-box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
	border-radius     : 8px;
	background-color  : var(--global--color-white);
}

@media screen and (min-width: 992px) {
	.services .service-panel {
		-webkit-box-align: start;
		-ms-flex-align   : start;
		align-items      : flex-start;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.services .service-panel {
		padding-left : 20px;
		padding-right: 20px;
	}
}

@media screen and (max-width: 991.98px) {
	.services .service-panel {
		margin   : 0 auto 30px;
		max-width: 370px;
	}
}

@media screen and (max-width: 767.98px) {
	.services .service-panel {
		padding-left : 25px;
		padding-right: 25px;
	}
}

.services .service-panel::after {
	content           : '';
	position          : absolute;
	bottom            : 0;
	right             : 0;
	height            : 4px;
	width             : calc(100% - 40px);
	background-color  : var(--global--color-primary);
	border-radius     : 0 0 8px 0;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.services .service-panel::after {
		width: calc(100% - 20px);
	}
}

@media screen and (max-width: 767.98px) {
	.services .service-panel::after {
		width: calc(100% - 20px);
	}
}

.services .service-panel:hover:after {
	height: 10px;
}

.services .service-panel:hover .service-icon i {
	-webkit-transform: rotateY(360deg);
	transform        : rotateY(360deg);
}

.services .service-panel:hover .service-content .btn::before {
	-webkit-transform       : scaleX(1);
	-ms-transform           : scaleX(1);
	transform               : scaleX(1);
	-webkit-transform-origin: left center;
	-ms-transform-origin    : left center;
	transform-origin        : left center;
}

.services .service-panel .service-icon {
	position               : relative;
	top                    : 0px;
	left                   : 0px;
	z-index                : 2;
	-webkit-transform-style: preserve-3d;
	transform-style        : preserve-3d;
	-webkit-perspective    : 1300px;
	perspective            : 1300px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.services .service-panel .service-icon {
		left: 25px;
	}
}

@media screen and (max-width: 767.98px) {
	.services .service-panel .service-icon {
		left: 25px;
	}
}

.services .service-panel .service-icon i {
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.services .service-panel .service-icon i::before {
	margin-left       : 0;
	font-size         : 75px;
	color             : var(--global--color-primary);
	-webkit-transition: 300ms ease-in-out;
	-o-transition     : 300ms ease-in-out;
	transition        : 300ms ease-in-out;
}
.services .service-panel .service-icon img{
	width: 100%;
}

.services .service-panel .service-content {
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-webkit-box-orient   : vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction   : column;
	flex-direction       : column;
	-webkit-box-align    : center;
	-ms-flex-align       : center;
	align-items          : center;
}

@media screen and (min-width: 768px) {
	.services .service-panel .service-content {
		-webkit-box-align: start;
		-ms-flex-align   : start;
		align-items      : flex-start;
	}
}

.services .service-panel .service-content h4 {
	width        : 100%;
	margin-bottom: 0;
}

.services .service-panel .service-content h4 a {
	display           : block;
	font-weight       : 700;
	font-size         : 16px;
	line-height       : 29px;
	text-transform    : capitalize;
	padding-bottom    : 26px;
	margin-bottom     : 25px;
	border-bottom     : 1px solid #eaeaea;
	color             : var(--global--color-heading);
	width             : 100%;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.services .service-panel .service-content h4 a:hover {
	color: var(--global--color-primary);
}

.services .service-panel .service-content p {
	font-family  : var(--global--font-body);
	font-size    : 15px;
	line-height  : 25px;
	margin-bottom: 24px;
	color        : var(--global--color-body);
}

.services .service-panel .service-content .advantages-list {
	text-align   : left;
	margin       : 0;
	padding      : 0;
	list-style   : none;
	margin-bottom: 32px;
}

.services .service-panel .service-content .advantages-list li {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	font-family      : var(--global--font-body);
	font-weight      : 700;
	font-size        : 15px;
	line-height      : 36px;
	color            : var(--global--color-body);
	text-transform   : capitalize;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.services .service-panel .service-content .advantages-list li {
		font-size: 14px;
	}
}

.services .service-panel .service-content .advantages-list li::before {
	content          : '\f00c';
	font-family      : "Font Awesome 5 Free";
	font-weight      : 700;
	width            : 20px;
	height           : 20px;
	display          : -webkit-inline-box;
	display          : -ms-inline-flexbox;
	display          : inline-flex;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	background-color : var(--global--color-secondary);
	color            : var(--global--color-white);
	margin-right     : 18px;
	border-radius    : 50%;
	font-size        : 10px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.services .service-panel .service-content .advantages-list li::before {
		margin-right: 8px;
	}
}

.services .service-panel .service-content .btn {
	position          : relative;
	text-transform    : capitalize;
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	width             : 165px;
	height            : 55px;
}

.services .service-panel .service-content .btn i {
	-webkit-transition: all 300ms ease-in-out;
	-o-transition     : all 300ms ease-in-out;
	transition        : all 300ms ease-in-out;
	color             : var(--global--color-white);
	font-size         : 12px;
}

.services .service-panel.services-panel-2 {
	padding-top   : 50px;
	padding-bottom: 50px;
	margin-bottom : 37px;
}

.services .service-panel.services-panel-2 .service-icon {
	position     : relative;
	top          : auto;
	left         : auto;
	margin-bottom: 32px;
}

.services .service-panel.services-panel-2 .service-content h4 a {
	padding-bottom: 0;
	border-bottom : 0;
	margin-bottom : 29px;
}

.services .service-panel.services-panel-2 .service-content p {
	margin-bottom: 31px;
}

.services .service-panel.services-panel-2 .service-content .advantages-list {
	display: none;
}

.services .service-panel.services-panel-3 {
	padding      : 0px;
	margin-bottom: 40px;
}

.services .service-panel.services-panel-3:hover::after {
	height: 4px;
	width : calc(100% - 80px);
}

@media (min-width: 992px) and (max-width: 1200px) {
	.services .service-panel.services-panel-3:hover::after {
		width: calc(100% - 50px);
	}
}

@media screen and (max-width: 767.98px) {
	.services .service-panel.services-panel-3:hover::after {
		width: calc(100% - 60px);
	}
}

.services .service-panel.services-panel-3::after {
	right            : auto;
	left             : 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform    : translateX(-50%);
	transform        : translateX(-50%);
	width            : 0;
	border-radius    : 0;
}

.services .service-panel.services-panel-3 .service-img {
	border-radius: 8px;
	overflow     : hidden;
}

.services .service-panel.services-panel-3 .service-content {
	padding: 33px 40px 40px;
}

@media screen and (max-width: 991.98px) {
	.services .service-panel.services-panel-3 .service-content {
		text-align: center;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.services .service-panel.services-panel-3 .service-content {
		padding-left : 25px;
		padding-right: 25px;
	}
}

@media screen and (max-width: 767.98px) {
	.services .service-panel.services-panel-3 .service-content {
		padding-left : 30px;
		padding-right: 30px;
	}
}

.services .service-panel.services-panel-3 .service-content h4 a {
	padding-bottom: 0;
	border-bottom : 0;
	margin-bottom : 19px;
}

.services .service-panel.services-panel-3 .service-content p {
	margin-bottom: 21px;
}

@media screen and (max-width: 991.98px) {
	.services .service-panel.services-panel-3 .service-content .btn {
		margin: 0 auto;
	}
}

.services .actions-holder {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	padding-top      : 8px;
}

@media screen and (max-width: 991.98px) {
	.services .actions-holder {
		-webkit-box-pack: center;
		-ms-flex-pack   : center;
		justify-content : center;
	}
}

@media screen and (max-width: 767.98px) {
	.services .actions-holder {
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
	}
}

.services .actions-holder .btn {
	margin-right: 30px;
}

.services .actions-holder .btn:first-child {
	width             : 170px;
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
}

.services .actions-holder .btn:last-child {
	width             : 182px;
	margin-right      : 0;
	padding           : 0 27px;
	border-color      : rgba(37, 55, 69, 0.5);
	color             : var(--global--color-heading);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.services .actions-holder .btn:last-child::before {
	background-color: var(--global--color-heading);
}

.services .actions-holder .btn:last-child:hover {
	border-color: var(--global--color-heading);
	color       : var(--global--color-white);
}

@media screen and (max-width: 767.98px) {
	.services .actions-holder .btn {
		margin-right : 0;
		margin-bottom: 20px;
	}
}

.services .more-services {
	text-align: center;
}

.services .more-services p {
	font-family: var(--global--font-body);
	font-weight: 700;
	font-size  : 15px;
	color      : var(--global--color-body);
}

.services .more-services p a {
	position          : relative;
	font-weight       : 700;
	color             : var(--global--color-heading);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	text-transform    : capitalize;
	padding-bottom    : 6px;
}

.services .more-services p a:hover::before {
	width: calc(100% - 20%);
}

.services .more-services p a::before {
	content           : '';
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	position          : absolute;
	bottom            : 0;
	left              : 50%;
	-webkit-transform : translateX(-50%);
	-ms-transform     : translateX(-50%);
	transform         : translateX(-50%);
	width             : 100%;
	height            : 2px;
	background-color  : var(--global--color-primary);
}

.services .more-services .rating i {
	color       : var(--global--color-primary);
	margin-right: 5px;
}

.services .more-services .rating i:last-child {
	margin-right: 0;
}

.services .more-services.more-services-2 {
	display   : -webkit-box;
	display   : -ms-flexbox;
	display   : flex;
	text-align: left;
}

@media screen and (max-width: 991.98px) {
	.services .more-services.more-services-2 {
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
		-webkit-box-align    : center;
		-ms-flex-align       : center;
		align-items          : center;
		text-align           : center;
	}
}

.services .more-services.more-services-2 .rating {
	margin-top       : 4px;
	margin-right     : 40px;
	-ms-flex-negative: 0;
	flex-shrink      : 0;
}

@media screen and (max-width: 991.98px) {
	.services .more-services.more-services-2 .rating {
		margin-right : 0;
		margin-bottom: 10px;
	}
}

.services .more-services.more-services-2 a {
	color: var(--global--color-primary);
}

.services .more-services.more-services-2 a::before {
	background-color: var(--global--color-heading);
}

.services .more-services.more-services-2 p {
	font-weight: 400;
}

.services.services-2 {
	padding-top   : 114px;
	padding-bottom: 65px;
}

@media screen and (max-width: 991.98px) {
	.services.services-2 {
		padding-top   : 60px;
		padding-bottom: 70px;
	}
}

.services.services-2 .owl-dots {
	margin-bottom: 62px;
}

.services.services-3 {
	padding-top        : 130px;
	padding-bottom     : 110px;
	background-position: top center;
}

@media screen and (max-width: 991.98px) {
	.services.services-3 {
		padding-top   : 70px;
		padding-bottom: 70px;
	}
}

.services.services-4 {
	padding-top   : 130px;
	padding-bottom: 40px;
}

@media screen and (max-width: 991.98px) {
	.services.services-4 {
		padding-top   : 70px;
		padding-bottom: 0px;
	}
}

.services.services-4 .service-panel {
	margin-bottom: 90px;
}

.service-single {
	padding-top   : 110px;
	padding-bottom: 60px;
}

@media screen and (max-width: 991.98px) {
	.service-single {
		padding-top   : 70px;
		padding-bottom: 20px;
	}
}

.service-single .sidebar {
	margin-left : 0;
	margin-right: auto;
}

.service-single .service-entry .entry-content .entry-infos {
	margin-bottom: 66px;
}

@media screen and (max-width: 991.98px) {
	.service-single .service-entry .entry-content .entry-infos {
		text-align: center;
	}
}

.service-single .service-entry .entry-content .entry-infos .entry-heading {
	font-size     : 24px;
	line-height   : 1;
	font-weight   : 700;
	margin-bottom : 21px;
	color         : var(--global--color-secondary);
	text-transform: capitalize;
}

.service-single .service-entry .entry-content .entry-infos .entry-desc {
	font-size    : 16px;
	line-height  : 26px;
	margin-bottom: 28px;
}

.service-single .service-entry .entry-content .entry-infos .entry-desc:last-child {
	margin-bottom: 0;
}

.service-single .service-entry .entry-content .entry-introduction .entry-heading {
	margin-bottom    : 17px;
	-webkit-transform: translateY(-4px);
	-ms-transform    : translateY(-4px);
	transform        : translateY(-4px);
}

.service-single .service-entry .entry-content .entry-introduction .entry-desc:nth-child(3) {
	margin-bottom: 32px;
}

.service-single .service-entry .entry-content .entry-introduction img {
	max-width         : 100%;
	border-radius     : 8px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

@media screen and (max-width: 767.98px) {
	.service-single .service-entry .entry-content .entry-introduction img {
		margin-bottom: 30px;
	}
}

.service-single .service-entry .entry-content .entry-introduction img:hover {
	-webkit-transform: translateY(-5px);
	-ms-transform    : translateY(-5px);
	transform        : translateY(-5px);
}

.service-single .service-entry .entry-content .entry-stats {
	margin-bottom: 57px;
}

.service-single .service-entry .entry-content .entry-stats img {
	max-width: 100%;
	margin   : 0 auto;
	display  : -webkit-box;
	display  : -ms-flexbox;
	display  : flex;
}

@media screen and (max-width: 991.98px) {
	.service-single .service-entry .entry-content .entry-stats img {
		margin-top: 30px;
	}
}

.service-single .service-entry .entry-content .entry-processes {
	margin-bottom   : 66px;
	background-color: #e0f6e9;
	border-radius   : 12px;
	padding         : 45px 40px 40px;
}

@media screen and (max-width: 767.98px) {
	.service-single .service-entry .entry-content .entry-processes {
		padding: 60px 25px 40px;
	}
}

.service-single .service-entry .entry-content .entry-processes .entry-body {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: start;
	-ms-flex-align   : start;
	align-items      : flex-start;
}

@media screen and (max-width: 767.98px) {
	.service-single .service-entry .entry-content .entry-processes .entry-body {
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
		-webkit-box-align    : center;
		-ms-flex-align       : center;
		align-items          : center;
	}
}

.service-single .service-entry .entry-content .entry-processes .entry-body .process-icon {
	-ms-flex-negative: 0;
	flex-shrink      : 0;
	width            : 170px;
	height           : 170px;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	border-radius    : 50%;
}

@media screen and (max-width: 767.98px) {
	.service-single .service-entry .entry-content .entry-processes .entry-body .process-icon {
		width        : 150px;
		height       : 150px;
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 768px) {
	.service-single .service-entry .entry-content .entry-processes .entry-body .process-icon {
		margin-right: 40px;
		margin-top  : 15px;
	}
}

.service-single .service-entry .entry-content .entry-processes .entry-body .process-icon:hover i {
	-webkit-transform: scale(0.9);
	-ms-transform    : scale(0.9);
	transform        : scale(0.9);
}

.service-single .service-entry .entry-content .entry-processes .entry-body .process-icon i {
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	width             : 110px;
	height            : 110px;
	background-color  : var(--global--color-primary);
	color             : var(--global--color-white);
	border-radius     : 50%;
}

@media screen and (max-width: 767.98px) {
	.service-single .service-entry .entry-content .entry-processes .entry-body .process-icon i {
		width : 90px;
		height: 90px;
	}
}

.service-single .service-entry .entry-content .entry-processes .entry-body .process-icon i::before {
	font-size: 50px;
}

.service-single .service-entry .entry-content .entry-processes .images-holder {
	overflow     : hidden;
	border-radius: 8px;
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-stage-outer {
	overflow: hidden;
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-stage-outer .owl-item {
	opacity: 1;
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav {
	position         : absolute;
	top              : 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform    : translateY(-50%);
	transform        : translateY(-50%);
	width            : 100%;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-pack : justify;
	-ms-flex-pack    : justify;
	justify-content  : space-between;
}

@media screen and (max-width: 767.98px) {
	.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav {
		display: none;
	}
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-prev,
.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-next {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 110px;
	height            : 110px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-prev::before,
.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-next::before {
	color      : var(--global--color-white);
	content    : '\e906';
	font-family: 'energia';
	font-size  : 20px;
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-prev {
	left            : 0;
	border-radius   : 0 8px 8px 0;
	background-color: var(--global--color-primary);
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-prev:hover {
	background-color: var(--global--color-heading);
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-prev:before {
	-webkit-transform: rotate(270deg);
	-ms-transform    : rotate(270deg);
	transform        : rotate(270deg);
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-next {
	right           : 0;
	border-radius   : 8px 0px 0px 8px;
	background-color: var(--global--color-heading);
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-next:hover {
	background-color: var(--global--color-primary);
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-nav .owl-next:before {
	-webkit-transform: rotate(90deg);
	-ms-transform    : rotate(90deg);
	transform        : rotate(90deg);
}

.service-single .service-entry .entry-content .entry-processes .images-holder .carousel .owl-dots {
	position: absolute;
	width   : 100%;
	bottom  : -100px;
}

.service-single .service-entry .entry-content .entry-video .heading-desc {
	margin-bottom: 35px;
}

.service-single .service-entry .entry-content .entry-topics {
	padding-top: 40px;
}

.service-single .service-entry .entry-content .entry-topics .entry-topic {
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-webkit-box-orient   : vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction   : column;
	flex-direction       : column;
	margin-bottom        : 16px;
}

.service-single .service-entry .entry-content .entry-topics .entry-topic:last-of-type {
	margin-bottom: 7px;
}

@media screen and (min-width: 992px) {
	.service-single .service-entry .entry-content .entry-topics .entry-topic {
		-webkit-box-orient   : horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction   : row;
		flex-direction       : row;
	}
}

@media screen and (min-width: 992px) {
	.service-single .service-entry .entry-content .entry-topics .entry-topic h5 {
		-ms-flex-preferred-size: 25%;
		flex-basis             : 25%;
		margin-right           : 5%;
	}
}

@media screen and (min-width: 992px) {
	.service-single .service-entry .entry-content .entry-topics .entry-topic p {
		-ms-flex-preferred-size: 70%;
		flex-basis             : 70%;
		-webkit-transform      : translateY(-4px);
		-ms-transform          : translateY(-4px);
		transform              : translateY(-4px);
	}
}

.service-single .service-entry .entry-content .entry-why {
	margin-bottom: 36px;
}

.service-single .service-entry .entry-content .entry-why .entry-desc {
	margin-bottom: 32px;
}

.service-single .service-entry .entry-content .entry-benefits {
	margin-bottom: 36px;
}

.service-single .service-entry .entry-content .entry-benefits .entry-heading {
	margin-bottom: 37px;
}

.service-single .service-entry .entry-content .projects {
	margin-bottom: 0;
}

.service-single .service-entry .entry-content .projects .entry-heading {
	margin-bottom: 37px;
}