/*------------------------------------*\
		#Video
\*------------------------------------*/
.video {
	position         : relative;
	border-radius    : 12px;
	height           : 375px;
	-webkit-transform: translateY(100px);
	-ms-transform    : translateY(100px);
	transform        : translateY(100px);
	margin-top       : -100px;
}

.video.video-1 {
	position     : relative;
	margin-bottom: 0px;
}

.video.video-1 .btn-video {
	position        : absolute;
	bottom          : 30px;
	left            : 30px;
	background-color: transparent;
	height          : auto;
	padding         : 0;
}

.video.video-2 {
	-webkit-transform: translateY(0);
	-ms-transform    : translateY(0);
	transform        : translateY(0);
	margin-top       : 0;
}

.video.video-2 .btn-video {
	position         : absolute;
	top              : 50%;
	left             : 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform    : translate(-50%, -50%);
	transform        : translate(-50%, -50%);
}

.video.video-3 {
	height           : 525px;
	border-radius    : 8px;
	margin-top       : 0;
	-webkit-transform: translateY(0);
	-ms-transform    : translateY(0);
	transform        : translateY(0);
}

.video.video-3 .btn-video {
	position         : absolute;
	top              : 50%;
	left             : 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform    : translate(-50%, -50%);
	transform        : translate(-50%, -50%);
}

.mfp-bg {
	z-index: 1543;
}

.mfp-wrap {
	z-index: 1642;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform        : scale(1);
	}

	100% {
		-webkit-transform: scale(1.4);
		transform        : scale(1.4);
		opacity          : 0;
	}
}

@keyframes pulse {
	0% {
		-webkit-transform: scale(1);
		transform        : scale(1);
	}

	100% {
		-webkit-transform: scale(1.4);
		transform        : scale(1.4);
		opacity          : 0;
	}
}