@use '../utils' as *;

/*----------------------------------------*/
/*  05. Section Tilte CSS START
/*----------------------------------------*/
.section{
    &__wrapper{
        position: relative;
        &::before{
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background: var(--tp-border-1);
            top: 0;
        }
        & .section__title{
            font-size: 40px;
            line-height: 1.2;
            padding-top: 20px;
            margin-left: 30px;
            @media #{$xl} {
                font-size: 35px;
            }
            @media #{$lg,$md,$sm} {
                font-size: 28px;
            }
            @media #{$xs} {
                font-size: 26px;
            }
        }
        &-2{
            & .section__title{
                margin-left: 0;
            }
            & .sm-title-d{
                color: var(--tp-common-white);
                font-size: 40px;
                @media #{$xl} {
                    font-size: 35px;
                }
                @media #{$lg,$md,$sm} {
                    font-size: 28px;
                }
                @media #{$xs} {
                    font-size: 26px;
                }
            }
            &::before{
                display: none;
            }
            & .st-meta{
                font-size: 14px;
                color: var(--tp-theme-1);
                background: var(--tp-theme-2);
                padding: 2px 10px;
                border-radius: 6px;
                display: inline-block;
                box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
                &-2{
                    font-size: 14px;
                    color: var(--tp-theme-1);
                    background: var(--tp-common-white);
                    padding: 2px 10px;
                    border-radius: 6px;
                    display: inline-block;
                    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
                }
                &-3{
                    font-size: 14px;
                    color: var(--tp-common-white);
                    background: var(--tp-theme-1);
                    padding: 2px 10px;
                    border-radius: 6px;
                    display: inline-block;
                    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
                }
            }
        }
        &-3{
            &::before{
                background: #545a60;
            }
        }
        & .r-text{
            position: absolute;
            left: -5%;
            bottom: 32%;
            transform: rotate(-90deg);
            & span{
                display: inline-block;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: var(--tp-fw-sbold);
                color: var(--tp-grey-1);
                letter-spacing: 1px;
                @media #{$lg,$sm,$xs} {
                    font-size: 12px;
                }
            }
        }
    }
    &__title{
        font-size: 40px;
        line-height: 1.3;
        padding-top: 20px;
        margin-left: 30px;
        text-transform: uppercase;
        @media #{$xl} {
            font-size: 35px;
        }
        @media #{$lg,$sm} {
            font-size: 28px;
        }
        @media #{$xs} {
            font-size: 26px;
        }
    }
}
.section-2{
    &__wrapper{
        & .st-1{
            font-size: 14px;
            font-weight: var(--tp-fw-sbold);
            display: inline-block;
            color: var(--tp-theme-1);
            position: relative;
            margin-bottom: 5px;
            &::before{
                position: absolute;
                content: '';
                background: var(--tp-theme-1);
                width: 40px;
                height: 2px;
                top: 50%;
                right: -50px;
            }
        }
        & .st-2{
            font-size: 16px;
            font-weight: var(--tp-fw-sbold);
            display: inline-block;
            color: var(--tp-theme-1);
            position: relative;
            margin-bottom: 5px;
            &::before{
                position: absolute;
                content: '';
                background: var(--tp-theme-1);
                width: 70px;
                height: 2px;
                top: 50%;
                right: -80px;
            }
            &::after{
                position: absolute;
                content: '';
                background: var(--tp-theme-1);
                width: 70px;
                height: 2px;
                top: 50%;
                left: -80px;
            }
        }
        & .section__title{
            font-size: 60px;
            @media #{$sm,$xs} {
                font-size: 40px;
            }
        }
        & .section__title-sd{
            font-size: 40px;
            color: var(--global--color-heading);
            text-transform: uppercase;
            @media #{$sm,$xs} {
                font-size: 30px;
            }
        }
        & .section__title-sm{
            font-size: 40px;
            @media #{$sm,$xs} {
                font-size: 30px;
            }
        }
    }
}