@use '../utils' as *;
.btn {
	display                    : -webkit-inline-box;
	display                    : -ms-inline-flexbox;
	display                    : inline-flex;
	-webkit-box-pack           : justify;
	-ms-flex-pack              : justify;
	justify-content            : space-between;
	-webkit-box-align          : center;
	-ms-flex-align             : center;
	align-items                : center;
	font-family                : var(--global--font-body);
	position                   : relative;
	z-index                    : 2;
	font-size                  : 15px;
	font-weight                : 700;
	text-transform             : capitalize;
	padding                    : 0 30px;
	border                     : 0;
	width                      : 170px;
	height                     : 65px;
	-webkit-transition         : all .3s ease-in-out;
	-o-transition              : all .3s ease-in-out;
	transition                 : all .3s ease-in-out;
	-webkit-transition-property: background, color;
	-o-transition-property     : background, color;
	transition-property        : background, color;
	border-radius              : 4px;
	overflow                   : hidden;
}

@media screen and (max-width: 767.98px) {
	.btn {
		height: 50px;
	}
}

.btn.no-shadow {
	-webkit-box-shadow: none;
	box-shadow        : none;
}

.btn:focus,
.btn.active,
.btn:active {
	-webkit-box-shadow: none;
	box-shadow        : none;
	outline           : none;
}

.btn i {
	-webkit-transition: all .3s ease-in-out;
	-o-transition     : all .3s ease-in-out;
	transition        : all .3s ease-in-out;
}

.btn::before {
	content                 : '';
	position                : absolute;
	width                   : calc(100%);
	height                  : calc(100%);
	top                     : 0px;
	left                    : 0px;
	z-index                 : -1;
	-webkit-transform       : scaleX(0);
	-ms-transform           : scaleX(0);
	transform               : scaleX(0);
	-webkit-transform-origin: right center;
	-ms-transform-origin    : right center;
	transform-origin        : right center;
	-webkit-transition      : -webkit-transform .24s ease-in-out;
	transition              : -webkit-transform .24s ease-in-out;
	-o-transition           : transform .24s ease-in-out;
	transition              : transform .24s ease-in-out;
	transition              : transform .24s ease-in-out, -webkit-transform .24s ease-in-out;
}

.btn:hover::before {
	-webkit-transform       : scaleX(1);
	-ms-transform           : scaleX(1);
	transform               : scaleX(1);
	-webkit-transform-origin: left center;
	-ms-transform-origin    : left center;
	transform-origin        : left center;
}

/* Button Primary */
.btn--primary {
	background-color: var(--global--color-primary);
	color           : var(--global--color-white);
}

.btn--primary::before {
	background-color: var(--global--color-secondary);
}

.btn--primary:active,
.btn--primary:focus,
.btn--primary:hover {
	color: var(--global--color-white);
}

.btn--primary.btn--inversed::before {
	background-color: var(--global--color-white);
}

.btn--primary.btn--inversed i {
	color: var(--global--color-white);
}

.btn--primary.btn--inversed:active,
.btn--primary.btn--inversed:focus,
.btn--primary.btn--inversed:hover {
	color: var(--global--color-heading);
}

.btn--primary.btn--inversed:active i,
.btn--primary.btn--inversed:focus i,
.btn--primary.btn--inversed:hover i {
	color: var(--global--color-heading);
}

/* Button Secondary*/
.btn--secondary {
	background-color: var(--global--color-heading);
	color           : var(--global--color-white);
}

.btn--secondary::before {
	background-color: var(--global--color-primary);
}

.btn--secondary:active,
.btn--secondary:focus,
.btn--secondary:hover {
	color: var(--global--color-white);
}

.btn--secondary.btn--inversed::before {
	background-color: var(--global--color-secondary);
}

.btn--secondary.btn--inversed:active,
.btn--secondary.btn--inversed:focus,
.btn--secondary.btn--inversed:hover {
	color: var(--global--color-white);
}

/* Button White */
.btn--white {
	background-color: var(--global--color-white);
	color           : var(--global--color-secondary);
}

.btn--white::before {
	background-color: var(--global--color-secondary);
}

.btn--white:active,
.btn--white:focus,
.btn--white:hover {
	color: var(--global--color-white);
}

/* Button Transparent */
.btn--transparent {
	background-color           : transparent;
	color                      : var(--global--color-white);
	border                     : 1px solid var(--global--color-primary);
	-webkit-transition-property: background, color, border-color;
	-o-transition-property     : background, color, border-color;
	transition-property        : background, color, border-color;
}

.btn--transparent::before {
	background-color: var(--global--color-primary);
}

.btn--transparent:active,
.btn--transparent:focus,
.btn--transparent:hover {
	color       : var(--global--color-white);
	border-color: var(--global--color-primary);
}

.btn--transparent.btn--inversed::before {
	background-color: var(--global--color-secondary);
}

.btn--transparent.btn--inversed:active,
.btn--transparent.btn--inversed:focus,
.btn--transparent.btn--inversed:hover {
	color       : var(--global--color-white);
	border-color: var(--global--color-secondary);
}

/* Buttons Shadow */
.btn-shadow {
	-webkit-box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
	box-shadow        : 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
}

.btn-shadow-hover:hover,
.btn-shadow-hover:active,
.btn-shadow-hover:focus {
	-webkit-box-shadow: 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
	box-shadow        : 0px 9px 30px 0px rgba(40, 40, 40, 0.15);
}

/* Button Bordered */
.btn--bordered {
	border          : 1px solid var(--global--color-primary);
	background-color: transparent;
}

.btn--bordered.btn--primary {
	color       : var(--global--color-primary);
	border-color: var(--global--color-primary);
}

.btn--bordered.btn--primary::before {
	background-color: var(--global--color-primary);
}

.btn--bordered.btn--primary:active,
.btn--bordered.btn--primary:focus,
.btn--bordered.btn--primary:hover {
	color       : var(--global--color-white);
	border-color: var(--global--color-primary);
}

.btn--bordered.btn--secondary {
	color       : var(--global--color-secondary);
	border-color: var(--global--color-secondary);
}

.btn--bordered.btn--secondary::before {
	background-color: var(--global--color-secondary);
}

.btn--bordered.btn--secondary:active,
.btn--bordered.btn--secondary:focus,
.btn--bordered.btn--secondary:hover {
	color       : var(--global--color-white);
	border-color: var(--global--color-secondary);
}

.btn--bordered.btn--white {
	color       : var(--global--color-white);
	border-color: var(--global--color-white);
}

.btn--bordered.btn--white::before {
	background-color: var(--global--color-white);
}

.btn--bordered.btn--white i {
	color: var(--global--color-white);
}

.btn--bordered.btn--white:active,
.btn--bordered.btn--white:focus,
.btn--bordered.btn--white:hover {
	color       : var(--global--color-primary);
	border-color: var(--global--color-white);
}

.btn--bordered.btn--white:active i,
.btn--bordered.btn--white:focus i,
.btn--bordered.btn--white:hover i {
	color: var(--global--color-primary);
}

.btn-video {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 170px;
	height            : 96px;
	padding           : 0 30px;
	border-radius     : 32px 32px 0 32px;
	background-color  : #435ba1;
	color             : var(--global--color-white);
	-webkit-transition: 300ms ease-in-out;
	-o-transition     : 300ms ease-in-out;
	transition        : 300ms ease-in-out;
	cursor            : pointer;
	position          : relative;
}

.btn-video i {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-ms-flex-negative : 0;
	flex-shrink       : 0;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 54px;
	height            : 36px;
	background-color  : var(--global--color-white);
	z-index           : 5;
	color             : var(--global--color-heading);
	font-size         : 16px;
	-webkit-box-shadow: 2.121px 2.121px 6px 0px rgba(3, 21, 50, 0.05);
	box-shadow        : 2.121px 2.121px 6px 0px rgba(3, 21, 50, 0.05);
	border-radius     : 8px;
	-webkit-transition: 300ms ease-in-out;
	-o-transition     : 300ms ease-in-out;
	transition        : 300ms ease-in-out;
	margin-right      : 15px;
}

.btn-video span {
	font-family   : var(--global--font-body);
	font-weight   : 700;
	font-size     : 15px;
	line-height   : 21px;
	text-transform: capitalize;
	color         : var(--global--color-white);
}

.btn-video.btn-video-2 {
	border-radius   : 0;
	background-color: transparent;
	padding         : 0;
	width           : auto;
	height          : auto;
}

.btn-video.btn-video-2 i {
	position        : relative;
	display         : -webkit-inline-box;
	display         : -ms-inline-flexbox;
	display         : inline-flex;
	width           : 88px;
	height          : 88px;
	padding         : 0;
	border-radius   : 50%;
	background-color: var(--global--color-white);
	margin-right    : 0;
	color           : var(--global--color-heading);
}

.btn-video.btn-video-2 i::after {
	content          : '';
	height           : 100%;
	width            : 100%;
	position         : absolute;
	top              : 0;
	left             : 0;
	right            : 0;
	border           : 2px solid rgba(255, 255, 255, 0.5);
	border-radius    : 100%;
	-webkit-transform: scale(1);
	-ms-transform    : scale(1);
	transform        : scale(1);
	opacity          : 1;
	-webkit-animation: pulse 0.8s ease-out 0s infinite;
	animation        : pulse 0.8s ease-out 0s infinite;
	z-index          : -1;
}

.btn-video.btn-video-2:hover i {
	background-color: var(--global--color-primary);
	color           : var(--global--color-white);
}