@use '../utils' as *;

.clients {
	padding-top   : 0;
	padding-bottom: 0;
	border-top    : 1px solid #eaeaea;
	overflow      : visible;
}

.clients.bg-grey {
	color: var(--global--color-gray);
}

.clients .client {
	padding-top       : 60px;
	padding-bottom    : 60px;
	cursor            : pointer;
	-webkit-transition: 0.3s ease;
	-o-transition     : 0.3s ease;
	transition        : 0.3s ease;
	width             : 150px;
	margin-right      : auto;
	margin-left       : auto;
	position          : relative;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.clients .client {
		width: 130px;
	}
}

.clients .client:hover::before {
	width: 100%;
}

.clients .client::before {
	content           : '';
	position          : absolute;
	top               : -1px;
	left              : 50%;
	-webkit-transform : translateX(-50%);
	-ms-transform     : translateX(-50%);
	transform         : translateX(-50%);
	width             : 0%;
	height            : 1px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	background-color  : var(--global--color-primary);
}

.clients .client a {
	position: absolute;
	width   : 100%;
	height  : 100%;
	top     : 0;
	left    : 0;
	right   : 0;
	z-index : 2;
}

.clients .client img {
	max-width   : 100%;
	width       : auto;
	display     : block;
	margin-right: auto;
	margin-left : auto;
}

.certificate img {
	display: block;
	margin : 0 auto;
}