@use '../utils' as *;
/*--
    - Page title
-----------------------------------------*/

/*----------------------------------------*/
/*  21. PAGE TITLE CSS START
/*----------------------------------------*/
.page{
    &__title{
        font-size: 70px;
        color: var(--tp-common-white);
        line-height: 62px;
        margin-bottom: 0;

        @media #{$xs,$sm}{
            font-size: 45px;
        }
        &-wrapper{
            position: relative;
            z-index: 1;
            text-align: center;
            .breadcrumb-menu {
                & ul {
                    & li {
                        display: inline-block;
                        padding: 0 10px;
                        position: relative;
                        &::before{
                            content: "";
                            background: rgba(255,255,255,0.50);
                            height: 15px;
                            width: 2px;
                            position: absolute;
                            left: -3px;
                            top: 5px;
                        }
                        &:first-child {
                            &::before{
                                display: none;
                            }
                        }
                        & a {
                            color: rgba(255,255,255,0.50);
                            text-transform: capitalize;
                            font-weight: 600;
                            font-size: 16px;
                            &:hover{
                                color: var(--tp-theme-1);
                            }
                        }
                        & span{
                            color: var(--tp-common-white);
                            text-transform: capitalize;
                            font-weight: 600;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &-height{
            min-height: 600px;
            @include background();
            @media #{$sm} {
                min-height: 500px;
            }
            @media #{$xs} {
                min-height: 450px;
            }
        }
        &-overlay{
            position: relative;
            &::after{
                background-image: -moz-linear-gradient( 90deg, rgb(52,58,64) 0%, rgba(0,0,0,0) 100%);
                background-image: -webkit-linear-gradient( 90deg, rgb(52,58,64) 0%, rgba(0,0,0,0) 100%);
                background-image: -ms-linear-gradient( 90deg, rgb(52,58,64) 0%, rgba(0,0,0,0) 100%);
                background-image: linear-gradient( 90deg, rgba(0,0,0,.2) 0%, rgba(0,0,0,0) 100%);
                width: 100%;
                height: 100%;
                content: '';
                top: 0;
                position: absolute;
            }
            &::before{
                background-image: -moz-linear-gradient( 90deg, rgba(0,0,0,0) 0%, rgb(52,58,64) 100%);
                background-image: -webkit-linear-gradient( 90deg, rgba(0,0,0,0) 0%, rgb(52,58,64) 100%);
                background-image: -ms-linear-gradient( 90deg, rgba(0,0,0,0) 0%, rgb(52,58,64) 100%);
                background-image: linear-gradient( 90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.2) 100%);
                width: 100%;
                height: 100%;
                content: '';
                bottom: 0;
                position: absolute;
            }
        }
    }
}

