@use '../utils' as *;

.post{
	position: relative;
}

.post.vertical-item{
	overflow: hidden;
}

.post.vertical-item .item-media img{
	width: 100%;
}

.post.vertical-item .btn{
	margin-top: 15px;
	margin-bottom: 0;
}

@media (max-width: 575px) {
	.post.vertical-item .btn{
		margin-top: 10px;
	}
}

.post.vertical-item.content-padding .item-content{
	padding: 60px 54px;
}

@media (max-width: 1200px)and (min-width: 992px) {
	.post.vertical-item.content-padding .item-content{
		padding: 40px;
	}
}

@media (max-width: 575px) {
	.post.vertical-item.content-padding .item-content{
		padding: 40px 20px;
		text-align: center;
	}
}

.post.vertical-item .item-content{
	padding: 40px 54px;
}

.post.vertical-item .item-content form label{
	font-weight: 700;
}

.post.vertical-item .item-content form input[type=submit]{
	-webkit-transform: none;
	        transform: none;
	margin-left: 10px;
	position: static;
}

.post.vertical-item .item-content .page-links{
	margin-top: 20px;
}

@media (max-width: 1200px)and (min-width: 992px) {
	.post.vertical-item .item-content{
		padding: 40px;
	}
}

@media (max-width: 575px) {
	.post.vertical-item .item-content{
		padding: 40px 20px;
		text-align: center;
	}
}

.post.vertical-item .item-content a:not(.btn):hover{
	color: var(--colorMain);
}

.post.vertical-item .item-content h1.entry-title{
	margin-top: 5px;
}

.post.vertical-item .entry-content form label[for=pwbox-1168]{
	font-size: 0;
}

.post.vertical-item .entry-content form input[type=submit]{
	-webkit-transform: none;
	        transform: none;
	margin-left: 10px;
	position: static;
	margin-top: 0;
}

.post > .fa{
	position: absolute;
	right: 9px;
	top: 7px;
	color: #fff;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	z-index: 2;
}

.post > .fa:before{
	position: relative;
	z-index: 3;
}

.post > .fa:after{
	content: "";
	display: block;
	position: absolute;
	z-index: 2;
	top: -14px;
	left: -35px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 40px 40px 40px;
	border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) var(--colorMain) rgba(0, 0, 0, 0);
}

.post + .post{
	margin-top: 60px;
}

.post h3.entry-title + .entry-meta{
	margin-top: -0.65em;
}

.post h1.entry-title + .entry-meta{
	margin: -1em 0 2em;
}

.post.cover-image{
	padding: 60px 54px;
	overflow: hidden;
	height: auto;
}

@media (max-width: 1200px)and (min-width: 992px) {
	.post.cover-image{
		padding: 40px;
	}
}

@media (max-width: 575px) {
	.post.cover-image{
		padding: 40px 20px;
	}
}

.post.cover-image .post-thumbnail{
	display: none;
}

.post-thumbnail{
	text-align: center;
}

.post-thumbnail .owl-dots{
	position: absolute;
	bottom: 30px;
	right: 0;
	left: 0;
}

.post-thumbnail + .entry-content{
	margin-top: 1.5em;
}

.post-password-form input[type=password]{
	margin-top: .5em;
	width: auto;
}

.post-password-form input[type=submit]{
	position: relative;
	top: -2px;
}

.entry-meta{
	margin-bottom: 1.8em;
	line-height: 1;
	clear: both;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
}

@media (min-width: 992px)and (max-width: 1199px) {
	.entry-meta{
		flex-direction: column;
	}
}

@media (max-width: 768px) {
	.entry-meta{
		flex-direction: column;
	}
}

.entry-meta i{
	font-size: 16px;
	margin-right: 12px;
	color: var(--colorMain);
}

.entry-meta .veaws{
	color: var(--colorMain2);
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	margin-left: 48px;
}

@media (max-width: 575px) {
	.entry-meta .veaws{
		margin-left: 30px;
	}
}

.entry-meta .share{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 500;
	color: var(--colorDark);
}

@media (max-width: 575px) {
	.entry-meta .share{
		margin-top: 20px;
	}
}

.entry-meta .share a{
	margin-right: 20px;
	font-size: 14px;
}

@media (max-width: 575px) {
	.entry-meta .share a{
		margin-right: 15px;
	}
}

@media (max-width: 768px) {
	.entry-meta{
		margin-bottom: 1em;
	}
}

.entry-meta .byline{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.entry-meta .byline + .cat-links{
	margin-left: auto;
	max-width: 61%;
}

@media (min-width: 992px)and (max-width: 1199px) {
	.entry-meta .byline + .cat-links{
		margin-top: 10px;
		margin-left: 0;
		width: 100%;
	}
}

@media (max-width: 768px) {
	.entry-meta .byline + .cat-links{
		margin-top: 10px;
		margin-left: 0;
		width: 100%;
	}
}

@media (max-width: 575px) {
	.entry-meta .byline{
		justify-content: center;
	}
}

@media (max-width: 400px) {
	.entry-meta .byline span + span{
		margin-right: 0;
		margin-top: 10px;
	}
}

.entry-meta .byline span + span:before{
	content: "|";
	position: relative;
	left: 0;
	margin-right: 7px;
}

@media (max-width: 768px) {
	.entry-meta .byline span + span:before{
		display: none;
	}
}

.entry-meta .byline > span{
	margin-right: 7px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.entry-meta .byline > span.date a{
	color: var(--colorMain);
}

.entry-meta .byline > span.date a:hover{
	color: var(--colorFont);
}

@media (max-width: 400px) {
	.entry-meta .byline > span{
		margin-right: 0;
	}
}

@media (max-width: 400px) {
	.entry-meta .byline{
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
	}
}

.entry-meta .byline .author.vcard i{
	display: none;
}

@media (max-width: 575px) {
	.entry-meta .byline .author.vcard i{
		display: block;
	}
}

@media (min-width: 992px)and (max-width: 1199px) {
	.entry-meta .byline .author.vcard{
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

@media (max-width: 575px) {
	.entry-meta .byline .author.vcard{
		margin-bottom: 0;
		display: flex;
		margin-right: 0;
		align-items: center;
		justify-content: center;
	}
}

.entry-meta .byline .author.vcard img{
	width: 40px;
	margin-right: 10px;
	border-radius: 50%;
}

@media (min-width: 992px)and (max-width: 1199px) {
	.entry-meta .byline .author.vcard img{
		margin-right: 0;
	}
}

@media (max-width: 575px) {
	.entry-meta .byline .author.vcard img{
		display: none;
	}
}

.entry-meta .byline .entry-meta-divider{
	display: none;
}

@media (max-width: 575px) {
	.entry-meta .byline .entry-meta-divider{
		display: none;
	}
}

.entry-meta a{
	font-size: 12px;
	font-weight: 500;
	text-transform: capitalize;
	letter-spacing: 0;
	color: var(--colorFont);
}

.entry-footer{
	margin-top: 22px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
}

@media (max-width: 575px) {
	.entry-footer{
		margin-top: 20px;
	}
}

.entry-footer .after-meta{
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 20px;
}

.entry-footer .after-meta .cat-links + .comments-link{
	margin-left: auto;
}

@media (max-width: 575px) {
	.entry-footer .after-meta .cat-links + .comments-link{
		margin-left: 0;
	}
}

.entry-footer .after-meta .cat-links{
	max-width: 61%;
	margin-top: 0;
	margin-left: 0;
}

@media (max-width: 575px) {
	.entry-footer .after-meta .cat-links{
		max-width: 100%;
	}
}

@media (max-width: 575px) {
	.entry-footer .after-meta{
		flex-direction: column;
		justify-content: center;
	}
}

.entry-footer .after-meta i{
	font-size: 14px;
	color: var(--colorMain);
	margin-right: 8px;
}

.entry-footer .after-meta a{
	font-size: 16px;
	font-weight: 300;
	text-transform: capitalize;
	letter-spacing: 0;
}

@media (max-width: 768px) {
	.entry-footer{
		flex-direction: column;
	}
}

.entry-footer .post-likes{
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
	color: var(--colorDark);
}

@media (max-width: 768px) {
	.entry-footer .post-likes{
		margin-top: 15px;
	}
}

.entry-footer .post-likes i{
	color: var(--colorMain);
	font-size: 16px;
	margin-right: 8px;
}

.entry-footer .fa{
	margin-right: 10px;
}

.entry-content:before,
.entry-content:after,
.entry-header:before,
.entry-header:after{
	display: block;
	content: "";
	clear: both;
}

.item-meta{
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0;
	line-height: 1.6;
}

.item-meta i{
	width: 1em;
	text-align: center;
	position: relative;
	font-size: 1.2em;
}

.item-meta span{
	padding: 0 0 0 1.7em;
}

.item-media{
	position: relative;
	overflow: hidden;
}

.item-media img{
	width: 100%;
}

.item-media.cover-image{
	height: 100%;
}

.media-links{
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.media-links div{
	position: absolute;
	top: 50%;
	width: 100%;
	margin-top: -30px;
	z-index: 4;
}

.content-absolute .media-links div{
	display: flex;
	width: 100%;
	height: 100%;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	margin-top: 0;
	justify-content: center;
}

.content-absolute .media-links div a{
	transition: all .35s ease .1s;
}

.content-absolute .media-links div a:first-child{
	transition-delay: 0s;
}

.media-links:before{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, .5);
	opacity: 0;
	transition: all .2s linear;
}

[class*=-item]:hover .media-links:before{
	opacity: .5;
}

.media-links a.abs-link{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
}

[class*=-item]:hover .media-links a.abs-link{
	opacity: 1;
}

[class*=-item] .links-wrap a{
	position: relative;
	width: 60px;
	height: 60px;
	line-height: 3;
	top: -100px;
	border: 2px solid rgba(0, 0, 0, 0);
	display: inline-block;
	z-index: 5;
	font-size: 20px;
	text-align: center;
	opacity: 0;
	transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

[class*=-item] .links-wrap a.link-zoom{
	color: #fff;
	background-color: var(--colorDarkGrey);
}

[class*=-item] .links-wrap a.link-zoom:hover{
	opacity: 1;
	background: #fff;
	color: var(--colorMain2);
}

[class*=-item] .links-wrap a.link-anchor{
	color: var(--colorDarkGrey);
	background-color: #fff;
	border-color: #fff;
	transition-delay: .1s;
}

[class*=-item] .links-wrap a.link-anchor:hover{
	opacity: 1;
	color: #fff;
	background-color: var(--colorMain);
	border-color: var(--colorMain);
}

[class*=-item] .links-wrap a:before{
	font-family: "FontAwesome";
	content: "ï€‚";
	font-weight: 900;
}

[class*=-item] .links-wrap a.link-anchor:before{
	content: "ï";
}

[class*=-item]:hover .links-wrap a{
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	filter: alpha(opacity=100);
	opacity: 1;
}

[class*=-item]:hover .media-links div{
	filter: alpha(opacity=100);
	opacity: 1;
}

.item-icons{
	margin-top: 35px;
	font-size: 12px;
}

.content-padding .item-icons{
	margin-top: 0;
}

.item-icons i{
	padding: 0 4px;
}

.item-icons .col{
	text-align: center;
	padding-top: 15px;
	padding-bottom: 20px;
}

.shortcode-posts.tiled-layout > .vertical-item{
	width: 100%;
	margin-left: 15px;
	margin-right: 15px;
}

@media (min-width: 1200px) {
	.shortcode-posts.tiled-layout .vertical-item{
		height: 100%;
	}
}

.shortcode-posts.tiled-layout .vertical-item.post + .post{
	margin-top: 30px;
}

.shortcode-posts.tiled-layout .vertical-item .item-content .entry-title{
	font-size: 20px;
	line-height: 1.4;
}

.shortcode-posts.tiled-layout .vertical-item.side-item{
	height: auto;
}

@media (min-width: 991px) {
	.shortcode-posts.tiled-layout .vertical-item.side-item{
		display: flex;
		height: auto;
	}

	.shortcode-posts.tiled-layout .vertical-item.side-item .item-media{
		flex-shrink: 0;
		flex-basis: 30%;
		width: 30%;
	}

	.shortcode-posts.tiled-layout .vertical-item.side-item .item-media img{
		height: 100%;
		-o-object-fit: cover;
		   object-fit: cover;
		width: 100%;
	}

	.shortcode-posts.tiled-layout .vertical-item.side-item .item-content{
		padding: 34px;
	}

	.shortcode-posts.tiled-layout .vertical-item.side-item .item-content .entry-title{
		font-size: 20px;
		line-height: 1.4;
	}
}

@media (min-width: 1200px) {
	.shortcode-posts.tiled-layout .vertical-item.side-item .item-content{
		padding: 10px 35px;
	}
}

@media (max-width: 991px)and (min-width: 768px) {
	.shortcode-posts.tiled-layout .smallitem{
		display: flex;
		justify-content: space-between;
	}

	.shortcode-posts.tiled-layout .smallitem .vertical-item{
		max-width: 47.5%;
	}

	.shortcode-posts.tiled-layout .smallitem .post + .post{
		margin-top: 0;
	}
}


.blog-entry {
	position          : relative;
	-webkit-box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.08);
	box-shadow        : 0px 5px 83px 0px rgba(40, 40, 40, 0.08);
	border-radius     : 8px;
	background-color  : var(--global--color-white);
	-webkit-transition: 0.5s ease-in-out;
	-o-transition     : 0.5s ease-in-out;
	transition        : 0.5s ease-in-out;
	margin-bottom     : 36px;
}

@media screen and (max-width: 991.98px) {
	.blog-entry {
		max-width: 370px;
		margin   : 0 auto 30px;
	}
}

.blog-entry::before {
	content           : '';
	position          : absolute;
	top               : 0;
	left              : 0;
	height            : 10px;
	width             : calc(100% - 40px);
	background-color  : #eaeaea;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	border-radius     : 8px 0 0 0;
}

@media screen and (max-width: 767.98px) {
	.blog-entry::before {
		width: calc(100% - 20px);
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.blog-entry::before {
		width: calc(100% - 25px);
	}
}

.blog-entry::after {
	content                 : '';
	position                : absolute;
	top                     : 0;
	left                    : 0;
	height                  : 10px;
	width                   : calc(100% - 40px);
	background-color        : var(--global--color-primary);
	-webkit-transition      : 0.3s ease-in-out;
	-o-transition           : 0.3s ease-in-out;
	transition              : 0.3s ease-in-out;
	border-radius           : 8px 0 0 0;
	-webkit-transform       : scaleX(0);
	-ms-transform           : scaleX(0);
	transform               : scaleX(0);
	-webkit-transform-origin: left;
	-ms-transform-origin    : left;
	transform-origin        : left;
}

@media screen and (max-width: 767.98px) {
	.blog-entry::after {
		width: calc(100% - 20px);
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.blog-entry::after {
		width: calc(100% - 25px);
	}
}

.blog-entry:hover {
	-webkit-box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
}

.blog-entry:hover::after {
	-webkit-transform: scaleX(1);
	-ms-transform    : scaleX(1);
	transform        : scaleX(1);
}

.blog-entry:hover .entry-content .entry-img-wrap .entry-img img {
	-webkit-transform: scale3d(1.05, 1.05, 1);
	transform        : scale3d(1.05, 1.05, 1);
}

.blog-entry:hover .entry-content .entry-more .btn {
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	color             : var(--global--color-white);
	background-color  : var(--global--color-heading);
	border-color      : var(--global--color-heading);
}

.blog-entry:hover .entry-content .entry-more .btn i {
	background-color: var(--global--color-white);
	color           : var(--global--color-heading);
}

.blog-entry .entry-content {
	padding: 36px 40px 40px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.blog-entry .entry-content {
		padding-left : 25px;
		padding-right: 25px;
	}
}

@media screen and (max-width: 767.98px) {
	.blog-entry .entry-content {
		padding-left : 20px;
		padding-right: 20px;
	}
}

.blog-entry .entry-content .entry-img-wrap {
	-webkit-transform: translateX(-40px);
	-ms-transform    : translateX(-40px);
	transform        : translateX(-40px);
	width            : calc(100% + 80px);
	position         : relative;
	margin-bottom    : 32px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.blog-entry .entry-content .entry-img-wrap {
		-webkit-transform: translateX(-25px);
		-ms-transform    : translateX(-25px);
		transform        : translateX(-25px);
		width            : calc(100% + 50px);
	}
}

@media screen and (max-width: 767.98px) {
	.blog-entry .entry-content .entry-img-wrap {
		-webkit-transform: translateX(-20px);
		-ms-transform    : translateX(-20px);
		transform        : translateX(-20px);
		width            : calc(100% + 40px);
	}
}

.blog-entry .entry-content .entry-img-wrap .entry-img {
	position     : relative;
	border-radius: 8px 8px;
	overflow     : hidden;
	max-width    : 100%;
}

.blog-entry .entry-content .entry-img-wrap .entry-img img {
	-webkit-transition: -webkit-transform 0.35s;
	transition        : -webkit-transform 0.35s;
	-o-transition     : transform 0.35s;
	transition        : transform 0.35s;
	transition        : transform 0.35s, -webkit-transform 0.35s;
	width         : 100%;
	height            : auto;
	-webkit-transform : scale3d(1, 1, 1);
	transform         : scale3d(1, 1, 1);
}

.blog-entry .entry-content .entry-img-wrap .entry-category {
	position         : absolute;
	left             : 40px;
	bottom           : -15px;
	display          : -webkit-inline-box;
	display          : -ms-inline-flexbox;
	display          : inline-flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	margin-bottom    : 0px;
	text-transform   : capitalize;
	height           : 30px;
	padding          : 0 10px;
	background-color : #4aab3d;
	border-radius    : 6px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.blog-entry .entry-content .entry-img-wrap .entry-category {
		left: 25px;
	}
}

@media screen and (max-width: 767.98px) {
	.blog-entry .entry-content .entry-img-wrap .entry-category {
		left: 20px;
	}
}

.blog-entry .entry-content .entry-img-wrap .entry-category a {
	position          : relative;
	color             : var(--global--color-white);
	font-family       : var(--global--font-body);
	font-size         : 13px;
	font-weight       : 400;
	line-height       : 26px;
	text-transform    : capitalize;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.blog-entry .entry-content .entry-img-wrap .entry-category a::after {
	content     : ", ";
	position    : relative;
	margin-right: 4px;
}

.blog-entry .entry-content .entry-img-wrap .entry-category a:last-of-type::after {
	content: "";
}

.blog-entry .entry-content .entry-img-wrap .entry-category a:hover {
	color: var(--global--color-heading);
}

.blog-entry .entry-content .entry-meta {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	margin-bottom    : 6px;
}

.blog-entry .entry-content .entry-meta .entry-date {
	margin-right: 20px;
}

.blog-entry .entry-content .entry-meta .entry-date .day,
.blog-entry .entry-content .entry-meta .entry-date .year {
	font-family   : var(--global--font-body);
	font-weight   : 400;
	font-size     : 13px;
	line-height   : 16px;
	color         : #616161;
	text-transform: capitalize;
}

.blog-entry .entry-content .entry-meta .entry-date .day:after {
	content     : ',';
	margin-right: 3px;
}

.blog-entry .entry-content .entry-meta .entry-author {
	text-transform: capitalize;
}

.blog-entry .entry-content .entry-meta .entry-author p {
	font-family  : var(--global--font-body);
	font-weight  : 400;
	font-size    : 13px;
	line-height  : 16px;
	color        : var(--global--color-heading);
	margin-bottom: 0;
}

.blog-entry .entry-content .entry-title {
	margin-bottom: 32px;
}

.blog-entry .entry-content .entry-title h4 a {
	font-family       : var(--global--font-heading);
	font-size         : 18px;
	font-weight       : 600;
	line-height       : 28px;
	margin-bottom     : 0;
	text-transform    : capitalize;
    display: inline-block;
    min-height: 56px;
	color             : var(--global--color-heading);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.blog-entry .entry-content .entry-title h4 a:hover {
	color: var(--global--color-primary);
}

@media (min-width: 992px) and (max-width: 1200px) {
	.blog-entry .entry-content .entry-title h4 a {
		font-size: 18px;
	}
}

.blog-entry .entry-content .entry-bio p {
	font-family  : var(--global--font-body);
	font-size    : 15px;
	font-weight  : 400;
	line-height  : 25px;
	margin-bottom: 22px;
}

@media screen and (max-width: 767.98px) {
	.blog-entry .entry-content .entry-more {
		text-align: center;
	}
}

.blog-entry .entry-content .entry-more a {
	width             : 142px;
	height            : 45px;
	padding           : 0 20px;
	border            : 1px solid rgba(37, 55, 69, 0.5);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.blog-entry .entry-content .entry-more a::before {
	content: unset;
}

.blog-entry .entry-content .entry-more a:hover i {
	-webkit-transform: translateX(10px);
	-ms-transform    : translateX(10px);
	transform        : translateX(10px);
}

.blog-entry .entry-content .entry-more a i {
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 20px;
	height            : 20px;
	border-radius     : 50%;
	background-color  : var(--global--color-heading);
	color             : var(--global--color-white);
	font-size         : 12px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}
.blog__content{
	& p{
		margin-bottom: 15px;
	}
}

/* sidebar area */
.blog-sidebar{
    &__wrapper{
        @media #{$md,$sm,$xs} {
            padding-left: 0;
        }
    }
    &__widget{
        padding: 38px 40px;
        border-radius: 6px;
        border: 1px solid #f0f0f5;
        @media #{$lg,$md,$sm,$xs} {
            padding: 20px 20px;
        }
        &-title{
            font-size: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #f0f0f5;
            position: relative;
            &::before{
                position: absolute;
                content: '';
                background: var(--tp-theme-1);
                height: 1px;
                width: 30px;
                bottom: -1px;
                left: 0;
                right: 0;
            }
        }
        & ul{
            & li{
                list-style: none;
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                & a{
                    font-size: 16px;
                    font-weight: 500;
                    padding-left: 20px;
                    position: relative;
                    color: var(--tp-heading-primary);
                    &::after{
                        position: absolute;
                        content: "\f054";
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: "Font Awesome 5 Pro";
                        font-size: 14px;
                        color: #001d23;
                        -webkit-transition: all 0.3s ease-out 0s;
                        -moz-transition: all 0.3s ease-out 0s;
                        -ms-transition: all 0.3s ease-out 0s;
                        -o-transition: all 0.3s ease-out 0s;
                        transition: all 0.3s ease-out 0s;
                    }
                    &:hover{
                        color: var(--tp-theme-1);
                        &::after{
                            color: var(--tp-theme-1);
                        }
                    }
                }
                a:hover::after {
                    left: 7px;
                }
                & ul{
                    padding-top: 10px;
                    padding-left: 15px;
                }
            }
        }
        & .featured{
            &__author{
                &-title{
                    font-size: 18px;
                    line-height: 1.3;
                }
                & p{
                    font-size: 16px;
                }
                &-social{
                    & a{
                        display: inline-block;
                        margin-right: 15px;
                        margin-bottom: 10px;
                        font-size: 16px;
                        &:last-child{
                            margin-right: 0;
                        }
                        &:hover{
                            color: var(--tp-theme-1);
                        }
                    }
                }
            }
        }
    }
    &__search{
        & input{
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: var(--tp-common-white);
            border: 1px solid transparent;
            @include border-radius(6px);
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
            padding-left: 20px;
            padding-right: 65px;
            
            &::placeholder{
                color: #aeaeae;
                font-weight: 500;
            }
            &:focus{
                border-color: var(--tp-theme-1);
                background-color: var(--tp-common-white);
            }
        }
        & button{
            position: absolute;
            top: 50%;
            right: 0;
            left: auto;
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: var(--tp-theme-1);
            color: var(--tp-common-white);
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            @include transform(translateY(-50%));
            & i{

            }
        }
    }
    &__banner{
        & img{
            border-radius: 6px;
            width: 100%;
        }
    }
}