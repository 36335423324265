@use '../utils' as *;

/*----------------------------------------*/
/*  03. Team CSS START
/*----------------------------------------*/

.team{
    &__slider{}
    &__item{
        position: relative;
        &:hover{
            & .team__image::before,
            & .team__content,
            & .team__social{
                visibility: visible;
                opacity: 1;
            }
            & .team__social{
                top: 40%;
            }
            & .team__content{
                bottom: 25px;
            }
        }
        &-box{
            & .member__info{
                background: var(--tp-heading-primary);
                padding: 30px;
                position: relative;
                transition: .3s;
                & span{
                    color: #a2abb4;
                    font-size: 14px;
                    display: inline-block;
                }
                & .m-name{
                    font-size: 20px;
                    color: var(--tp-common-white);
                    margin-bottom: 0;
                    transition: .3s;
                    &:hover{
                        color: var(--tp-theme-1);
                    }
                }
                &::before{
                    position: absolute;
                    content: "";
                    width: 30px;
                    height: 30px;
                    background: #e7e7e7;
                    clip-path: polygon(0 0, 0 100%, 100% 0);
                    bottom: -30px;
                    right: 0;
                    transition: .5s;
                }
            }
            &:hover{
                & .member__info{background: var(--tp-theme-1);}
                & .member__info .m-name,span{ color: var(--tp-common-white);}
                & .member__info::before{
                    background: #ffc3c3;
                }
            }
        }
        &-grid{
            position: relative;
            &::after{
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #474c52db;
                border-radius: 6px;
                opacity: 0;
                transition: .3s; 
            }
            &:hover::after{
                opacity: 1;
            }
            &:hover .team__social-3{
                visibility: visible;
                opacity: 1;
                bottom: 0;
            }
            &:hover .team__info{
                visibility: visible;
                opacity: 1;
                top: 50%;
            }
        }
    }
    &__image{
        position: relative;
        &::before{
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            background: var(--tp-heading-primary);
            width: 100%;
            height: 340px;
            -webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 0 0, 100% 100%, 0% 100%);
            transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
            visibility: hidden;
            opacity: 0;
        }
    }
    &__content{
        position: absolute;
        bottom: 0;
        left: 40px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        & span{
            font-size: 14px;
            color: var(--tp-grey-7);
            display: inline-block;
        }
        & h5{
            font-size: 24px;
            color: var(--tp-common-white);
            &:hover{
                color: var(--tp-theme-1);
            }
        }
    }
    &__social{
        position: absolute;
        top: 50%;
        left: 40px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        & a{
            height: 50px;
            width: 50px;
            line-height: 50px;
            color: var(--tp-heading-primary);
            background: var(--tp-common-white);
            border-radius: 6px;
            margin-right: 10px;
            margin-bottom: 10px;
            display: inline-block;
            text-align: center;
            transition: .3s;
            &:hover{
                background: var(--tp-theme-1);
                color: var(--tp-common-white);
            }
        }
        &-2{
            padding: 20px 30px;
            & a{
                font-size: 15px;
                margin-right: 20px;
                margin-bottom: 10px;
                &:hover{
                    color: var(--tp-theme-1);
                }
            }
        }
        &-3{
            position: absolute;
            z-index: 1;
            transition: all 0.3s ease-out 0s;
            margin: 0 40px;
            left: 0;
            right: 0;
            bottom: -30px;
            background: var(--tp-common-white);
            padding: 20px 30px;
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            visibility: hidden;
            opacity: 0;
            & a{
                font-size: 15px;
                margin-bottom: 10px;
                margin-right: 15px;
                transition: .3s;
                &:last-child{
                    margin-right: 0;
                }
                &:hover {
                    color: var(--tp-theme-1);
                }
            }
        }
    }
    &__info{
        position: absolute;
        top: 30%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        z-index: 1;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        visibility: hidden;
        opacity: 0;
        & span{
            font-size: 14px;
            font-weight: var(--tp-fw-sbold);
            color: #c5c5c5;
            text-transform: uppercase;
        }
        & h5{
            font-size: 24px;
            color: var(--tp-common-white);
            &:hover{
                color: var(--tp-theme-1);
            }
        }
    }
    &__details{
        &-image{
            @media #{$md} {
                margin-right: 0;
            }
            margin-right: 30px;
            position: relative;
            & img{
                width: 100%;
                border-radius: 6px;
            }
        }
        &-dtitle{
            font-size: 60px;
            line-height: 1;
        }
        &-degination{
            font-size: 30px;
            line-height: 1;
            color: var(--tp-theme-1);
            display: block;
        }
        &-contact{
            &-icon{
                & a{
                    height: 50px;
                    width: 50px;
                    border: 1px solid var(--tp-border-2);
                    border-radius: 6px;
                    display: inline-block;
                    text-align: center;
                    line-height: 50px;
                    font-size: 20px;
                    color: var(--tp-theme-1);
                    transition: .3s;
                }
            }
            &-text{
                & span{
                    font-size: 14px;
                }
                & h6{
                    font-size: 20px;
                }
            }
            &-info ul li{
                margin-bottom: 30px;
                list-style: none;
            }
            &-wrapper{
                display: flex;
                &:hover > .team__details-contact-icon a{
                    background-color: var(--tp-theme-1);
                    color: var(--tp-common-white);
                    border-color: var(--tp-theme-1);
                }
            }
        }
        &-text{
            font-size: 16px;
            line-height: 25px;
            &-2{
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
    &__dtitle{
        font-size: 60px;
    }
    &__main-info{
        margin-left: 40px;
        @media #{$md,$sm,$xs}{
            margin-left: 0;
        }
    }
    &__area{
        position: relative;
        z-index: 0;
    }
}
.td-border{
    border-bottom: 1px solid var(--tp-border-2);
}

// qualification progress
.member-skill {
	& .progress {
		height: 10px;
		background-color: transparent;
		border-radius: 0;
        z-index: 1;
        position: relative;
        &::before{
            position: absolute;
            content: '';
            background: #ececec;
            top: 0;
            width: 100%;
            height: 3px;
            z-index: -1;
            top: 50%;
            width: 100%;
            height: 3px;
            transform: translateY(-50%);
        }
	}
	& .progress-bar {
		background-color: var(--tp-theme-1);
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 70%; 
        visibility: visible; 
        animation-name: slideInLeft;
	}
    & .progress-bar-2{
        width: 52%;
    }
    & .progress-bar-3{
        width: 82%;
    }
}
.skill-wrapper {
	margin-bottom: 27px;
}
.skill-title {
	display: flex;
	justify-content: space-between;
	& span {
		float: right;
		font-size: 18px;
		font-weight: 700;
		color:  var(--tp-theme-1);
	}
}
.skill-category {
	font-size: 18px;
    line-height: 28px;
	display: inline-block;
	margin-bottom: 16px;
}
.qulification-title{
    font-size: 26px;
}
.certificate{
    & a{
        margin-right: 20px;
        margin-bottom: 20px;
        display: inline-block;
    }
}
.member-qualification{
    @media #{$md,$xs,$sm} {
        padding-right: 0;
    }
}

.team__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #31373f;
    pointer-events: none;
    z-index: -1;
}
.section--bg {
    position: absolute;
    z-index: 0;
}
.t50 {
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.r0 {
    right: 0;
}
.heading__pre-title {
    display: inline-block;
    color: #a9aeb3;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .15em;
    text-transform: uppercase;
    margin-bottom: 0px;
    z-index: 1;
    position: relative;
}
.heading__title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0;
    z-index: 1;
    position: relative;
}
@media only screen and (min-width: 992px){
.heading__title {
    font-size: 40px;
    line-height: 45px;
}
}
.heading__text {
    margin-top: 26px;
    z-index: 1;
    position: relative;
}
.heading__text:last-of-type {
    margin-bottom: 0;
}
.team-item {
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    -o-transition: transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s
}

.team-item:hover {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px)
}

.team-item__img {
    position: relative
}

.team-item__img::before {
    content: "";
    display: block;
}

.team-item__description {
    color: #fff;
    position: absolute;
    bottom: 0px;
    left: 0;
    background: var(--tp-theme-1);
    width: 285px;
    -webkit-clip-path: polygon(90% 0,100% 15%,100% 100%,0 100%,0 0);
    clip-path: polygon(90% 0,100% 15%,100% 100%,0 100%,0 0);
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    padding: 23px 30px 28px 30px;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    -o-transition: transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s
}

.team-item__name {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 20px;
    color: #fff;
}

.team-item__position {
    color: #427ccb;
    font-size: 14px;
    font-weight: 700
}

.team-item .socials {
    margin-top: 19px
}

.team-item .socials__link {
    color: #427ccb;
    font-size: 18px
}

.team-item .socials__link:hover {
    color: #fff
}

.team-item:hover .team-item__description {
    -webkit-transform: translateY(20);
    -ms-transform: translateY(20);
    transform: translateY(20)
}
.img--bg {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top center;
    object-position: top center;
    z-index: -1;
    img{
        max-width: 100%;
    }
}
.project-hover {
	position          : absolute;
	top               : 50%;
	left              : 50%;
	-webkit-transform : translate(-50%, -50%);
	-ms-transform     : translate(-50%, -50%);
	transform         : translate(-50%, -50%);
	width             : 80px;
	height            : 80px;
	border-radius     : 50%;
	z-index           : 2;
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	background        : var(--global--color-primary-70);
	opacity           : 0;
	visibility        : hidden;
	-webkit-transition: 0.4s ease-in-out;
	-o-transition     : 0.4s ease-in-out;
	transition        : 0.4s ease-in-out;
}

.project-hover .project-zoom {
	color    : var(--global--color-white);
	font-size: 20px;
}

.project-hover a {
	position: absolute;
	top     : 0;
	left    : 0;
	right   : 0;
	width   : 100%;
	height  : 100%;
	z-index : 2;
}
.team-item:hover .project-hover {
	opacity   : 1;
	visibility: visible;
}