@use '../utils' as *;

.testimonials {
    overflow: hidden;
    color: #fff
}

@media only screen and (min-width: 992px) {
    section.testimonials:not(.testimonials--style-2) {
        padding-bottom:0
    }
}

.testimonials__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #31373f;
    pointer-events: none
}

@media only screen and (min-width: 992px) {
    .testimonials__bg {
        height:calc(100% - 70px)
    }
}

.testimonials__nav {
    margin-top: 33px;
    margin-bottom: 23px
}

@media only screen and (min-width: 992px) {
    .testimonials__nav {
        margin-bottom:0
    }
}

.testimonials__img {
    display: none;
    margin-top: 30px
}

@media only screen and (min-width: 992px) {
    .testimonials__img {
        display:initial;
        position: relative;
    }
}

.testimonials-slider__item {
    position: relative
}

.testimonials-slider p:last-of-type {
    margin-bottom: 0
}

.testimonials-slider__author {
    position: relative;
    padding-left: 80px;
    margin-top: 42px;
    padding-bottom: 30px;
}

.testimonials-slider__author h6 {
    margin-bottom: 0;
    line-height: 2;
}

.testimonials-slider__author .img {
    position: absolute;
    top: 0;
    left: 0
}

.testimonials--style-2 {
    color: inherit
}

.testimonials--style-2 .quote-icon {
    display: none;
    position: absolute;
    top: -28px;
    left: -17px;
    z-index: 0
}

@media only screen and (min-width: 992px) {
    .testimonials--style-2 .quote-icon {
        display:initial
    }
}

.testimonials--style-2 .testimonials-slider__author {
    padding-left: 0
}

.testimonials-slider--white {
    color: #fff
}

.testimonials-slider--white+.testimonials__nav {
    position: absolute;
    margin: 0;
    bottom: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

@media only screen and (min-width: 576px) {
    .testimonials-slider--white+.testimonials__nav {
        bottom:-15px;
        right: 0;
        left: auto;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}

.testimonials-slider--white .testimonials-slider__author {
    margin-top: 30px;
    padding-left: 0;
    margin-bottom: 60px
}

@media only screen and (min-width: 576px) {
    .testimonials-slider--white .testimonials-slider__author {
        width:calc(100% - 200px);
        padding-left: 80px;
        margin-top: 62px;
        margin-bottom: 0
    }
}

.testimonials-slider--white .testimonials-slider__author img {
    display: none
}

@media only screen and (min-width: 576px) {
    .testimonials-slider--white .testimonials-slider__author img {
        display:initial
    }
}

.testimonials-slider--white .testimonials-slider__author span {
    color: #72787f
}

.testimonial-img {
    margin-right: 40px;
    width: 63px;
    height: 63px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 5px;
    border: 2px solid var(--global--color-primary);
    border-radius: 50%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    img {
        max-width: 100%;
        border-radius: 50%;
    }
}
.testimonials-slider__item p{
    font-family: var(--global--font-heading);
    color: var(--global--color-white);
    font-size: 24px;
    font-weight: 500;
    line-height: 39px;
    margin-bottom: 21px;
    font-style: italic;
}
.testimonial-thumbs{
    position: relative;
    padding: 0;
    margin: 0;
    line-height: initial;
    display: -webkit-inline-box!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .testimonial-thumb{
        position: relative;
        padding: 15px 0 15px 0;
        list-style: none;
        cursor: pointer;
        width: 57px;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        line-height: initial;
        span{
            position: absolute;
            top: 15px;
            left: 0;
            font-size: 0;
            height: 1px;
            width: 57px;
            padding: 0;
            border: none;
            outline: 0;
            background-color: #ddd;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            cursor: pointer;
        }
    }
    .testimonial-thumb:not(:last-of-type) {
        margin-right: 6px;
    }
    .testimonial-thumb.active span, .testimonial-thumb:hover span {
        background-color: #00997d;
        height: 5px;
        top: 11px;
    }
}