.ls{
	background-color: #fff;
	color: var(--colorFont);
}

.ls input[type=text],
.ls input[type=email],
.ls input[type=url],
.ls input[type=password],
.ls input[type=search],
.ls input[type=tel],
.ls input[type=number],
.ls textarea,
.ls select,
.ls .form-control{
	background-color: #fff;
}

.ls .breadcrumb{
	background: var(--colorDarkGrey);
}

.ls .breadcrumb .breadcrumb-item{
	color: #fff !important;
}

.ls .breadcrumb .breadcrumb-item:before{
	color: #fff;
}

.ls .breadcrumb .breadcrumb-item a{
	color: #fff !important;
}

.ls .top-includes{
	color: var(--colorFont);
}

.ls .top-includes li a{
	color: var(--colorFont);
}

.ls .top-includes li a.bg-gradient{
	color: #fff;
}

.ls.transparent_bg{
	background-color: rgba(0, 0, 0, 0);
}

.ls .border-right,
.ls .border-left{
	border-color: #f2f5f7 !important;
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6{
	color: var(--colorDarkGrey);
}

.ls h1 a,
.ls h2 a,
.ls h3 a,
.ls h4 a,
.ls h5 a,
.ls h6 a{
	color: inherit;
}

.ls h1 a:hover,
.ls h2 a:hover,
.ls h3 a:hover,
.ls h4 a:hover,
.ls h5 a:hover,
.ls h6 a:hover{
	color: var(--colorFont);
}

.ls .color-grey{
	color: var(--colorGrey);
}

.ls .btn-maincolor:hover{
	background-color: rgba(0, 0, 0, 0);
	border-color: var(--colorMain);
	color: var(--colorDarkGrey);
}

.ls .btn-maincolor2:hover{
	background-color: rgba(0, 0, 0, 0);
	border-color: var(--colorMain2);
	color: var(--colorDarkGrey);
}

.ls .btn-maincolor3:hover{
	background-color: rgba(0, 0, 0, 0);
	border-color: var(--colorMain3);
	color: var(--colorDarkGrey);
}

.ls .btn-darkgrey:hover{
	background-color: rgba(0, 0, 0, 0);
	border-color: var(--colorDarkGrey);
	color: var(--colorDarkGrey);
}

.ls .btn-outline-maincolor:hover{
	background-color: var(--colorMain);
	color: #fff;
}

.ls .btn-outline-maincolor2:hover{
	background-color: var(--colorMain2);
	color: #fff;
}

.ls .btn-outline-maincolor3:hover{
	background-color: var(--colorMain3);
	color: #fff;
}

.ls .btn-outline-darkgrey:hover{
	background-color: var(--colorGrey);
	color: var(--colorDarkGrey);
}

.ls .links-maincolor a:hover{
	opacity: .7;
}

.ls.ms{
	background-color: var(--colorGrey);
	color: var(--colorFontDarken7);
}

.ls.ms .widget_calendar tbody{
	background: #fff;
}

.ls.ms [role=tablist] .card-header{
	background: #e6ecef;
	border-color: #e6ecef;
}

.ls.ms [role=tablist] .card-header:hover{
	background-color: rgba(0, 0, 0, 0);
}

.ls.ms .btn-darkgrey{
	background-color: #fff;
}

.ls.ms .btn-darkgrey:hover{
	background-color: var(--colorGrey);
}

.ls.ms .btn-outline-darkgrey{
	border-color: #fff;
}

.ls.ms .btn-outline-darkgrey:hover{
	border-color: #fff;
	background: #fff;
}

.ls.ms .links-grey a{
	color: var(--colorFontDarken7);
}

.ls.ms .hero-bg{
	background-color: #fff;
}

.ls.ms .hero-bg hr{
	background-color: #f2f5f7;
}

.ls.ms hr{
	background-color: #fff;
}

.ls.ms input[type=text],
.ls.ms input[type=email],
.ls.ms input[type=url],
.ls.ms input[type=password],
.ls.ms input[type=search],
.ls.ms input[type=tel],
.ls.ms input[type=number],
.ls.ms textarea,
.ls.ms select,
.ls.ms .form-control{
	background-color: rgba(0, 0, 0, 0);
	border-color: #e6ecef;
}

.ls.ms .hero-bg input[type=text],
.ls.ms .hero-bg input[type=email],
.ls.ms .hero-bg input[type=url],
.ls.ms .hero-bg input[type=password],
.ls.ms .hero-bg input[type=search],
.ls.ms .hero-bg input[type=tel],
.ls.ms .hero-bg input[type=number],
.ls.ms .hero-bg textarea,
.ls.ms .hero-bg select,
.ls.ms .hero-bg .form-control{
	border-color: #f2f5f7;
	background-color: var(--colorGrey);
}

.ls.ms td,
.ls.ms th,
.ls.ms .bordered{
	border-color: #e2e9ed;
}

.ls.ms .btn-secondary{
	border-color: #fff;
}

.ls.ms .btn-secondary:hover{
	border-color: var(--colorGreyDarken6);
	background-color: var(--colorGreyDarken6);
}

.woocommerce .page_content.ls.ms,
.woocommerce-page .page_content.ls.ms{
	background-color: #fff;
}

.ls .color-icon.bg-icon{
	background-color: #ececec;
}

.ls .pricing-plan [class*=btn-outline-]{
	background-color: #fff;
}

.ls .pricing-plan [class*=btn-outline-]:hover{
	background-color: #fff;
}

.ls .bubble{
	background: rgba(var(--colorMain), 0.25);
}

.ls .owl-carousel .owl-dots .owl-dot.active span,
.ls .owl-carousel .owl-dots .owl-dot:hover span{
	background-color: var(--colorDark);
}

.ls .color-light{
	color: #fff;
}

.ls.ms .nav-tabs > li.active > a,
.ls.ms .nav-tabs > li.active > a:hover,
.ls.ms .nav-tabs > li.active > a:focus{
	background-color: #fff;
	border-color: #fff;
}

.ls.ms .tab-content{
	background-color: #fff;
}

.ls.ms .tab-content .bordered{
	border-color: #e6e6e6;
}

.ls.ms .tab-content.no-border{
	background-color: rgba(0, 0, 0, 0);
}

.ls.ms .tab-content .form-control{
	background-color: var(--colorGrey);
}

.ls.ms .vertical-tabs .nav > li > a{
	background-color: #fff;
}

.ls.ms .vertical-tabs .nav > li > a:hover,
.ls.ms .vertical-tabs .nav > li.active > a{
	background-color: var(--colorDarkGrey);
}

.ls.ms .panel-title > a{
	background-color: var(--colorDarkGrey);
}

.ls.ms .panel-title > a.collapsed{
	background-color: #fff;
}

.ls.ms .hero-bg .panel-title > a{
	border: 1px solid #f2f5f7;
}

.ls.ms .panel-title > a.collapsed:hover,
.ls.ms .panel-title > a:hover{
	background-color: var(--colorDarkGrey);
}

.ls.ms .pagination a,
.ls.ms .pagination span{
	background-color: #fff;
	border-color: #fff;
}

.ls.ms .breadcrumb{
	color: var(--colorFont);
}

.ls.ms .breadcrumb a{
	color: var(--colorFontDarken10);
}

.ls.ms .breadcrumb a:hover{
	color: var(--colorMain);
}

.ls.ms [class*=btn-outline-]:not(:hover){
	background-color: var(--colorGrey);
}