/*------------------------------------*\
		#Features
\*------------------------------------*/
.features {
	padding-top   : 130px;
	padding-bottom: 100px;
	overflow      : visible;
}

@media screen and (max-width: 767.98px) {
	.features {
		padding-top   : 70px;
		padding-bottom: 70px;
	}
}

.features .feature-panel-holder {
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.features .feature-panel-holder:hover .feature-panel {
	background-color: var(--global--color-white);
}

.features .feature-panel-holder:hover .feature-panel .feature-icon {
	-webkit-animation: bounce 0.5s ease-in-out;
	animation        : bounce 0.5s ease-in-out;
}

.features .feature-panel-holder:hover .feature-panel a {
	background-color: var(--global--color-primary);
	padding-left    : 15px;
	padding-right   : 15px;
}

.features .feature-panel-holder:hover .feature-panel a i {
	color: var(--global--color-white);
}

.features .feature-panel-holder:hover .feature-panel a span {
	font-size  : 14px;
	opacity    : 1;
	margin-left: 10px;
}

.features .feature-panel-holder:hover .feature-panel.feature-panel-2 {
	-webkit-box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.21);
	box-shadow        : 0px 5px 83px 0px rgba(40, 40, 40, 0.21);
}

.features .feature-panel-holder .feature-panel {
	position             : relative;
	padding              : 39px 35px 40px;
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-webkit-box-orient   : vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction   : column;
	flex-direction       : column;
	-webkit-box-align    : center;
	-ms-flex-align       : center;
	align-items          : center;
	text-align           : center;
	overflow             : hidden;
	background-color     : var(--global--color-white);
	border-radius        : 8px;
	-webkit-box-shadow   : 0px 5px 83px 0px rgba(40, 40, 40, 0.21);
	box-shadow           : 0px 5px 83px 0px rgba(40, 40, 40, 0.21);
	cursor               : pointer;
	margin-bottom        : 30px;
	-webkit-transition   : 0.3s ease-in-out;
	-o-transition        : 0.3s ease-in-out;
	transition           : 0.3s ease-in-out;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.features .feature-panel-holder .feature-panel {
		padding: 39px 19px 40px;
	}
}

@media screen and (max-width: 991.98px) {
	.features .feature-panel-holder .feature-panel {
		max-width: 370px;
		margin   : 0 auto 30px;
	}
}

.features .feature-panel-holder .feature-panel .feature-icon {
	margin-bottom: 17px;
}
.features .feature-panel-holder .feature-panel .feature-img {
	margin-bottom: 17px;
	width: 70px;
	height: 70px;
}

.features .feature-panel-holder .feature-panel .feature-pic {
	margin-bottom: 17px;
	width: 100%;
	height: 100%;
}

.features .feature-panel-holder .feature-panel .feature-icon i::before {
	color             : var(--global--color-primary);
	margin-left       : 0;
	font-size         : 65px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.features .feature-panel-holder .feature-panel .feature-content {
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-webkit-box-orient   : vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction   : column;
	flex-direction       : column;
	-webkit-box-align    : center;
	-ms-flex-align       : center;
	align-items          : center;
	text-align           : center;
}
.features .feature-panel-holder .feature-panel-4 .feature-content{
	min-height: 250px;
} 

.features .feature-panel-holder .feature-panel .feature-content h4 {
	font-family       : var(--global--font-heading);
	font-weight       : 600;
	font-size         : 19px;
	line-height       : 27px;
	text-transform    : capitalize;
	margin-bottom     : 16px;
	color             : var(--global--color-secondary);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}
.features .feature-panel-holder .feature-panel .feature-content h6 {
	font-family       : var(--global--font-heading);
	font-weight       : 500;
	font-size         : 14px;
	line-height       : 27px;
	text-transform    : capitalize;
	margin-bottom     : 0;
	color             : var(--global--color-secondary);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	min-height: 55px;
	display: inline-block;
}

.features .feature-panel-holder .feature-panel .feature-content p {
	font-family       : var(--global--font-body);
	font-size         : 15px;
	line-height       : 25px;
	margin-bottom     : 20px;
	color             : var(--global--color-body);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.features .feature-panel-holder .feature-panel a {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	padding           : 10px;
	height            : 32px;
	border-radius     : 16px;
	background-color  : var(--global--color-heading);
	font-size         : 12px;
	color             : var(--global--color-white);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
}

.features .feature-panel-holder .feature-panel a i {
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.features .feature-panel-holder .feature-panel a span {
	font-size         : 0;
	opacity           : 0;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	text-transform    : capitalize;
	font-family       : var(--global--font-body);
	font-weight       : 700;
	font-size         : 0px;
	color             : var(--global--color-white);
}

.features .feature-panel-holder .feature-panel.feature-panel-2 {
	-webkit-box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 5px 83px 0px rgba(40, 40, 40, 0.11);
}

.features .actions-holder {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

@media screen and (max-width: 991.98px) {
	.features .actions-holder {
		-webkit-box-pack: center;
		-ms-flex-pack   : center;
		justify-content : center;
	}
}

@media screen and (max-width: 767.98px) {
	.features .actions-holder {
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
	}
}

.features .actions-holder .btn {
	margin-right: 30px;
}

.features .actions-holder .btn:first-child {
	width: 170px;
}

.features .actions-holder .btn:last-child {
	width       : 180px;
	margin-right: 0;
	padding     : 0 28px;
}

@media screen and (max-width: 767.98px) {
	.features .actions-holder .btn {
		margin-right : 0;
		margin-bottom: 20px;
	}
}

.features .carousel-dots .owl-dots {
	margin-top   : 10px;
	margin-bottom: 70px;
}

.features.features-1 {
	padding-bottom: 0;
}

.features.features-2 {
	padding-bottom: 0;
	position      : relative;
	z-index       : 2;
}

.features.features-2 .feature-panel-holder .feature-panel {
	margin-bottom: 32px;
}

.features.features-3 {
	padding-top   : 0;
	padding-bottom: 0;
}

.features.features-4 {
	padding-top   : 130px;
	padding-bottom: 110px;
	position      : relative;
}

@media screen and (max-width: 991.98px) {
	.features.features-4 {
		padding-top   : 70px;
		padding-bottom: 70px;
	}
}

.features.features-4 .features-overlay {
	position         : absolute;
	top              : 0;
	left             : 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform    : translateX(-50%);
	transform        : translateX(-50%);
	height           : 100%;
	width            : calc(100% - 7%);
	border-radius    : 12px;
}

@media screen and (max-width: 991.98px) {
	.features.features-4 .features-overlay {
		width: calc(100% - 4%);
	}
}

.features.features-4 .heading {
	position: relative;
	z-index : 1;
}

.features.features-4 .owl-carousel .owl-dots {
	margin-bottom: 0;
}

@media screen and (max-width: 991.98px) {
	.more-features {
		text-align   : center;
		margin-bottom: 40px;
	}
}

.more-features p {
	font-family  : var(--global--font-body);
	font-weight  : 700;
	font-size    : 16px;
	color        : var(--global--color-gray);
	margin-bottom: 30px;
}

.more-features p a {
	position          : relative;
	color             : var(--global--color-primary);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	text-transform    : capitalize;
}

.more-features p a:hover {
	color: var(--global--color-primary-70);
}

.more-features .btn {
	width: 185px;
}

.more-features.more-features-2 {
	text-align       : center;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	padding-top      : 40px;
	padding-bottom   : 40px;
}

@media screen and (max-width: 991.98px) {
	.more-features.more-features-2 {
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
		margin-bottom        : 0;
	}
}

.more-features.more-features-2 p {
	margin-right : 30px;
	margin-bottom: 0;
	font-size    : 15px;
}

@media screen and (max-width: 991.98px) {
	.more-features.more-features-2 p {
		margin-right : 0;
		margin-bottom: 30px;
	}
}

.more-features.more-features-2 .btn {
	width  : 178px;
	height : 45px;
	padding: 0 23px;
	color  : var(--global--color-heading);
}

.more-features.more-features-3 {
	text-align: center;
}

.more-features.more-features-3 p {
	font-family  : var(--global--font-body);
	font-weight  : 700;
	font-size    : 15px;
	color        : var(--global--color-white);
	margin-bottom: 70px;
}

.more-features.more-features-3 p a {
	position          : relative;
	font-weight       : 700;
	color             : var(--global--color-primary);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	text-transform    : capitalize;
	padding-bottom    : 6px;
}

.more-features.more-features-3 p a:hover::before {
	width: calc(100% - 20%);
}

.more-features.more-features-3 p a::before {
	content           : '';
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	position          : absolute;
	bottom            : 0;
	left              : 50%;
	-webkit-transform : translateX(-50%);
	-ms-transform     : translateX(-50%);
	transform         : translateX(-50%);
	width             : 100%;
	height            : 2px;
	background-color  : var(--global--color-primary);
}

.features-bar {
	padding-top   : 0;
	padding-bottom: 0px;
	overflow      : visible;
}

.features-bar .features-holder {
	position         : relative;
	z-index          : 55;
	-webkit-transform: translateY(-80px);
	-ms-transform    : translateY(-80px);
	transform        : translateY(-80px);
	margin-bottom    : -80px;
	background-color : var(--global--color-white);
	border-radius    : 4px;
	overflow         : hidden;
}

@media screen and (max-width: 567px) {
	.features-bar .features-holder {
		max-width: 370px;
	}
}

@media screen and (min-width: 992px) {
	.features-bar .features-holder {
		-webkit-box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
		box-shadow        : 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
	}
}

@media screen and (max-width: 767.98px) {
	.features-bar .features-holder {
		margin: 0 auto -80px;
	}
}

@media screen and (max-width: 767px) {
	.features-bar .features-holder {
		padding-bottom: 40px;
	}
}

.features-bar .features-holder .feature-panel {
	padding: 34px 40px 40px;
}

@media screen and (max-width: 767px) {
	.features-bar .features-holder .feature-panel {
		padding-bottom: 0;
	}
}

@media screen and (min-width: 992px) {
	.features-bar .features-holder .feature-panel {
		-webkit-box-shadow: 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
		box-shadow        : 0px 3px 63px 0px rgba(40, 40, 40, 0.11);
	}
}

@media screen and (max-width: 991.98px) {
	.features-bar .features-holder .feature-panel {
		max-width: 370px;
		margin   : 0 auto;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.features-bar .features-holder .feature-panel {
		padding-left : 30px;
		padding-right: 30px;
	}
}

.features-bar .features-holder .feature-panel:hover .feature-content i {
	color            : var(--global--color-white);
	background-color : var(--global--color-primary);
	-webkit-transform: rotate(360deg);
	-ms-transform    : rotate(360deg);
	transform        : rotate(360deg);
}

.features-bar .features-holder .feature-panel .feature-content {
	position             : relative;
	z-index              : 3;
	text-align           : center;
	display              : -webkit-box;
	display              : -ms-flexbox;
	display              : flex;
	-webkit-box-orient   : vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction   : column;
	flex-direction       : column;
	-webkit-box-align    : center;
	-ms-flex-align       : center;
	align-items          : center;
}

.features-bar .features-holder .feature-panel .feature-content i {
	margin-bottom     : 7px;
	width             : 94px;
	height            : 94px;
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	border-radius     : 50%;
	background-color  : var(--global--color-white);
	color             : var(--global--color-primary);
	position          : relative;
	overflow          : hidden;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.features-bar .features-holder .feature-panel .feature-content i::before {
	font-size: 65px;
	z-index  : 2;
}

.features-bar .features-holder .feature-panel .feature-content h5 {
	text-transform: capitalize;
	font-family   : var(--global--font-heading);
	font-weight   : 600;
	font-size     : 19px;
	line-height   : 28px;
	color         : var(--global--color-secondary);
	margin-bottom : 0px;
}

.features-bar .actions-holder {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

@media screen and (max-width: 991.98px) {
	.features-bar .actions-holder {
		-webkit-box-pack: center;
		-ms-flex-pack   : center;
		justify-content : center;
	}
}

@media screen and (max-width: 767.98px) {
	.features-bar .actions-holder {
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
	}
}

.features-bar .actions-holder .btn {
	width       : 170px;
	height      : 60px;
	margin-right: 30px;
}

@media screen and (max-width: 767.98px) {
	.features-bar .actions-holder .btn {
		margin-right : 0;
		margin-bottom: 20px;
	}
}

.features-bar .actions-holder .btn:last-child {
	border-color               : #687696;
	border                     : 2px solid;
	-webkit-transition-property: background, color, border-color;
	-o-transition-property     : background, color, border-color;
	transition-property        : background, color, border-color;
}

.features-bar .actions-holder .btn:last-child::before {
	background-color: var(--global--color-primary);
	width           : calc(100% + 4px);
	height          : calc(100% + 4px);
	top             : -2px;
	left            : -2px;
}

.features-bar .actions-holder .btn:last-child:hover {
	border-color: var(--global--color-secondary);
}