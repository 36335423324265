/*------------------------------------*\
		#TopBar
\*------------------------------------*/
.top-bar {
	padding         : 0 40px;
	display         : -webkit-box;
	display         : -ms-flexbox;
	display         : flex;
	-webkit-box-pack: justify;
	-ms-flex-pack   : justify;
	justify-content : space-between;
	height          : 50px;
	position        : relative;
	z-index         : 8888;
	background-color: var(--global--color-secondary);
}

@media (min-width: 992px) and (max-width: 1100px) {
	.top-bar {
		padding: 0 20px;
	}
}

.top-bar .block-left {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	-webkit-box-pack : start;
	-ms-flex-pack    : start;
	justify-content  : flex-start;
	-webkit-box-flex : 1;
	-ms-flex-positive: 1;
	flex-grow        : 1;
}

.top-bar .block-right {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	-webkit-box-pack : end;
	-ms-flex-pack    : end;
	justify-content  : flex-end;
}

.top-bar .block-right .module-language {
	margin-left: 30px;
}

@media (min-width: 992px) and (max-width: 1100px) {
	.top-bar .block-right .module-language {
		margin-left: 15px;
	}
}

.top-bar .top-contact {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.top-bar .top-contact .contact-infos {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	margin-right     : 30px;
}

@media (min-width: 992px) and (max-width: 1100px) {
	.top-bar .top-contact .contact-infos {
		margin-right: 15px;
	}
}

.top-bar .top-contact .contact-infos:last-child {
	margin-right: 0;
}

.top-bar .top-contact .contact-infos i {
	color       : var(--global--color-primary);
	font-size   : 15px;
	margin-right: 10px;
}

.top-bar .top-contact .contact-infos a,
.top-bar .top-contact .contact-infos p {
	font-family   : var(--global--font-body);
	line-height   : 22px;
	font-size     : 13px;
	font-weight   : 400;
	margin-bottom : 0;
	color         : #929ba2;
	text-transform: capitalize;
}

.top-bar .top-contact .contact-infos a:hover {
	color: var(--global--color-primary);
}

.top-bar .social-links {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	margin-right     : 30px;
}

@media (min-width: 992px) and (max-width: 1100px) {
	.top-bar .social-links {
		margin-right: 15px;
	}
}

.top-bar .social-links a {
	margin-right      : 10px;
	color             : var(--global--color-primary);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	font-size         : 20px;
	line-height       : 1;
}

.top-bar .social-links a:last-child {
	margin-right: 0;
}

.top-bar .social-links a:hover i {
	-webkit-transform: scale(0.9);
	-ms-transform    : scale(0.9);
	transform        : scale(0.9);
}

.top-bar .social-links a i {
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.top-bar .topbar-links a {
	font-size         : 13px;
	color             : var(--global--color-white);
	text-transform    : capitalize;
	margin-right      : 15px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.top-bar .topbar-links a:hover {
	color: var(--global--color-primary);
}

.top-bar .topbar-links a:last-child {
	margin-right: 0;
}

.top-bar .headline {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	font-family      : var(--global--font-body);
	font-weight      : 400;
	font-size        : 13px;
	line-height      : 21px;
	color            : var(--global--color-primary);
	margin-bottom    : 0;
	text-transform   : capitalize;
}

.top-bar .headline a {
	font-family   : var(--global--font-body);
	font-weight   : 400;
	font-size     : 13px;
	line-height   : 21px;
	color         : var(--global--color-primary);
	margin-bottom : 0;
	text-transform: capitalize;
	margin-left   : 4px;
}

.top-bar .headline a {
	color: #929ba2;
}

.top-bar .headline i {
	font-size   : 16px;
	margin-right: 10px;
}

.top-bar.top-bar-2 .top-contact {
	margin-right: 30px;
}

.top-bar.top-bar-2 .social-links {
	margin-right: 0;
}

.top-bar.top-bar-3 {
	padding: 0;
}

.top-bar.top-bar-3 .topbar-links {
	margin-right: 30px;
}

.top-bar.top-bar-3 .social-links {
	margin-right: 0;
}

.top-bar.top-bar-3 .blocks-holder {
	display         : -webkit-box;
	display         : -ms-flexbox;
	display         : flex;
	height          : 100%;
	-webkit-box-pack: justify;
	-ms-flex-pack   : justify;
	justify-content : space-between;
}