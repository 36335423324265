.section {
    align-items: center;
    display: grid;
    grid-template: "stack";
    position: relative;

    &__container {
        width: 100%;
    }

    .section__content {
        display: flex;
        flex-direction: row;

        > :first-child:last-child {
            margin-left: auto;
            margin-right: auto;
        }
        > div:last-child{
            .-textPanel{
                margin-left: 10%;
            }
        }
    }

    &__background,
    &__container {
        grid-area: stack;
    }

    &__background {
        align-self: stretch;
        position: relative;
    }

    &__backgroundImg {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__header {
        margin-bottom: 2rem;
    }

    &__heading,
    &__subheading {
        margin-bottom: 0;
    }

    &__introduction {

        &.-emphasized {
            font-size: 1.125rem;
        }
    }

    &__conclusion{
        padding-top: 2rem;
    }

    &__actions {
        display: flex;
        justify-content: start;
    }
    &__action{
        font-weight: 600;
        border-bottom: 1px solid #000;
        color: #000;
    }
    &__item-textbox{
        flex: 1;
        &:first-child{
            margin-right: 1rem;
        }
        &:last-child{
            margin-left: 1rem;
        }
    }
    &__item-text{
        display: flex;
        flex: 1;
        .-textPanel{
            width: 90%;
        }
    }
    &__item-image{
        border-radius: 10px;
        overflow: hidden;
        flex: 1;
        display: flex;
        align-items: center;
    }
    &__image{
        img{
            max-width: 100%;
        }
    }

}
.-align__content__center{
    align-items: center;
}

.-richTextSection{
    .section__header{
        text-align: center;
        .eyebrow.-primary{
            color: #000;
            margin-bottom: 2rem;
        }
    }
}

.text-panel-holder {
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.text-panel-holder:hover .text-panel {
    background-color: var(--global--color-white)
}

.text-panel-holder:hover .text-panel .text-icon {
    -webkit-animation: bounce .5s ease-in-out;
    animation: bounce .5s ease-in-out
}

.text-panel-holder:hover .text-panel a {
    background-color: var(--global--color-primary);
    padding-left: 15px;
    padding-right: 15px
}

.text-panel-holder:hover .text-panel a i {
    color: var(--global--color-white)
}

.text-panel-holder:hover .text-panel a span {
    font-size: 14px;
    opacity: 1;
    margin-left: 10px
}

.text-panel-holder:hover .text-panel.text-panel-2 {
    -webkit-box-shadow: 0 5px 83px 0 rgba(40,40,40,.21);
    box-shadow: 0 5px 83px 0 rgba(40,40,40,.21)
}

.text-panel-holder .text-panel {
    position: relative;
    padding: 39px 35px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    background-color: var(--global--color-white);
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 83px 0 rgba(40,40,40,.21);
    box-shadow: 0 5px 83px 0 rgba(40,40,40,.21);
    cursor: pointer;
    margin-bottom: 30px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

@media(min-width: 992px) and (max-width:1200px) {
    .text-panel-holder .text-panel {
        padding:39px 19px 40px
    }
}

@media screen and (max-width: 991.98px) {
    .text-panel-holder .text-panel {
        max-width:370px;
        margin: 0 auto 30px
    }
}

.text-panel-holder .text-panel .text-icon {
    margin-bottom: 17px
}

.text-panel-holder .text-panel .text-icon i::before {
    color: var(--global--color-primary);
    margin-left: 0;
    font-size: 65px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.text-panel-holder .text-panel .text-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    align-items: start;
    text-align: left
}


.text-panel-holder .text-panel .text-content p {
    font-family: var(--global--font-body);
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 20px;
    color: var(--global--color-body);
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    position: relative;
    padding-left: 30px;
    &:after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: linear-gradient(to right, #32c36c, #B5E9CA);
        position: absolute;
        top: 7px;
        left: 0;
    }
}

.text-panel-holder .text-panel a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    height: 32px;
    border-radius: 16px;
    background-color: var(--global--color-heading);
    font-size: 12px;
    color: var(--global--color-white);
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    -webkit-box-shadow: 0 3px 63px 0 rgba(40,40,40,.11);
    box-shadow: 0 3px 63px 0 rgba(40,40,40,.11)
}

.text-panel-holder .text-panel a i {
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.text-panel-holder .text-panel a span {
    font-size: 0;
    opacity: 0;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-transform: capitalize;
    font-family: var(--global--font-body);
    font-weight: 700;
    font-size: 0;
    color: var(--global--color-white)
}

.text-panel-holder .text-panel.text-panel-2 {
    -webkit-box-shadow: 0 5px 83px 0 rgba(40,40,40,.11);
    box-shadow: 0 5px 83px 0 rgba(40,40,40,.11)
}