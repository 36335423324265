@use '../utils' as *;

/*----------------------------------------*/
/*  02. HEADER CSS START
/*----------------------------------------*/

.header {
	height            : 80px;
	-webkit-box-shadow: 0px 5px 41.5px rgba(40, 40, 40, 0.11);
	box-shadow        : 0px 5px 41.5px rgba(40, 40, 40, 0.11);
}

@media screen and (min-width: 992px) {
	.header {
		height: 100px;
	}
}

.header .navbar-brand {
	padding     : 0;
	margin      : 0;
	margin-right: 40px;
}

@media (min-width: 992px) and (max-width: 1100px) {
	.header .navbar-brand {
		margin-right: 20px;
	}
}

@media screen and (min-width: 992px) {
	.header .navbar-brand {
		height           : 100px;
		display          : -webkit-box;
		display          : -ms-flexbox;
		display          : flex;
		-webkit-box-align: center;
		-ms-flex-align   : center;
		align-items      : center;
	}
}

@media (min-width: 1200px) and (max-width: 1350px) {
	.header .navbar-brand {
		margin-right: 20px;
	}
}

.header .navbar-brand .logo {
	max-width: 100%;
	width    : 230px;
	height   : 23px;
}

@media screen and (max-width: 991.98px) {
	.header .navbar-brand .logo {
		width : auto;
		height: 15px;
	}
}

@media screen and (max-width: 767.98px) {
	.header .navbar-brand .logo {
		width: 150px;
	}
}

.header.header-light {
	background-color: var(--global--color-white);
}

.header.header-light .navbar {
	background-color: var(--global--color-white);
}

.header.header-light .navbar .logo-dark {
	display: inline-block;
}

@media screen and (max-width: 991.98px) {
	.header.header-light .navbar .logo-dark {
		display: none;
	}
}

.header.header-light .navbar .logo-light {
	display: none;
}

.header.header-light .navbar .logo-mobile {
	display: inline-block;
}

@media screen and (min-width: 992px) {
	.header.header-light .navbar .logo-mobile {
		display: none;
	}
}

.header.header-light .navbar .navbar-nav>li>a {
	color: var(--global--color-heading);
}

.header.header-light .navbar .navbar-nav>li>a::before {
	background-color: var(--global--color-heading);
}

.header.header-light .navbar .module .module-icon i {
	color: var(--global--color-secondary);
}

.header.header-light .navbar .module .module-icon i:hover {
	color: var(--global--color-primary);
}

.header.header-topbar {
	height: 150px;
}

@media screen and (max-width: 991.98px) {
	.header.header-topbar {
		height: 80px;
	}
}

@media screen and (max-width: 991.98px) {
	.header.header-topbar .top-bar {
		display: none;
	}
}

@media screen and (min-width: 992px) {
	.header.header-topbar .navbar {
		padding   : 0 0 0 40px;
		min-height: 100px;
		position  : relative;
		top       : auto;
		bottom    : auto;
		right     : auto;
		left      : auto;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.header.header-topbar .navbar {
		padding: 0 0 0 20px;
	}
}

@media screen and (min-width: 992px) {
	.header.header-topbar .navbar .navbar-nav>li>a {
		line-height: 100px;
	}
}

.header.header-topbar.header-shadow .navbar {
	-webkit-box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11);
	box-shadow        : 0px 5px 41.5px rgba(9, 29, 62, 0.11);
}

.header.header-topbar.header-shadow .navbar {
	-webkit-box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11);
	box-shadow        : 0px 5px 41.5px rgba(9, 29, 62, 0.11);
}