/*------------------------------------*\
		#MODULE
\*------------------------------------*/
.module-holder {
	margin-left      : auto;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	height           : 100px;
}

@media screen and (max-width: 991.98px) {

	.module-holder .module-language,
	.module-holder .module-search,
	.module-holder .module-cart {
		display: none;
	}
}

.module-holder.module-holder-phone {
	display: none;
}

@media screen and (max-width: 991.98px) {
	.module-holder.module-holder-phone {
		display   : -webkit-box;
		display   : -ms-flexbox;
		display   : flex;
		height    : 80px;
		margin-top: 0;
	}
}

.module-holder.module-holder-phone .navbar-toggler {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	width             : 30px;
	height            : 30px;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	border            : 0;
	padding           : 0;
	outline           : 0;
	cursor            : pointer;
	border-radius     : 0;
}

.module-holder.module-holder-phone .navbar-toggler:focus {
	-webkit-box-shadow: none;
	box-shadow        : none;
}

@media screen and (min-width: 1200px) {
	.module-holder.module-holder-phone .navbar-toggler {
		display: none;
	}
}

.module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
	background-color: transparent;
}

.module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before,
.module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
	top: 0;
}

.module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
	-webkit-transform: rotate(45deg);
	-ms-transform    : rotate(45deg);
	transform        : rotate(45deg);
}

.module-holder.module-holder-phone .navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
	-webkit-transform: rotate(-45deg);
	-ms-transform    : rotate(-45deg);
	transform        : rotate(-45deg);
}

.module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon {
	background-color  : var(--global--color-heading);
	width             : 100%;
	height            : 2px;
	position          : relative;
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon::before,
.module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
	content           : '';
	position          : absolute;
	left              : 0;
	height            : 2px;
	width             : 100%;
	background-color  : var(--global--color-heading);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
}

.module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon::before {
	top: -10px;
}

.module-holder.module-holder-phone .navbar-toggler .navbar-toggler-icon::after {
	top: 10px;
}

.module-holder.module-holder-phone .module-language {
	display     : -webkit-inline-box;
	display     : -ms-inline-flexbox;
	display     : inline-flex;
	margin-left : 20px;
	margin-right: 20px;
}

@media screen and (min-width: 992px) {
	.module-holder.module-holder-phone .module-language {
		display: none;
	}
}

@media screen and (max-width: 767.98px) {
	.module-holder.module-holder-phone .module-language {
		margin-right: 15px;
	}
}

.module-holder.module-holder-phone .module-language .selected img {
	margin-right: 0;
}

.module-holder.module-holder-phone .module-language .selected span,
.module-holder.module-holder-phone .module-language .selected i {
	display: none;
}

.module-holder.module-holder-phone .module-language .selected .lang-list {
	left: -45px;
}

.module-holder.module-holder-phone .module-search {
	display      : -webkit-inline-box;
	display      : -ms-inline-flexbox;
	display      : inline-flex;
	border-bottom: 0;
	padding      : 0;
	padding-right: 20px;
}

@media screen and (max-width: 767.98px) {
	.module-holder.module-holder-phone .module-search {
		padding-right: 15px;
	}
}

.module-holder.module-holder-phone .module-cart {
	display    : -webkit-inline-box;
	display    : -ms-inline-flexbox;
	display    : inline-flex;
	margin-left: 30px;
}

@media screen and (max-width: 767.98px) {
	.module-holder.module-holder-phone .module-cart {
		margin-left: 20px;
	}
}

.module {
	display : inline-block;
	position: relative;
}

.module span.title {
	display: none;
}

.module .module-label {
	width           : 16px;
	height          : 16px;
	line-height     : 16px;
	border-radius   : 50%;
	background-color: var(--global--color-primary);
	color           : var(--global--color-white);
	font-size       : 10px;
	font-weight     : 400;
	text-align      : center;
	position        : absolute;
	top             : -6px;
	left            : 10px;
}

.module .module-box {
	position          : absolute;
	display           : block;
	-webkit-box-shadow: 0 2px 3px rgba(40, 40, 40, 0.1);
	box-shadow        : 0 2px 3px rgba(40, 40, 40, 0.1);
	background-color  : #1b1a1a;
	opacity           : 0;
	visibility        : hidden;
	-webkit-transition: all 100ms linear;
	-o-transition     : all 100ms linear;
	transition        : all 100ms linear;
	right             : 0;
	width             : 290px;
	border-radius     : 6px;
	padding           : 30px;
	z-index           : 99;
	-webkit-transform : translateY(10%);
	-ms-transform     : translateY(10%);
	transform         : translateY(10%);
	height            : 0;
	margin-top        : 1px;
}

/* Module Fullscreen */
.module-fullscreen {
	left            : 0;
	top             : 0;
	right           : 0;
	z-index         : 9999;
	display         : block;
	height          : 100%;
	width           : 100%;
	opacity         : 0;
	visibility      : hidden;
	background-color: var(--global--color-white);
	position        : fixed;
}

.module-fullscreen .module-cancel {
	top              : 0px;
	right            : 0px;
	border-radius    : 0px;
	position         : absolute;
	width            : 100px;
	height           : 100px;
	font-size        : 20px;
	font-weight      : 700;
	text-transform   : uppercase;
	color            : var(--global--color-white);
	background-color : var(--global--color-primary);
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

@media screen and (max-width: 767.98px) {
	.module-fullscreen .module-cancel {
		font-size: 14px;
		width    : 50px;
		height   : 50px;
	}
}

.module-fullscreen.module-active {
	visibility        : visible;
	opacity           : 1;
	-webkit-transition: opacity .5s ease-in-out;
	-o-transition     : opacity .5s ease-in-out;
	transition        : opacity .5s ease-in-out;
}

.module-fullscreen.fullscreen-light {
	background-color: var(--global--color-white);
}

.module-active .module-box {
	opacity          : 1;
	visibility       : visible;
	display          : block;
	-webkit-transform: translateY(0px);
	-ms-transform    : translateY(0px);
	transform        : translateY(0px);
	height           : inherit;
}

/* Module Search */
.module-search {
	position         : relative;
	padding-right    : 20px;
	height           : 80px;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

@media screen and (min-width: 992px) {
	.module-search {
		height: 100px;
	}
}

@media (min-width: 992px) and (max-width: 1100px) {
	.module-search {
		padding-right: 20px;
	}
}

.module-search .module-icon i {
	color             : var(--global--color-heading);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	cursor            : pointer;
}

.module-search .module-icon i:hover {
	color: var(--global--color-primary);
}

@media only screen and (max-width: 991px) {
	.module-search .module-icon i {
		border-left  : none;
		padding-left : 0;
		padding-right: 5px;
	}
}

.module-search-warp {
	left            : 0;
	top             : 0;
	right           : 0;
	z-index         : 9999;
	display         : block;
	height          : 100%;
	width           : 100%;
	opacity         : 0;
	visibility      : hidden;
	background-color: var(--global--color-white);
	position        : fixed;
}

.module-search-warp.search-warp-active {
	visibility        : visible;
	opacity           : 1;
	-webkit-transition: opacity .5s ease-in-out;
	-o-transition     : opacity .5s ease-in-out;
	transition        : opacity .5s ease-in-out;
}

.module-search-warp .module-cancel {
	top              : 0px;
	right            : 0px;
	border-radius    : 0px;
	position         : absolute;
	width            : 100px;
	height           : 100px;
	font-size        : 20px;
	font-weight      : 700;
	text-transform   : uppercase;
	color            : var(--global--color-white);
	background-color : var(--global--color-primary);
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

@media screen and (max-width: 767.98px) {
	.module-search-warp .module-cancel {
		font-size: 14px;
		width    : 50px;
		height   : 50px;
	}
}

.module-search-warp .form-search {
	margin-bottom: 0;
}

.module-search-warp .form-search button::before {
	position   : absolute;
	left       : 0;
	top        : 0;
	content    : "\e90b";
	color      : var(--global--color-heading);
	font-family: "energia";
	font-size  : 24px;
	font-weight: 400;
	line-height: 70px;
	z-index    : 999;
	cursor     : pointer;
}

.module-search-warp .form-control {
	position          : relative;
	color             : var(--global--color-white);
	font-family       : var(--global--font-heading);
	font-size         : 24px;
	font-weight       : 400;
	line-height       : 70px;
	height            : 70px;
	color             : var(--global--color-body);
	border            : none;
	background-color  : transparent;
	width             : 100%;
	border-bottom     : 2px solid #eaeaea;
	padding           : 17px 30px 17px 46px;
	margin-bottom     : 0;
	-webkit-transition: 400ms linear;
	-o-transition     : 400ms linear;
	transition        : 400ms linear;
	text-transform    : capitalize;
	border-radius     : 0;
}

.module-search-warp .form-control:focus,
.module-search-warp .form-control:active {
	border-color: var(--global--color-primary);
}

.module-search-warp .form-control::-webkit-input-placeholder {
	color      : var(--global--color-body);
	font-family: var(--global--font-heading);
	font-size  : 35px;
}

@media screen and (max-width: 767.98px) {
	.module-search-warp .form-control::-webkit-input-placeholder {
		font-size: 18px;
	}
}

.module-search-warp .form-control::-moz-placeholder {
	color      : var(--global--color-body);
	font-family: var(--global--font-heading);
	font-size  : 35px;
}

@media screen and (max-width: 767.98px) {
	.module-search-warp .form-control::-moz-placeholder {
		font-size: 18px;
	}
}

.module-search-warp .form-control::-moz-placeholder {
	color      : var(--global--color-body);
	font-family: var(--global--font-heading);
	font-size  : 35px;
}

@media screen and (max-width: 767.98px) {
	.module-search-warp .form-control::-moz-placeholder {
		font-size: 18px;
	}
}

.module-search-warp .form-control:-ms-input-placeholder {
	color      : var(--global--color-body);
	font-family: var(--global--font-heading);
	font-size  : 35px;
}

@media screen and (max-width: 767.98px) {
	.module-search-warp .form-control:-ms-input-placeholder {
		font-size: 18px;
	}
}

.module-search-warp .btn {
	background-color: transparent;
	border-radius   : 0;
	width           : 35px;
	line-height     : 57px;
	color           : var(--global--color-white);
	font-size       : 28px;
	font-weight     : 400;
	text-align      : right;
	margin-left     : 0;
	padding         : 0;
}

/* Module Cart */
.module-cart {
	margin-left      : 40px;
	height           : 80px;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

@media screen and (min-width: 992px) {
	.module-cart {
		height: 100px;
	}
}

@media (min-width: 992px) and (max-width: 1100px) {
	.module-cart {
		margin-left: 20px;
	}
}

.module-cart .module-icon {
	position: relative;
}

.module-cart .module-icon i {
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	font-size         : 16px;
	position          : relative;
	cursor            : pointer;
}

.module-cart .module-cart-warp {
	position          : absolute;
	-webkit-box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.15);
	box-shadow        : 0px 5px 41.5px rgba(9, 29, 62, 0.15);
	top               : 100%;
	left              : -150px;
	width             : 290px;
	background-color  : var(--global--color-white);
	padding           : 40px;
	border-radius     : 0 0 8px 8px;
	opacity           : 0;
	visibility        : hidden;
	-webkit-transform : translateY(40px);
	-ms-transform     : translateY(40px);
	transform         : translateY(40px);
	-webkit-transition: 500ms;
	-o-transition     : 500ms;
	transition        : 500ms;
}

@media screen and (max-width: 991.98px) {
	.module-cart .module-cart-warp {
		left: -250px;
	}
}

@media screen and (max-width: 767.98px) {
	.module-cart .module-cart-warp {
		left   : -160px;
		padding: 30px;
	}
}

@media screen and (min-width: 768px) {
	.module-cart .module-cart-warp {
		width: 330px;
	}
}

.module-cart .module-cart-warp .cart-overview {
	margin-bottom: 0;
	width        : 100%;
}

.module-cart .module-cart-warp .cart-overview li {
	margin-bottom    : 30px;
	position         : relative;
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	-webkit-box-pack : justify;
	-ms-flex-pack    : justify;
	justify-content  : space-between;
}

.module-cart .module-cart-warp .cart-overview li:last-child {
	margin-bottom: 25px;
}

.module-cart .module-cart-warp .cart-overview li .cart-cancel {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-pack : center;
	-ms-flex-pack    : center;
	justify-content  : center;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	color            : var(--global--color-white);
	font-size        : 7px;
	-ms-flex-negative: 0;
	flex-shrink      : 0;
	border-radius    : 50%;
	width            : 20px;
	height           : 20px;
	background-color : var(--global--color-heading);
	margin-left      : 15px;
}

.module-cart .module-cart-warp .cart-overview li .cart-cancel:hover {
	background-color: var(--global--color-primary);
}

.module-cart .module-cart-warp .cart-overview li img {
	border-radius     : 4px;
	-ms-flex-negative : 0;
	flex-shrink       : 0;
	width             : 60px;
	height            : 60px;
	margin-right      : 15px;
	-webkit-box-shadow: 0px 5px 41.5px rgba(40, 40, 40, 0.21);
	box-shadow        : 0px 5px 41.5px rgba(40, 40, 40, 0.21);
}

.module-cart .module-cart-warp .cart-overview li .product-meta {
	-webkit-box-flex : 1;
	-ms-flex-positive: 1;
	flex-grow        : 1;
}

.module-cart .module-cart-warp .cart-overview li .product-meta h5 {
	color         : var(--global--color-body);
	font-family   : var(--global--font-heading);
	font-size     : 15px;
	text-transform: capitalize;
	line-height   : 1;
	font-weight   : 700;
	margin-bottom : 8px;
}

.module-cart .module-cart-warp .cart-overview li .product-meta p {
	font-family  : var(--global--font-body);
	color        : var(--global--color-primary);
	font-size    : 16px;
	line-height  : 20px;
	font-weight  : 700;
	margin-bottom: 0;
}

.module-cart .module-cart-warp span {
	font-family   : var(--global--font-heading);
	color         : var(--global--color-heading);
	font-size     : 17px;
	font-weight   : 500;
	line-height   : 22px;
	display       : inline-block;
	text-transform: capitalize;
	margin-bottom : 24px;
}

.module-cart .module-cart-warp span .total-price {
	font-style: normal;
}

.module-cart .module-cart-warp .cart--control .btn {
	height          : 65px;
	width           : 100%;
	border-radius   : 8px;
	background-color: var(--global--color-primary);
	color           : var(--global--color-white);
	-webkit-box-pack: center;
	-ms-flex-pack   : center;
	justify-content : center;
}

.module-cart .module-cart-warp .cart--control .btn::before {
	background-color: var(--global--color-heading);
}

.module-cart.module-active .module-cart-warp {
	opacity          : 1;
	visibility       : visible;
	-webkit-transform: translateY(0);
	-ms-transform    : translateY(0);
	transform        : translateY(0);
}

/* Module Call */
.module-call {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	margin-left      : auto;
	position         : relative;
}

.module-call i {
	font-size        : 50px;
	color            : var(--global--color-primary);
	margin-right     : 15px;
	-ms-flex-negative: 0;
	flex-shrink      : 0;
}

@media screen and (min-width: 992px) and (max-width: 1060px) {
	.module-call i {
		display: none;
	}
}

.module-call p {
	font-size     : 13px;
	color         : #929ba2;
	line-height   : 21px;
	margin-bottom : 0;
	text-transform: capitalize;
}

.module-call a {
	font-size        : 15px;
	line-height      : 22px;
	font-weight      : 700;
	color            : var(--global--color-secondary);
	-webkit-transform: 0.3s ease-in-out;
	-ms-transform    : 0.3s ease-in-out;
	transform        : 0.3s ease-in-out;
}

.module-call a:hover {
	color: var(--global--color-primary);
}

@media screen and (max-width: 991.98px) {
	.module-call {
		margin-left  : 0;
		margin-top   : 10px;
		margin-bottom: 20px;
	}
}

/* Module Contact */
.module-contact {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

@media screen and (min-width: 992px) {
	.module-contact {
		margin-left: 40px;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.module-contact {
		margin-left: 20px;
	}
}

@media screen and (max-width: 991.98px) {
	.module-contact {
		-webkit-box-orient   : vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction   : column;
		flex-direction       : column;
		width                : 100%;
	}
}

.module-contact .btn {
	display           : -webkit-box;
	display           : -ms-flexbox;
	display           : flex;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	color             : var(--global--color-white);
	background-color  : var(--global--color-primary);
	padding           : 0 38px;
	width             : auto;
	text-transform    : capitalize;
	font-family       : var(--global--font-body);
	font-size         : 15px;
	font-weight       : bold;
	cursor            : pointer;
	-webkit-transition: background 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9), color 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9);
	-o-transition     : background 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9), color 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9);
	transition        : background 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9), color 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9);
	overflow          : hidden;
	height            : 100px;
	border-radius     : 0;
}

@media screen and (min-width: 992px) {
	.module-contact .btn {
		font-size: 14px;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.module-contact .btn {
		padding: 0 20px;
	}
}

@media screen and (max-width: 991.98px) {
	.module-contact .btn {
		height       : 50px;
		width        : 100%;
		border-radius: 8px;
	}
}

.module-contact .btn i {
	color             : var(--global--color-white);
	margin-left       : 10px;
	margin-right      : 0;
	-webkit-transition: 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9);
	-o-transition     : 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9);
	transition        : 0.3s cubic-bezier(0.37, 0.31, 0.31, 0.9);
}

@media screen and (max-width: 991.98px) {
	.module-contact .btn .btn {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width  : 100%;
	}
}

.module-contact.module-contact-2 .btn {
	width        : 174px;
	height       : 55px;
	padding      : 0 20px;
	border-radius: 4px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.module-contact.module-contact-2 .btn {
		width: 150px;
	}
}

@media screen and (max-width: 991.98px) {
	.module-contact.module-contact-2 .btn {
		width: 100%;
	}
}

/* Module Language */
.module-language {
	display          : -webkit-inline-box;
	display          : -ms-inline-flexbox;
	display          : inline-flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
	position         : relative;
	height           : 100%;
}

.module-language.module-active .lang-list,
.module-language:hover .lang-list {
	opacity          : 1;
	visibility       : visible;
	-webkit-transform: translateY(0);
	-ms-transform    : translateY(0);
	transform        : translateY(0);
}

.module-language .selected {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

.module-language .selected img {
	margin-right: 10px;
}

.module-language .selected span {
	font-family   : var(--global--font-body);
	font-size     : 13px;
	font-weight   : 400;
	text-transform: capitalize;
	color         : #929ba2;
	margin-right  : 5px;
	cursor        : pointer;
}

.module-language .selected i {
	font-size: 10px;
	color    : #929ba2;
}

.module-language .lang-list {
	background-color  : var(--global--color-white);
	color             : var(--global--color-heading);
	position          : absolute;
	top               : 100%;
	padding           : 6px 22px;
	-webkit-box-shadow: 0px 5px 41.5px rgba(9, 29, 62, 0.11);
	box-shadow        : 0px 5px 41.5px rgba(9, 29, 62, 0.11);
	z-index           : 5555;
	left              : -70px;
	visibility        : hidden;
	opacity           : 0;
	-webkit-transform : translateY(15px);
	-ms-transform     : translateY(15px);
	transform         : translateY(15px);
	-webkit-transition: 0.3s ease-in-out;
	-o-transition     : 0.3s ease-in-out;
	transition        : 0.3s ease-in-out;
	border-radius     : 0 0 8px 8px;
}

@media (min-width: 992px) and (max-width: 1200px) {
	.module-language .lang-list {
		top: 140%;
	}
}

.module-language .lang-list ul {
	margin-bottom: 0;
}

.module-language .lang-list ul li {
	display          : -webkit-box;
	display          : -ms-flexbox;
	display          : flex;
	-webkit-box-align: center;
	-ms-flex-align   : center;
	align-items      : center;
}

.module-language .lang-list ul li:last-of-type a {
	border-bottom: none;
}

.module-language .lang-list ul li:last-of-type a::after {
	content: none;
}

.module-language .lang-list ul li img {
	margin-right: 10px;
}

.module-language .lang-list ul li a {
	font-size         : 14px;
	display           : block;
	color             : var(--global--color-body);
	text-transform    : capitalize;
	border-bottom     : 1px solid #eaeaea;
	padding           : 8px 0px;
	cursor            : pointer;
	-webkit-transition: 0.3s ease;
	-o-transition     : 0.3s ease;
	transition        : 0.3s ease;
	position          : relative;
}

.module-language .lang-list ul li a::after {
	content           : '';
	position          : absolute;
	bottom            : 0;
	left              : 0;
	height            : 1px;
	width             : 0px;
	-webkit-transition: 0.3s ease;
	-o-transition     : 0.3s ease;
	transition        : 0.3s ease;
	background-color  : transparent;
}

.module-language .lang-list ul li a:hover {
	color: var(--global--color-primary);
}

.module-language .lang-list ul li a:hover::after {
	width           : 100%;
	background-color: var(--global--color-primary);
}

/* Module Social */
.module-social {
	line-height  : 100px;
	height       : 100px;
	margin-right : 20px;
	margin-bottom: 0;
}

@media screen and (max-width: 991.98px) {
	.module-social {
		border-bottom: 0;
	}
}

.module-social:last-child {
	margin-right: 0;
}

.module-social a {
	color             : var(--global--color-white);
	font-size         : 17px;
	font-weight       : 400;
	margin-right      : 10px;
	position          : relative;
	overflow          : hidden;
	width             : 35px;
	height            : 35px;
	background-color  : var(--global--color-primary);
	border-radius     : 50%;
	display           : -webkit-inline-box;
	display           : -ms-inline-flexbox;
	display           : inline-flex;
	-webkit-box-pack  : center;
	-ms-flex-pack     : center;
	justify-content   : center;
	-webkit-box-align : center;
	-ms-flex-align    : center;
	align-items       : center;
	-webkit-transition: 300ms ease-in-out;
	-o-transition     : 300ms ease-in-out;
	transition        : 300ms ease-in-out;
}

.module-social a:hover {
	-webkit-transform: scale(0.9);
	-ms-transform    : scale(0.9);
	transform        : scale(0.9);
}

.module-social.module-social-2 {
	line-height : 70px;
	height      : 70px;
	border-right: 2px solid #292929;
	margin-right: 0;
}

.module-social.module-social-2:last-child {
	margin-right: 0;
}

.module-social.module-social-2 a {
	color           : var(--global--color-white);
	background-color: transparent;
	margin-right    : 20px;
	width           : auto;
	height          : auto;
	border-radius   : 0;
}

.module-social.module-social-2 a:hover {
	color            : var(--global--color-primary);
	-webkit-transform: scale(1);
	-ms-transform    : scale(1);
	transform        : scale(1);
}

/* Small Devices, Tablets */
@media only screen and (max-width: 991px) {
	.module-content {
		margin-bottom: 10px;
	}

	.module-cart-warp .cart-overview h5 {
		color: var(--global--color-white);
	}
}

.module-cart.active .module-cart-warp {
	opacity          : 1;
	visibility       : visible;
	-webkit-transform: translateY(0);
	-ms-transform    : translateY(0);
	transform        : translateY(0);
	opacity          : 1;
}

.module.module-active .module-cart-warp,
.module.module-active .search-box {
	display: block !important;
}

.module-cart.module-active .module-cart-warp .cart-control {
	margin-right: auto;
	margin-left : auto;
	text-align  : center;
}

.module-cart.module-active .module-cart-warp .cart-control .pull-right {
	float: none !important;
}

.module-consultation {
	padding: 10px 0;
}

.module-consultation .btn {
	border-color: var(--global--color-heading);
	color       : var(--global--color-heading);
	margin      : 0;
}

.module-social a {
	color      : var(--global--color-heading);
	line-height: 36px;
}

.module-sidearea .module-sidearea-wrap {
	width  : 320px;
	padding: 40px;
}